import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { useSpring, animated } from "react-spring";
import { makeStyles } from "@material-ui/core/styles";
import { fiTheme } from "../../../commons/Theme";
import { PurposeTrans } from "../../ProductList/components/PurposeTrans";

const useStyles = makeStyles({
  checkoutItemImg: {
    width: "100%",
    height: "432px",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left bottom",
    backgroundColor: fiTheme.palette.secondary.main,
    backgroundBlendMode: "multiply",
    "@media (max-width:1440px)": {
      height: "264px",
    },
  },
  checkoutItem: {
    height: "340px",
    "@media (max-width:1440px)": {
      height: "216px",
    },
  },
});

export default function CheckoutItem({ item }) {
  const classes = useStyles();

  const propsScale = useSpring({
    transform: "scale(1)",
    opacity: 1,
    from: { transform: "scale(0.96)", opacity: 0 },
  });

  return (
    <Box mb={1 / 4}>
      <Grid container>
        <Grid item xs={12}>
          <animated.div style={propsScale}>
            <Box
              className={classes.checkoutItemImg}
              style={{ backgroundImage: `url(${item.img})` }}
              p={[2, 2, 2, 2, 4]}
            >
              <Grid container justify='space-between'>
                <Grid item xs={12}>
                  <Box className={classes.checkoutItem}>
                    <Box color='primary.main'>
                      <Typography variant='h1'>
                        {item.title} x {item.quantity}
                      </Typography>
                    </Box>
                    <Box color='primary.main' pt={1}>
                      <Typography variant='body1'>
                        {item.fi}/{item.width}/{item.height} —{" "}
                        <PurposeTrans purpose={item.purpose} />
                      </Typography>
                    </Box>
                    <Box color='primary.main'>
                      <Typography variant='body1'>{item.colorName}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box color='primary.main'>
                    <Typography variant='subtitle2' align='right'>
                      {item.price * item.quantity} PLN
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </animated.div>
        </Grid>
      </Grid>
    </Box>
  );
}
