import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation, Trans } from "react-i18next";

function HomeAssemblyText() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box
        ml={[3, 9, 12, 12, 36]}
        mr={[3, 9, 12, 12, 36]}
        pb={[4, 4, 6, 12, 18]}
      >
        <Grid container justify='space-between'>
          <Grid item xs={12} md={4} lg={6}>
            <Box>
              <Typography color='primary' variant='h2' paragraph>
                <Trans t={t} i18nKey='aboutFI.assembly'>
                  Montaż
                </Trans>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Box>
              <Typography color='primary' variant='h2' paragraph>
                <Trans t={t} i18nKey='aboutFI.assemblyParagraph'>
                  Montaż każdego stołu i stolika wyglada tak samo. Do
                  podnoszenia większych FI potrzebne są dwie osoby. Stół
                  montujemy w pozycji odwróconej, tak by wkręcić pręt do spodu
                  blatu, następnie osadzić nogę i całość skręcić śrubą ukrytą w
                  dnie sklejki. Stół jest wyposażony w regulowane stopki, które
                  stabilizują go na nierównych podłogach, ułatwiają przesuwanie
                  i chronią przed zarysowaniem.
                </Trans>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default HomeAssemblyText;
