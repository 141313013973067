import { atom, selector } from "recoil";
import { IProducts } from "./ProductsCtx";

export interface IItem extends IProducts {
  quantity: number;
  total?: number;
}

export const cartState = atom<IItem[]>({
  key: "cart",
  default: [],
});

export const cartCountState = selector({
  key: "cartCountState",
  get: ({ get }) => {
    const cartCount = get(cartState);

    return cartCount.reduce((total, item) => {
      return total + item.quantity;
    }, 0);
  },
});

export const cartItemCountState = selector({
  key: "cartItemCountState",
  get: ({ get }) => {
    const cartItemCount: IItem[] = get(cartState);

    return cartItemCount.reduce((total, item) => {
      return total + item.quantity * item.price;
    }, 0);
  },
});
