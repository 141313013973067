import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  Slide,
  SwipeableDrawer,
  Typography,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import MenuIcon from "@material-ui/icons/Menu";
import ShoppingBasketOutlinedIcon from "@material-ui/icons/ShoppingBasketOutlined";
import MuiAppBar from "@material-ui/core/AppBar";
import MuiToolBar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { useRecoilValue } from "recoil";
import { cartCountState } from "../commons/CartState";
import { appBarState } from "../commons/AppBarState";
import { useRecoilState } from "recoil";
import i18n from "i18next";
import { useTranslation, Trans } from "react-i18next";

function HideOnScroll(props: any) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction='down' in={!trigger} timeout={250}>
      {children}
    </Slide>
  );
}

function AppBar(props: any) {
  const { t } = useTranslation();

  const [{ appBarColor, appBarBg, transisionTime }] =
    useRecoilState(appBarState);

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const cartCount = useRecoilValue(cartCountState);

  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <MuiAppBar elevation={0} style={{ position: "sticky" }}>
          <SwipeableDrawer
            onOpen={handleDrawerOpen}
            onClose={handleDrawerClose}
            variant='temporary'
            anchor='right'
            open={open}
          >
            <Box
              pl={2}
              pr={2}
              width={[`100vw`, `50vw`, `25vw`]}
              height='100vh'
              style={{
                backgroundColor: appBarBg,
                color: appBarColor,
                transition: "all 2s",
              }}
            >
              <Grid container justify='space-between'>
                <Grid item xs={9}>
                  <List>
                    <ListItem>
                      <Link to='/' onClick={handleDrawerClose}>
                        <Typography variant='h3'>PASIEKA</Typography>
                      </Link>
                    </ListItem>
                    <Box mt={4}>
                      <ListItem>
                        <Link to='/products' onClick={handleDrawerClose}>
                          <Typography variant='h1'>
                            <Trans t={t} i18nKey='navigation.product'>
                              Produkt
                            </Trans>
                          </Typography>
                        </Link>
                      </ListItem>
                      {/* <ListItem>
                        <Link to='/demo' onClick={handleDrawerClose}>
                          <Typography variant='h1'>Demo</Typography>
                        </Link>
                      </ListItem> */}
                      <ListItem>
                        <Link to='/aboutFI' onClick={handleDrawerClose}>
                          <Typography variant='h1'>
                            <Trans t={t} i18nKey='navigation.fi'>
                              FI
                            </Trans>
                          </Typography>
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link to='/about' onClick={handleDrawerClose}>
                          <Typography variant='h1'>
                            <Trans t={t} i18nKey='navigation.aboutUs'>
                              O nas
                            </Trans>
                          </Typography>
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link
                          to={cartCount > 0 ? "/cart" : "#"}
                          onClick={handleDrawerClose}
                        >
                          <Typography variant='h1'>
                            <Trans t={t}>
                              {cartCount > 0
                                ? `${t("navigation.cart")} — ${cartCount}`
                                : null}
                            </Trans>
                          </Typography>
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Box
                          marginTop={2}
                          onClick={() => i18n.changeLanguage("pl")}
                          style={{ cursor: "pointer" }}
                        >
                          <Typography variant='subtitle2' color='primary'>
                            PL
                          </Typography>
                        </Box>
                      </ListItem>
                      <ListItem>
                        <Box
                          onClick={() => i18n.changeLanguage("en")}
                          style={{ cursor: "pointer" }}
                        >
                          <Typography variant='subtitle2' color='primary'>
                            EN
                          </Typography>
                        </Box>
                      </ListItem>
                    </Box>
                  </List>
                </Grid>
                <Grid item>
                  <Box pt={2}>
                    <IconButton onClick={handleDrawerClose}>
                      <ClearIcon />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </SwipeableDrawer>

          <Box
            pl={[3, 9, 12, 12, 36]}
            pr={[3, 9, 12, 12, 36]}
            style={{
              backgroundColor: appBarBg,
              color: appBarColor,
              transition: transisionTime,
            }}
          >
            <MuiToolBar variant='regular' disableGutters={true}>
              <Grid container alignItems='center'>
                <Grid item xs={6} md={3} lg={2}>
                  <Box>
                    <Link to='/'>
                      <Typography variant='h3'>PASIEKA</Typography>
                    </Link>
                  </Box>
                </Grid>

                <Grid item md={5} lg={6}>
                  <Grid container>
                    <Hidden smDown>
                      <Grid item>
                        <Box mr={[6, 6, 6, 6, 17]}>
                          <Link to='/products'>
                            <Typography variant='subtitle2'>
                              <Trans t={t} i18nKey='navigation.product'>
                                Produkt
                              </Trans>
                            </Typography>
                          </Link>
                        </Box>
                      </Grid>
                    </Hidden>
                    {/* <Hidden smDown>
                      <Grid item>
                        <Box mr={[6, 6, 6, 6, 17]}>
                          <Link to='/demo'>
                            <Typography variant='subtitle2'>Demo</Typography>
                          </Link>
                        </Box>
                      </Grid>
                    </Hidden> */}
                    <Hidden smDown>
                      <Grid item>
                        <Box mr={[6, 6, 6, 6, 17]}>
                          <Link to='/aboutFI'>
                            <Typography variant='subtitle2'>
                              <Trans t={t} i18nKey='navigation.fi'>
                                FI
                              </Trans>
                            </Typography>
                          </Link>
                        </Box>
                      </Grid>
                    </Hidden>
                    <Hidden smDown>
                      <Grid item>
                        <Box mr={[6, 6, 6, 6, 17]}>
                          <Link to='/about'>
                            <Typography variant='subtitle2'>
                              <Trans t={t} i18nKey='navigation.aboutUs'>
                                O nas
                              </Trans>
                            </Typography>
                          </Link>
                        </Box>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>

                <Grid item xs={6} md={4} lg={4} container justify='flex-end'>
                  <Hidden mdUp>
                    <Grid container justify='flex-end'>
                      {cartCount > 0 ? (
                        <Grid item>
                          <Box>
                            <IconButton onClick={handleDrawerOpen}>
                              <ShoppingBasketOutlinedIcon />
                              <Box pl={1}>
                                <Typography variant='subtitle2'>
                                  {cartCount}
                                </Typography>
                              </Box>
                            </IconButton>
                          </Box>
                        </Grid>
                      ) : (
                        <Grid item>
                          <Box>
                            <IconButton onClick={handleDrawerOpen}>
                              <MenuIcon />
                            </IconButton>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Hidden>
                  <Hidden smDown>
                    <Grid item>
                      <Box
                        onClick={() => i18n.changeLanguage("pl")}
                        style={{ cursor: "pointer" }}
                      >
                        <Typography variant='subtitle2' color='primary'>
                          PL
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box
                        onClick={() => i18n.changeLanguage("en")}
                        marginLeft={4}
                        style={{ cursor: "pointer" }}
                      >
                        <Typography variant='subtitle2' color='primary'>
                          EN
                        </Typography>
                      </Box>
                    </Grid>
                  </Hidden>
                  {cartCount > 0 ? (
                    <Hidden smDown>
                      <Grid item>
                        <Box marginLeft={4}>
                          <Link to={cartCount > 0 ? "/cart" : "#"}>
                            <Typography
                              variant='subtitle2'
                              color='primary'
                              align='right'
                            >
                              <Trans t={t}>
                                {cartCount > 0
                                  ? `${t("navigation.cart")} — ${cartCount}`
                                  : null}
                              </Trans>
                            </Typography>
                          </Link>
                        </Box>
                      </Grid>
                    </Hidden>
                  ) : null}
                </Grid>
              </Grid>
            </MuiToolBar>
          </Box>
        </MuiAppBar>
      </HideOnScroll>
    </React.Fragment>
  );
}

export default AppBar;
