import React, { useEffect } from "react";
import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { cartState, cartItemCountState } from "./../../commons/CartState";
import CartItem from "./components/CartItem";
import { Link } from "react-router-dom";
import { appBarState } from "../../commons/AppBarState";
import { footerState } from "../../commons/FooterState";
import { useResetRecoilState } from "recoil";
import { useTranslation, Trans } from "react-i18next";

export default function Cart() {
  const cartSummary = useRecoilValue(cartState);
  const cartItemCount = useRecoilValue(cartItemCountState);

  const setAppBar = useResetRecoilState(appBarState);
  useEffect(() => setAppBar(), [setAppBar]);

  const setFooter = useResetRecoilState(footerState);
  useEffect(() => setFooter(), [setFooter]);

  const { t } = useTranslation();

  return (
    <Box ml={[3, 9, 12, 12, 36]} mr={[3, 9, 12, 12, 36]} mt={[3, 6, 8, 8, 12]}>
      <Grid container>
        <Grid item xs={12} md={12} lg={8}>
          <Box
            pb={2}
            color='primary.main'
            borderColor='primary.main'
            borderBottom={2}
          >
            <Typography color='primary' variant='h1'>
              <Trans t={t} i18nKey='cart.summary'>
                Zestawienie
              </Trans>
            </Typography>
          </Box>
          {cartSummary.map((cartItem) => (
            <CartItem key={cartItem.id} item={cartItem} />
          ))}
        </Grid>
        <Hidden mdDown>
          <Grid item lg={1} />
        </Hidden>
        <Hidden mdDown>
          <Grid item lg={3}>
            <Grid container alignItems='flex-start'>
              <Grid item xs={4}>
                <Box>
                  <Typography color='primary' variant='h1'>
                    <Trans t={t} i18nKey='cart.total'>
                      Suma
                    </Trans>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box>
                  <Typography color='primary' variant='h1' align='right'>
                    {cartItemCount} PLN
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mt={2}>
                  <Link to={cartItemCount > 0 ? "/checkout" : "/cart"}>
                    <Button
                      variant='contained'
                      size='large'
                      color='secondary'
                      fullWidth={true}
                      disableElevation
                      disabled={cartItemCount < 1 ? true : false}
                    >
                      <Typography color='primary' variant='subtitle2'>
                        <Trans t={t} i18nKey='cart.cta'>
                          Kontynuuj
                        </Trans>
                      </Typography>
                    </Button>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
      <Hidden lgUp>
        <Box mt={4}>
          <Grid container>
            <Grid item xs={4}>
              <Box>
                <Typography color='primary' variant='h1'>
                  <Trans t={t} i18nKey='cart.summary'>
                    Suma
                  </Trans>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box>
                <Typography color='primary' variant='h1' align='right'>
                  {cartItemCount} PLN
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt={2}>
                <Link to={cartItemCount > 0 ? "/checkout" : "/cart"}>
                  <Button
                    variant='contained'
                    size='large'
                    color='secondary'
                    fullWidth={true}
                    disableElevation
                    disabled={cartItemCount < 1 ? true : false}
                  >
                    <Typography color='primary' variant='subtitle2'>
                      <Trans t={t} i18nKey='cart.cta'>
                        Kontynuuj
                      </Trans>
                    </Typography>
                  </Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Hidden>
    </Box>
  );
}
