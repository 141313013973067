export const TRANSLATIONS_EN = {
  navigation: {
    product: "Shop",
    fi: "About FI",
    aboutUs: "About Us",
    cart: "Cart",
  },
  footer: {
    design:
      "Design — Katarzyna Dobiecka / Coding — Grzegorz Łotysz / Fonts — Söhne Breit and EB Garamond",
    copyright:
      "Copyright © 2021 PASIEKA. It is allowed to copy materials from this website only for further promotion of the Pasieka brand. All rights reserved.",
    terms: "Terms and Conditions",
    privacy: "Policy Privacy",
  },
  home: {
    title: "FI Collection",
    winner: "1st Prize in Forbo Competition",
    interview: "Interview with the designer",
    aboutFI: "More about FI",
    headline:
      "FI is a brand new, innovative collection of round dining and coffee tables. All items are designed in a minimalistic form, complete with a unique finish of Forbo Furniture Linoleum. FI combines clean lines, classical geometrical shapes, and practical advantages of the Linoleum material. Thanks to the flexible material structure, tabletops merge smoothly with the legs for visual lightness, freshness, and elegance.",
    paragraph:
      "The basic palette contains three universal colors, which are accurately displayed in the photographs on this website, taken in natural light. The offered selection of practical, functional, and aesthetic tables in various sizes is our response to different needs. The tables will perfectly fit a room of any size, either at home (as a single piece of furniture) or in commercial and public areas (as a furniture group, complementing any grand interior).",
    cta: "Shop FI",
  },
  product: {
    purpose: {
      s: "side table S",
      m: "side table M",
      l: "side table L",
      for3: "3 person table",
      for5: "5 person table",
      for7: "7 person table",
      aS: "coaster S",
      aM: "placemat M",
      aL: "placemat L",
    },
    extraColors: "on request",
    cta: "Add to Cart",
    ctaDisabled: "Available soon",
    info: {
      about: "Product information",
      aboutContent: {
        aboutTitle: "About collection",
        aboutParagraph:
          "FI is a brand new, innovative collection of round dining and coffee tables. All items are designed in a minimalistic form, complete with a unique finish of Forbo Furniture Linoleum. FI combines clean lines, classical geometrical shapes, and practical advantages of the Linoleum material. Thanks to the flexible material structure, tabletops merge smoothly with the legs for visual lightness, freshness, and elegance.",
        materials: "Materials",
        materialsParagraph1:
          "Table top - Forbo furniture linoleum / birch plywood / hpl laminate",
        materialsParagraph2:
          "Leg - Forbo furniture linoleum / birch plywood / cardboard sleeve",
        materialsParagraph3: "Mounting elements - steel",
        dimensions: "Dimensions in cm (top diameter / leg diameter / height)",
        dimensionsParagraph:
          "FI 50 (50∕30∕50), FI 70 (70∕39∕35), FI 90 (90∕48∕25), FI 85 (85∕30∕75), FI 110 (110∕39∕75), FI 135 (135∕48∕75)",
        colors: "FI Colors",
        colorsParagraph:
          "The collection's primary colors are Mushroom, Olive, and Nero from the Forbo Furniture Linoleum palette. Tables in other colors are available on request for an additional charge.",
        more: "Detailed furniture specifications can be found in the product sheet.",
      },
      order: "About the order",
      orderContent: {
        timing: "Delivery time",
        timingParagraph:
          "The delivery time is about 6-8 weeks from the payment date.",
        transport: "Delivery cost",
        transportParagraph: "Free delivery on the territory of Poland.",
        returns: "Returns",
        returnsParagraph:
          "All FI tables are made to order according to the Customer’s specifications and are not returnable.",
        more: "Detailed regulations are provided in our Terms and Conditions.",
      },
      gallery: "Gallery",
    },
  },
  cart: { summary: "Summary", total: "Total", cta: "Checkout" },
  checkout: {
    form: {
      contact: "Contact information",
      delivery: "Delivery address",
      invoice: "VAT number",
      note: "Click Submit order button to submit your order. We will send you the confirmation email with all necessary information how to finalise the order. You will have 7 days for the payment. We will start preparing your order after the payment has been successfully processed.",
      labels: {
        name: "first and last name",
        email: "email",
        phone: "phone",
        deliveryName: "first and last name",
        companyName: "company name*",
        country: "country",
        address: "address",
        zipCode: "zip code",
        city: "city",
        vatNumber: "VAT number",
      },
      validation: {
        required: "Field required",
        email: "Are you sure it's correct e-mail address?",
        phone: "Are you sure it's correct phone number?",
        company: "Are you sure it's correct company number? ",
      },
    },
    orderSummary: "My Order",
    cost: "Amount",
    delivery: "Delivery",
    toPay: "To pay",
    termsOptIn:
      "I have read and accept the privacy policy and terms and conditions",
    cta: "Submit order",
    confirmation: "Thank you for order",
  },
  aboutFI: {
    assembly: "Assembly",
    assemblyParagraph:
      "On top of that, our tables are easy to assemble (please note that two people are needed to lift larger models.) First, turn the tabletop upside-down, and screw in the connector. Then place the leg onto the connector and fasten it with a screw hidden underneath the base. Each table is equipped with adjustable levelers to protect the floor from scratches.",
    components: "Components",
    componentsParagraph:
      "The cylindrical leg of an FI table is made of a base plate and a thick cardboard sleeve structure finished with a linoleum layer. The round top is a “sandwich” of high-quality materials: furniture linoleum, birch plywood, and high-pressure laminate (HPL).",
    componentsParagraph2:
      "Thanks to its sophisticated design, the connection between the leg and the top is completely invisible.",
    detail: "Detail",
    detailParagraph:
      "The linoleum surface is warm to the touch, perfectly matte, and fingerprint resistant. The natural antistatic properties of the linoleum prevent dust and dirt accumulation.",
    detailParagraph2:
      "All sharp edges of our tables have been beveled. The plywood rim is smooth and wax-impregnated.",
    linoleum: "Forbo Linoleum",
    linoleumParagraph:
      "Forbo Furniture Linoleum is a unique, ecological product of Fobo, a Swiss manufacturer, famous for its wide range of finishing materials. This linoleum is a mixture of oxidized linseed oil and resin with wood flour and dyes. When rolled out on a paper backing, it creates a thick, single-colored core. After drying, it is impregnated with an acrylic, water-based protective coating for durability and easy care.",
    linoleumParagraph2:
      "Furniture Linoleum is a CO2-neutral product. It is a 100% natural product made of recyclable materials.",
    linoleumLink: "More about Forbo Linoleum",
  },
  aboutUs: {
    contact: "Contact",
    address: "Wrocław, Poland",
    creators: "Creators of the brand",
    creatorsKD1:
      "Co-founder and designer at PASIEKA. An architect with extensive design experience, Katarzyna works at the intersection of many disciplines and on different scales. She designs public spaces, interiors, furniture, packaging, and applied graphics. Her ideas are born from the constant observation of trends and needs and the problems that they bring. The objects that she designs have a simple concept, a fresh look at aesthetics, and subtle detail. She strives for originality within simple means and available solutions. At Pasieka, Katarzyna controls the whole process from conception through detailed design to manufacturing. She is the author of all presented objects.",
    creatorsKD2:
      'Katarzyna is a co-laureate and winner of numerous competitions, for example, Revitalization of Xawery Dunikowski Boulevard in Wroclaw, DoFA visual identity, CREA AWARDS Vibia, H&M Home, and ELLE "Contemporary Nomad", the catalog of the main exhibition of POLIN Museum of the History of Polish Jews, visual identification of the “100 na 100” program, Musical Decades of Freedom, Polish Graphic Design Awards, Linoleum Od Nowa Forbo. She presented her lamp, table, and modular furniture at make me!, Wroclove Design, Gdynia Design Days, and Gwangju International Design Biennale.',
    creatorsGL:
      "Co-founder of PASIEKA. Creative Director, Product Designer, and entrepreneur with international experience in designing and developing brands and products. Currently working as Senior Product Designer in Boldare",
    intro: "About the brand",
    introParagraph:
      "An apiary (pasieka in Polish) is a place that is strongly defined by its function and appearance. This isolated area of the field is characterized by an original form and color consistent with the objects located there. The visual aspect of the apiary is insignificant. It is (only and as much as) an expression of the aesthetic tastes of its owner and creator.",
    introParagraph2:
      "At PASIEKA (Polish for apiary), we manufacture simple furniture and objects, which we first created to meet our own needs. Their final form results from a search for individual design solutions, which do not have their counterparts in the market.",
  },
  terms: {
    intro:
      'These Terms and Conditions relate to the website operated by Grzegorz Łotysz, a sole proprietor (hereinafter referred to as the "Seller"), at www.mypasieka.com (hereinafter referred to as the "Store"), and the goods and services provided through or in connection with the Store.',
    definitions: {
      title: "§ 1 DEFINITIONS OF TERMS",
      pasieka:
        "PASIEKA – the name of the brand promoting home décor products by Katarzyna Dobiecka and selling them via the Store operating at www.mypasieka.com.",
      shop: "Store (PASIEKA store) – Internet service available at www.mypasieka.com, through which the Customer may, in particular, place Orders.",
      assets:
        "Materials – photographs of Products and other assets (including, in particular, texts, graphics, logotypes, source code) which are works as defined by the Act on Copyright and Related Rights (Journal of Laws of 2019, item 1231, 2245), displayed in the Store and owned by the Seller or used by the Seller with the consent of a person or a third party who holds intellectual property rights to the Materials.",
      seller:
        "Seller – Grzegorz Łotysz, running a sole proprietorship with its registered office in Wrocław, ul. Mickiewicza 107/4, 51-685, NIP: 754-270-63-49, registered in the Central Register and Information on Economic Activity (CEIDG).",
      buyer:
        "Customer – Consumer or Entrepreneur using the services provided by the Seller via the Store or concluding a Sales Agreement with the Seller,",
      consument:
        "Consumer – a natural person purchasing Products via the Store for purposes not directly related to his business or professional activity,",
      entrepreneur:
        "Entrepreneur – a natural person, legal person, or unincorporated entity, making a legal transaction on their own behalf in the course of business or professional activity,",
      courier:
        "Courier – a specialized entity providing transport services with which the Seller cooperates to deliver the Products purchased by the Customer,",
      orderForm:
        "Order Form – a form available in the Store, which enables the Customer to place an Order,",
      order:
        "Order – a declaration of will of the Customer, leading to the conclusion of a Sales Agreement with the Seller,",
      contract:
        "Sales Agreement – a contract for the sale of a Product concluded between the Seller and the Customer via the Store,",
      cart: "Cart – a virtual tool that enables the Buyer to aggregate selected Products and calculate their value before purchasing. The Customer may freely manage the content of the Shopping Cart by adding or removing Products,",
      product: "Product – a movable item offered by the Seller via the Store,",
      bill: "Proof of Purchase – a VAT invoice issued by the Seller to confirm the conclusion of a Sales Agreement.",
    },
    generals: {
      title: "§ 2 GENERAL PROVISIONS",
      1: "1. These Terms and Conditions apply to Sales Agreements and other services provided by the Seller via the Store.",
      2: "2. The Store operates on the territory of the Republic of Poland.",
      3: "3. The Products available in the Store are original, brand new, and free of physical or legal defects.",
      4: "4. The Seller takes the utmost care in processing Sales Agreements and Orders.",
      5: "5. Starting from 1st January 2021.: if an Entrepreneur is a natural person conducting business activity registered in the Central Register and Information on Economic Activity (CEIDG) and concludes a Sales Agreement directly related to his business activity, when at the same time it follows from the content of the Sales Agreement that it is not of a professional nature for such Entrepreneur, arising in particular from the subject of his business activity made available in CEIDG (including, in particular, the PKD numbers), the provisions of these Terms and Conditions concerning the Customer being a Consumer shall also apply to such Entrepreneur, excluding the provision of Article 558 1, second sentence, of the Civil Code. Also, from 1st January 2021, the provisions of Article 563 and Article 567 § 2 of the Civil Code shall not apply to the Entrepreneur referred to in this section.",
    },
    rules: {
      title: "§ 3 RULES ON USE OF THE STORE",
      1: "1. The Customer is obliged to use the Store in a manner consistent with applicable law, principles of community life, and good practice while respecting the personal rights and intellectual property rights of third parties or persons.",
      2: "2. The use of the Store is entirely voluntary.",
      3: "3. When completing the Registration Form or Order Form, the Customer shall read and accept the Terms and Conditions and Privacy Policy.",
      4: "4. When using the Store, the Customer shall provide true and accurate data and information necessary to set up an Account or conclude a Sales Agreement.",
      5: "5. The Customer and any other person accessing the Store shall refrain from copying, modifying, distributing, reprinting, transmitting, or using in any other way (including, in particular, for marketing, trade, or profit-making purposes) the Materials available in the Store without the written consent of the Seller or any other person or third party, who has intellectual property rights to the Materials, except for the use of these Materials within the framework of fair use as defined by the Act on Copyright and Related Rights (Journal of Laws of 2019, item 1231, 2245).",
      6: "6. Violation of these provisions could constitute a violation of the law and thus provide grounds for civil or criminal proceedings against persons or entities engaged in such practices.",
    },
    orders: {
      title: "§ 4 PLACING ORDERS",
      1: {
        title: "1. The Customer places an Order:",
        1: "via the Store, operating at www.mypasieka.com",
        2: "via e-mail at mypasieka@gmail.com",
      },
      2: '2. To place an Order via the Store, the Customer selects a Product and specifies its characteristics. Then the Customer clicks the "Place an Order" button and goes to the Cart, which displays the selected Products and the total Order value. After clicking the "Continue" button, the Customer fills in the Order Form and then places the Order by clicking the "Order" button. An e-mail is sent to the Store, and the Customer receives an automatic confirmation e-mail with all the information needed to complete the Order.',
      3: "3. Placing an Order and receiving a confirmation e-mail from the Seller is not equal to concluding a Sales Agreement.",
      4: "4. The Customer shall complete the payment within 7 days of receiving the e-mail confirmation. The Order is accepted for processing after the payment is made by the Customer and credited to the Seller's bank account. If the payment is not made within this period, the Order is canceled.",
      5: "5. When the payment has been received, the Customer is notified by e-mail that the Order has been accepted for processing. From this moment, a Sales Agreement is concluded between the Customer and the Seller.",
      6: "6. If, after receiving the payment, the Seller determines that the Order can not be fulfilled, the Seller shall immediately inform the Customer via e-mail or telephone and refund the payment within 7 days.",
    },
    payments: {
      title: "§ 5 PAYMENTS",
      1: "1. The Customer purchases the Products at a price applicable at the time of placing the Order.",
      2: "2. The prices shown in the Store are specified in Polish zlotys and are gross prices, i.e., they include taxes (such as VAT) and customs duties.",
      3: "3. Before placing the Order, the Customer is notified about the total price for the Products in the Order, including shipping costs.",
      4: "4. After submitting the Order and receiving the confirmation e-mail, the Customer shall complete the payment for the Order within 7 days.",
      5: {
        title:
          "5. The Customer shall make payment for the ordered Product by a direct transfer to the Seller's bank account:",
        1: "Bank account details:",
        2: "mBank",
        3: "BIC/SWIFT CODE: BREXPLPWMBK",
        4: "Bank account numbers:",
        5: "For payments in zloty (PLN):",
        6: "IBAN: PL 95 1140 2004 0000 3102 6873 1478",
      },
      6: "6. Each Order is accompanied by a Proof of Purchase. The Customer agrees to receive an electronic invoice as a Proof of Purchase to the e-mail address provided in the Order Form.",
    },
    processing: {
      title: "§ 6 PROCESSING TIME",
      1: "1. Each Product has a processing time assigned to it. Processing time is expressed in weeks and runs from the date of booking the payment on the Seller's bank account.",
      2: "2. The delivery date of an Order is calculated basing on the processing time of the Order and its shipping time.",
    },
    delivery: {
      title: "§ 7 DELIVERY",
      1: "1. The Seller bears the delivery cost of the Order on the territory of the Republic of Poland.",
      2: "2. The delivery costs outside of the Republic of Poland are calculated individually.",
      3: "3. The Customer is obliged to collect the shipped Product.",
      4: "4. Large-size Products, such as tables, are delivered on pallets through reputable shipping companies. Deliveries are made between 8 a.m. and 4 p.m. on working days. The Customer receives a link to track the delivery on the Courier’s website, where the Customer can also obtain the Courier’s phone number and confirm the delivery time.",
      5: "5. Following the provisions of Article 43 of the Transport Law, loading is the responsibility of the sender or recipient, respectively. The driver delivers the parcel to the indicated address up to the property border and unloads the parcel next to the vehicle.",
      6: "6. The Customer shall check the condition of the parcel and its content in the presence of the Courier. If there is any damage, the Customer shall report it to the Courier within 7 calendar days and immediately contact the Seller. In such a case, the Seller shall send a courier to write a protocol. NOTE: Writing the protocol is not equivalent to filing a complaint.",
      7: "7. The Customer shall sign for the delivery.",
      8: "8. Upon signing for the delivery, the ownership of the ordered Product is transferred to the Customer, along with all risks associated with its possession and use, particularly the risk of loss or damage.",
    },
    withdrawal: {
      title: "§ 8 WITHDRAWAL FROM THE SALES AGREEMENT",
      1: "1. A Customer being a Consumer may withdraw from the Sales Agreement without giving reasons within 14 days from receiving the Product, unless the exclusion referred to in item 8 of this section applies.",
      2: {
        title:
          "2. The Customer may withdraw from the Sales Agreement by submitting a declaration of withdrawal to the Seller within the indicated period. This statement may be made:",
        1: "in writing to the Seller’s address: Mickiewicza 107/4, 51-685 Wrocław",
        2: "via e-mail to the address of the Seller: mypasieka@gmail.com",
      },
      3: "3. The Customer shall return the Product, along with the original or a copy of the Proof of Purchase or other evidence confirming the purchase of the returned Product within 14 days from submitting the declaration of withdrawal. The Seller will not accept cash on delivery returns.",
      4: "4. Shipping costs of the returned Product shall be covered by the Customer.",
      5: "5. The Customer is liable for the decrease in the Product’s value resulting from using it in a way that goes beyond what is necessary to establish the nature, characteristics, and functioning of the Product.",
      6: "6. The Seller shall return to Customer all payments made by the Customer, including delivery costs, within 14 days from the date of delivery of the Product covered by the declaration of withdrawal, subject to item 7 of this section.",
      7: "7. The Seller shall return the payment using the same payment method as the Customer used unless the Customer has explicitly agreed to a different payment method that does not involve any costs for him. If the Customer has chosen a delivery method other than the cheapest usual delivery offered by the Seller, the Seller will not reimburse the Customer for additional costs.",
      8: "8. The right of withdrawal from the Sales Agreement, referred to in this section, does not apply to the Customer who has ordered non-prefabricated goods manufactured according to the Customer’s specifications. This applies in particular to furniture whose size and finish color can be chosen by the Customer. The right to withdraw from the Sales Agreement does not apply to tables from the FI collection.",
      9: "9. The details on the exercise of the right of withdrawal from a distance contract are set out in the Act on Consumer Rights (Journal of Laws of 2019, item 134, 730).",
      10: "10. The provisions of this section do not apply to a Customer who is an Entrepreneur other than the Entrepreneur referred to in section 2, item 5 of these Terms and Conditions.",
    },
    complaints: {
      title: "§ 9 COMPLAINTS",
      1: "1. The provisions in this section do not apply to the Products damaged in transit.",
      2: "2. The Seller is under warranty if the physical defect is found before the expiry of two years from the delivery of the Product to the Customer.",
      3: "3. A complaint should contain: Customer's data, information about the Product and the Order, description, and date of detection of a defect, and Customer's request. The Customer shall also provide the original or a copy of the Proof of Purchase or other evidence confirming the purchase of the Product under complaint and send back the Product under complaint so that the complaint can be processed.",
      4: {
        title: "4. Complaints may be submitted:",
        1: "in writing to the Seller’s address: Mickiewicza 107/4, 51-685 Wrocław",
        2: "via e-mail to the Seller’s address: mypasieka@gmail.com",
        3: "Products returned within the complaint procedure should be sent to the address indicated individually by the Seller.",
      },
      5: "5. If the information provided in the complaint is not deemed to be sufficient, the Seller shall contact the Customer for clarification.",
      6: "6. The Seller shall process a complaint within 14 days of receiving it. This provision applies to a complaint filed by the Customer who is a Consumer, who requested replacement of the Product, removal of defects, or price reduction, specifying the reduction amount.",
      7: "7. The Seller's liability under warranty towards Customers who are Entrepreneurs is excluded. This provision does not apply to Entrepreneurs referred to in section 2, item 5 of these Terms and Conditions.",
    },
    provisions: {
      title: "§ 10 FINAL PROVISIONS",
      1: "1. The seller informs that the Store's activities may be interrupted due to technical service, maintenance, or improving its functionality.",
      2: "2. The Seller reserves the right to change these Terms and Conditions. The changes enter into force on the date indicated by the Seller, not earlier than 7 days after their announcement. Any Order placed before this date will be processed according to the Terms and Conditions applicable at the time of placing the Order.",
      3: "3. Any disputes between the Seller and the Customer – subject to their mutual agreement – shall be resolved amicably.",
      4: "4. Any disputes that cannot be resolved amicably shall be settled before the common court competent for the Seller’s seat.",
      5: "5. In the matters not covered by these Regulations, generally applicable provisions of the Polish law shall apply, in particular: the provisions of the Civil Code, Act on Consumer Rights, Act on Copyright and Related Rights, and provisions on personal data protection.",
      6: "6. These Terms and Conditions are effective as of 1st August 2021.",
    },
  },
  privacy: {
    title:
      'This Privacy Policy describes how PASIEKA (“the Website”, "we", "us" or "our") processes and protects the personal data submitted by users while using the services provided to them by PASIEKA.',
    1: "1. To secure the data entrusted to us, we have developed internal procedures and recommendations to prevent unauthorized access to the data. We monitor their implementation and constantly verify their compliance with the relevant legal acts: the Act on Personal Data Protection, the Act on Electronic Provision of Services, and various secondary legislation and community law acts.",
    2: "2. We process personal data on the basis of the consent given by the user and, in cases where the law authorizes the Administrator to process personal data, on the basis of the law or for the implementation of the agreement concluded between the parties.",
    3: "3. We obtain information about users and their behavior through the data voluntarily entered in the contact forms and by collecting Google Analytics cookies.",
    4: "4. We collect information provided voluntarily by the user.",
    5: "5. The data entered in the contact forms shall be processed only for processing orders.",
    6: "6. Personal data that we collect shall not be sold or made available to third parties, following the Act on Personal Data Protection.",
    7: "7. The data sent through the form may be inspected by the person who provided the data. This person shall also have the right to rectify, delete or limit the processing of their data at any time.",
    8: "8. We reserve the right to change this privacy policy due to technological development, possible changes in the law on personal data protection, and the development of our website. We shall notify the users of any changes in an explicit and understandable manner.",
    9: "9. The Website may contain links to third-party services. Such services operate independently of the Website and are not supervised by us in any way. These services may have their own privacy policies and regulations, which we recommend you read.",
    10: "10. The Website uses Google Analytics to obtain data on user behavior. Google Analytics uses cookies to collect and process data about visits, location, devices, and users’ browsers. We use this data solely to improve the quality of our services.",
    admin: {
      title:
        "If you have any questions regarding this privacy policy, feel free to contact us.",
      1: "The personal data Administrator:",
      2: "Grzegorz Łotysz",
      3: "ul. Mickiewicza 107/4",
      4: "51-685 Wrocław",
      5: "NIP 754-270-63-49",
      6: "mypasieka@gmail.com",
    },
  },
};
