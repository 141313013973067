import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { appBarState } from "../commons/AppBarState";
import { useRecoilState } from "recoil";
import { footerState } from "../commons/FooterState";
import { makeStyles } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";

const useStyles = makeStyles({
  footerMobile: {
    paddingBottom: 48,
    "@media (max-width:640px)": {
      paddingBottom: 200,
    },
  },
  footerDesktop: {
    paddingBottom: 48,
  },
});

export const Footer = () => {
  const [{ appBarColor, appBarBg, transisionTime }] =
    useRecoilState(appBarState);

  const [{ page }] = useRecoilState(footerState);

  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Box
        className={
          page === "home" ? classes.footerMobile : classes.footerDesktop
        }
        style={{
          backgroundColor: appBarBg,
          color: appBarColor,
          transition: transisionTime,
        }}
        pt={[12, 12, 12, 16, 36]}
        pl={[3, 9, 12, 12, 36]}
        pr={[3, 9, 12, 12, 36]}
      >
        <Grid container>
          <Grid item xs={10} md={8} xl={8}>
            <Box>
              <Typography variant='body2'>
                <Trans i18nKey='footer.design' t={t}>
                  Projekt — Katarzyna Dobiecka / Kody — Grzegorz Łotysz / Fonty
                  — Söhne Breit i EB Garamond
                </Trans>
              </Typography>
            </Box>
            <Box mt={[1, 1, 1, 1, 2]}>
              <Typography variant='body2'>
                <Trans i18nKey='footer.copyright' t={t}>
                  Copyright © 2021 PASIEKA. Dopuszcza się kopiowanie materiałów
                  z powyższej strony wyłącznie w celu dalszej promocji marki
                  Pasieka. Wszelkie prawa zastrzeżone.
                </Trans>
              </Typography>
            </Box>
          </Grid>
          <Grid item md={1} xl={1} />
          <Grid item md={3} xl={3}>
            <Box mt={[4, 0, 0, 0, 0]}>
              <Link to='/terms-conditions'>
                <Typography variant='subtitle2'>
                  <Trans i18nKey='footer.terms' t={t}>
                    Regulamin
                  </Trans>
                </Typography>
              </Link>
            </Box>
            <Box mt={2}>
              <Link to='/privacy-policy'>
                <Typography variant='subtitle2'>
                  <Trans i18nKey='footer.privacy' t={t}>
                    Polityka prywatności
                  </Trans>
                </Typography>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};
