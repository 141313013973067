import React, { useState, useCallback } from "react";
import { useSpring, useTransition, animated } from "react-spring";
import { makeStyles } from "@material-ui/core";
import { Box, Grid, Hidden, Typography } from "@material-ui/core";

import img1 from "../../../assets/img/Home/Gallery/DSC_1854.jpg";
import img2 from "../../../assets/img/Home/Gallery/DSC_1585.jpg";
import img3 from "../../../assets/img/Home/Gallery/DSC_3736.jpg";
import img4 from "../../../assets/img/Home/Gallery/Pijalni_1.jpg";
import img5 from "../../../assets/img/Home/Gallery/DSC_3531.jpg";
import img6 from "../../../assets/img/Home/Gallery/DSC_3634.jpg";
import img7 from "../../../assets/img/Home/Gallery/DSC_1897.jpg";
import img8 from "../../../assets/img/Home/Gallery/DSC_3815.jpg";

const useStyles = makeStyles({
  gallerySlider: {
    position: "absolute",
    height: "1000px",
    width: "100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    cursor: "pointer",
    "@media (max-width:1600px)": {
      height: "600px",
    },
    "@media (max-width:1280px)": {
      height: "480px",
    },
    "@media (max-width:960px)": {
      height: "320px",
    },
  },
  relativeDiv: {
    position: "relative",
    width: "100%",
    height: "1000px",
    willChange: "transform",
    "@media (max-width:1600px)": {
      height: "600px",
    },
    "@media (max-width:1280px)": {
      height: "480px",
    },
    "@media (max-width:960px)": {
      height: "320px",
    },
  },
});

interface ISlides {
  id: number;
  url: string;
}

const slides: Array<ISlides> = [
  { id: 1, url: `url(${img1})` },
  { id: 2, url: `url(${img2})` },
  { id: 3, url: `url(${img3})` },
  { id: 4, url: `url(${img4})` },
  { id: 5, url: `url(${img5})` },
  { id: 6, url: `url(${img6})` },
  { id: 7, url: `url(${img7})` },
  { id: 8, url: `url(${img8})` },
];
export const ProductGallery = () => {
  const classes = useStyles();

  const [index, set] = useState(0);
  const transitions = useTransition(slides[index], (item) => item.id, {
    from: { opacity: 0, transform: "translate3d(25%, 0, 0)" },
    enter: { opacity: 1, transform: "translate3d(0%, 0, 0)" },
    leave: { opacity: 0, transform: "translate3d(-50%, 0, 0)" },
  });
  const onClick = useCallback(
    () => set((state) => (state + 1) % slides.length),
    []
  );

  const toggle = useState(false);
  const [scale, setScale] = useSpring(() => ({ transform: "scale(1)" }));

  return (
    <Box mt={[6, 6, 6, 6, 12]} mb={[6, 6, 8, 8, 12]}>
      <Box ml={[3, 6, 8, 8, 30]} mr={[3, 6, 8, 8, 30]} bgcolor='secondary.main'>
        <Grid container justify='center' alignItems='center'>
          <Hidden mdDown>
            <Grid item md={6}>
              <Box
                onClick={onClick}
                textAlign='center'
                style={{ cursor: "pointer" }}
                onMouseEnter={() =>
                  setScale({ transform: toggle ? "scale(0.96)" : "scale(1)" })
                }
                onMouseLeave={() =>
                  setScale({ transform: !toggle ? "scale(0.96)" : "scale(1)" })
                }
              >
                <Typography color='primary' variant='h1'>
                  &#8594;
                </Typography>
              </Box>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={6}>
            <animated.div
              onClick={onClick}
              className={classes.relativeDiv}
              onMouseEnter={() =>
                setScale({ transform: toggle ? "scale(0.96)" : "scale(1)" })
              }
              onMouseLeave={() =>
                setScale({ transform: !toggle ? "scale(0.96)" : "scale(1)" })
              }
              style={scale}
            >
              {transitions.map(({ item, props, key }) => (
                <animated.div
                  key={key}
                  className={classes.gallerySlider}
                  style={{ ...props, backgroundImage: item.url }}
                />
              ))}
            </animated.div>
          </Grid>
        </Grid>
      </Box>
      <Hidden mdUp>
        <Grid container item xs={12} justify='center'>
          <Box
            mt={3}
            onClick={onClick}
            textAlign='center'
            style={{ cursor: "pointer" }}
            onMouseEnter={() =>
              setScale({ transform: toggle ? "scale(0.96)" : "scale(1)" })
            }
            onMouseLeave={() =>
              setScale({ transform: !toggle ? "scale(0.96)" : "scale(1)" })
            }
          >
            <Typography color='primary' variant='h1'>
              &#8594;
            </Typography>
          </Box>
        </Grid>
      </Hidden>
    </Box>
  );
};
