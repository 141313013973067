import React, { useEffect } from "react";
// import { HomeHero } from "./components/HomeHero";
import { HomeHeroBG } from "./components/HomeHeroBG";
import { HomeProducts } from "./components/HomeProducts";
import { HomeHeroText } from "./components/HomeHeroText";
import { HomeButton } from "./components/HomeButton";
import { appBarState } from "../../commons/AppBarState";
import { footerState } from "../../commons/FooterState";
import { useSetRecoilState, useResetRecoilState } from "recoil";
import { Hidden } from "@material-ui/core";

export const Home = () => {
  const setAppBar = useResetRecoilState(appBarState);
  useEffect(() => setAppBar(), [setAppBar]);

  const setFooter = useSetRecoilState(footerState);
  useEffect(() => {
    setFooter({
      page: "home",
    });
  }, [setFooter]);

  return (
    <React.Fragment>
      <HomeHeroBG />
      <Hidden smUp>
        <HomeProducts />
      </Hidden>
      <HomeHeroText />
      <HomeButton />
      <Hidden mdDown>
        <HomeProducts />
      </Hidden>
    </React.Fragment>
  );
};
