import React, { useState, useContext, ChangeEvent } from "react";
import { ProductsCtx } from "../../../commons/ProductsCtx";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Hidden,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { fiTheme } from "../../../commons/Theme";
import { PurposeTrans } from "../../ProductList/components/PurposeTrans";

const useStyles = makeStyles({
  linksItems: {
    color: fiTheme.palette.primary.light,
    height: "80px",
    padding: "0",
    "&:hover": {
      color: fiTheme.palette.primary.main,
    },
    "@media (max-width:1440px)": {
      height: "48px",
    },
  },
  listItems: {
    borderBottom: `2px solid ${fiTheme.palette.primary.main}`,
  },
});

export const ProductSizes = (props: any) => {
  const DetailsContext = useContext(ProductsCtx);

  const classes = useStyles();

  const [, setIndex] = useState(props.size);

  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const productsKind = DetailsContext.filter((p) =>
    props.fi < 40 ? p.fi < 40 : p.fi >= 40
  );

  return (
    <React.Fragment>
      <Accordion
        elevation={0}
        square
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <Box borderBottom={2} borderColor='primary.main' color='primary.main'>
          <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
            <Grid container alignItems='center' justify='space-between'>
              <Grid item xs={4} md={4} lg={2}>
                <Typography color='primary' variant='h1'>
                  {props.title}
                </Typography>
              </Grid>
              <Hidden mdDown>
                <Grid item md={4} lg={6}>
                  <Typography color='primary' variant='body1'>
                    {props.fi}
                    {props.width && `/${props.width}/${props.height}`} cm —{" "}
                    <PurposeTrans purpose={props.purpose} />
                  </Typography>
                </Grid>
              </Hidden>
              <Hidden lgUp>
                <Grid item xs={4}>
                  <Typography color='primary' variant='body1' align='center'>
                    {props.fi}/{props.width}/{props.height}
                  </Typography>
                </Grid>
              </Hidden>
              <Grid item xs={4} md={4} lg={4}>
                <Typography color='primary' variant='subtitle2' align='right'>
                  {props.price && `${props.price} PLN`}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
        </Box>
        <AccordionDetails
          onClick={() => {
            setExpanded(false);
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              {productsKind
                .filter((product) => product.color === "White")
                .filter((p) => p.fi !== props.fi)
                .map((product) => (
                  <List key={product.id} disablePadding={true} dense={false}>
                    <Link
                      className={classes.linksItems}
                      to={`/product/${product.id}`}
                      onClick={() => setIndex(product.id - 1)}
                    >
                      <ListItem
                        className={classes.listItems}
                        divider={false}
                        disableGutters={true}
                      >
                        <Grid
                          container
                          alignItems='center'
                          justify='space-between'
                        >
                          <Grid item xs={4} md={4} lg={2}>
                            <Typography variant='h1'>
                              {product.title}
                            </Typography>
                          </Grid>
                          <Hidden mdDown>
                            <Grid item md={4} lg={6}>
                              <Typography variant='body1'>
                                {product.fi}
                                {product.width &&
                                  `/${product.width}/${product.height}`}{" "}
                                cm — <PurposeTrans purpose={product.purpose} />
                              </Typography>
                            </Grid>
                          </Hidden>
                          <Hidden lgUp>
                            <Grid item xs={4}>
                              <Typography variant='body1' align='center'>
                                {product.fi}/{product.width}/{product.height}
                              </Typography>
                            </Grid>
                          </Hidden>
                          <Grid item xs={4} md={4} lg={4}>
                            <Typography
                              color='primary'
                              variant='subtitle2'
                              align='right'
                            >
                              {product.price} PLN
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </Link>
                  </List>
                ))}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};
