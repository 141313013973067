import React from "react";
import { Box, Grid, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import bgImage from "../../../assets/img/Home/PASIEKA-Home.jpg";

const useStyles = makeStyles({
  image: {
    "@media (max-width:640px)": {
      position: "fixed",
      bottom: -10,
      zIndex: 10,
    },
  },
  bgImage: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "840px",
    "@media (max-width:1920px)": {
      height: "700px",
    },
    "@media (max-width:1600px)": {
      height: "700px",
    },
    "@media (max-width:1440px)": {
      height: "600px",
    },
  },
});

export const HomeHeroBG = () => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Hidden mdDown>
          <Box
            ml={[3, 6, 8, 8, 30]}
            mr={[3, 6, 8, 8, 30]}
            className={classes.bgImage}
            style={{ backgroundImage: `url(${bgImage})` }}
          />
        </Hidden>
        <Hidden smUp>
          <Box className={classes.image}>
            <img
              src='/img/fi_web_hero_crop.jpg'
              alt='FI PASIEKA'
              style={{ width: `100%` }}
            />
          </Box>
        </Hidden>
      </Grid>
    </Grid>
  );
};
