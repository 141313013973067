import React from "react";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";

function HomeForboText() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Box mt={[2, 2, 4, 4, 6]} ml={[3, 9, 12, 12, 36]} mr={[3, 9, 12, 12, 36]}>
        <Grid container justify='space-between'>
          <Grid item xs={12} md={4} lg={6}>
            <Box>
              <Typography color='primary' variant='h2' paragraph>
                <Trans t={t} i18nKey='aboutFI.linoleum'>
                  Linoleum Forbo
                </Trans>
              </Typography>
            </Box>
            <Box mt={[2, 2, 2, 4, 6]} mb={[4, 4, 4, 8, 8]}>
              <Typography color='primary' variant='h2'>
                <Link
                  href='https://www.forbo.com/flooring/pl-pl/produkty/wyk-adziny-marmoleum/furniture-linoleum/bi15kp'
                  underline='none'
                  target='_blank'
                  rel='noopener'
                >
                  &#8594;{" "}
                  <Trans t={t} i18nKey='aboutFI.linoleumLink'>
                    Więcej o linoleum meblowym Forbo
                  </Trans>
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Box>
              <Box>
                <Typography color='primary' variant='h2' paragraph>
                  <Trans t={t} i18nKey='aboutFI.linoleumParagraph'>
                    Forbo Furniture Linoleum to unikatowy, ekologiczny produkt
                    szwajcarskiego producenta Forbo znanego z całej gamy
                    materiałów wykończeniowych. Granulat linoleum jest mieszanką
                    utlenionego oleju lnianego oraz żywicy z dodatkiem mączki
                    drzewnej i barwników. Rozwałkowywany na papierowym
                    podkładzie tworzy gruby, jednobarwny rdzeń. Po wyschnięciu
                    impregnuje się go akrylową powłoką ochronną na bazie wody,
                    która zapewnia okładzinie trwałość oraz łatwość pielęgnacji
                    i użytkowania.
                  </Trans>
                </Typography>
              </Box>
              <Box mt={[3, 6]} pr={[0, 16]}>
                <Typography color='primary' variant='body1' paragraph>
                  <Trans t={t} i18nKey='aboutFI.linoleumParagraph2'>
                    Furniture Linoleum jest produktem neutralnym pod względem
                    emisji CO2. To w 100% naturalny produkt wytwarzany z
                    naturalnych materiałów nadających się do recyklingu.
                  </Trans>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={5} xl={6}></Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default HomeForboText;
