import React, { useContext } from "react";
import { ProductsCtx } from "../../../commons/ProductsCtx";
import { useTransition, animated } from "react-spring";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const useStyles = makeStyles({
  relativeDiv: {
    position: "relative",
    width: "100%",
    height: "900px",
    willChange: "scale",
    "@media (max-width:1440px)": {
      width: "100%",
      height: "600px",
    },
    "@media (max-width:1280px)": {
      width: "100%",
      height: "480px",
    },
    "@media (max-width:960px)": {
      width: "100%",
      height: "528px",
    },
    "@media (max-width:640px)": {
      width: "100%",
      height: "360px",
    },
    "@media (max-width:480px)": {
      width: "100%",
      height: "272px",
    },
  },
  images: {
    position: "absolute",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "900px",
    willChange: "opacity",
    "@media (max-width:1440px)": {
      width: "100%",
      height: "600px",
    },
    "@media (max-width:1280px)": {
      width: "100%",
      height: "480px",
    },
    "@media (max-width:960px)": {
      width: "100%",
      height: "320px",
      marginTop: "-20%",
    },
    "@media (max-width:640px)": {
      width: "100%",
      height: "320px",
      marginTop: "-120px",
    },
    "@media (max-width:480px)": {
      width: "100%",
      height: "320px",
      marginTop: "-80px",
    },
  },
});

export interface IProductImages {
  image: number;
}

export interface IImages {
  id: number;
  img: string;
}

export const ProductImages = (props: IProductImages) => {
  const DetailsContext = useContext(ProductsCtx);

  const images: IImages[] = [
    { id: 0, img: DetailsContext[0].img },
    { id: 1, img: DetailsContext[1].img },
    { id: 2, img: DetailsContext[2].img },
    { id: 3, img: DetailsContext[3].img },
    { id: 4, img: DetailsContext[4].img },
    { id: 5, img: DetailsContext[5].img },
    { id: 6, img: DetailsContext[6].img },
    { id: 7, img: DetailsContext[7].img },
    { id: 8, img: DetailsContext[8].img },
    { id: 9, img: DetailsContext[9].img },
    { id: 10, img: DetailsContext[10].img },
    { id: 11, img: DetailsContext[11].img },
    { id: 12, img: DetailsContext[12].img },
    { id: 13, img: DetailsContext[13].img },
    { id: 14, img: DetailsContext[14].img },
    { id: 15, img: DetailsContext[15].img },
    { id: 16, img: DetailsContext[16].img },
    { id: 17, img: DetailsContext[17].img },
    { id: 18, img: DetailsContext[18].img },
    { id: 19, img: DetailsContext[19].img },
    { id: 20, img: DetailsContext[20].img },
    { id: 21, img: DetailsContext[21].img },
    { id: 22, img: DetailsContext[22].img },
    { id: 23, img: DetailsContext[23].img },
    { id: 24, img: DetailsContext[24].img },
    { id: 25, img: DetailsContext[25].img },
    { id: 26, img: DetailsContext[26].img },
  ];

  const transtitions = useTransition(images[props.image], (item) => item.id, {
    from: { transform: "scale(0.96)", opacity: 0 },
    enter: { transform: "scale(1)", opacity: 1 },
    leave: { transform: "scale(0.96)", opacity: 0 },
  });

  const classes = useStyles();

  return (
    <Box className={classes.relativeDiv}>
      {transtitions.map(({ item, key, props }) => (
        <animated.div key={key} style={props} className={classes.images}>
          <img src={item.img} alt='FI Table' style={{ width: "100%" }} />
        </animated.div>
      ))}
    </Box>
  );
};
