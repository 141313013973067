import React, { useContext, useState, ChangeEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Hidden,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { ProductsCtx } from "../../../commons/ProductsCtx";
import { fiTheme } from "../../../commons/Theme";
import { ListExtraColors } from "./ProductExtraColors";

const useStyles = makeStyles({
  linksItems: {
    color: fiTheme.palette.primary.light,
    height: "80px",
    padding: "0",
    "&:hover": {
      color: fiTheme.palette.primary.main,
    },
    "@media (max-width:1440px)": {
      height: "48px",
    },
  },
  listItems: {
    borderBottom: `2px solid ${fiTheme.palette.primary.main}`,
  },
  listColors: {
    height: "80px",
    padding: "0",
    borderTop: `2px solid ${fiTheme.palette.primary.main}`,
    "@media (max-width:1440px)": {
      height: "48px",
    },
  },
  colors: {
    height: "32px",
    "@media (max-width:1440px)": {
      height: "24px",
    },
  },
});

export const ProductColors = (props: any) => {
  const classes = useStyles();

  const DetailsContext = useContext(ProductsCtx);

  const [, setIndex] = useState(props.color);

  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <React.Fragment>
      <Accordion
        elevation={0}
        square
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
          <Grid container alignItems='center'>
            <Hidden mdDown>
              <Grid item lg={2} />
            </Hidden>
            <Grid item xs={6} md={6} lg={4}>
              <Typography color='primary' variant='body1'>
                {props.fi < 40
                  ? `${props.colorName} — ${props.colorName2}`
                  : props.colorName}
              </Typography>
            </Grid>
            {props.fi < 40 ? (
              <>
                <Grid item xs={3} md={3} lg={3}>
                  <Box
                    className={classes.colors}
                    style={{ backgroundColor: `${props.colorHex}` }}
                  ></Box>
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                  <Box
                    className={classes.colors}
                    style={{ backgroundColor: `${props.colorHex2}` }}
                  ></Box>
                </Grid>
              </>
            ) : (
              <Grid item xs={6} md={6} lg={6}>
                <Box
                  className={classes.colors}
                  style={{ backgroundColor: `${props.colorHex}` }}
                ></Box>
              </Grid>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails onClick={() => setExpanded(false)}>
          <Grid container>
            <Grid item xs={12}>
              {DetailsContext.filter((product) => product.fi === props.fi)
                .filter((p) => p.colorName !== props.colorName)
                .map((product) => (
                  <List key={product.id} disablePadding={true} dense={false}>
                    <Link
                      className={classes.linksItems}
                      to={`/product/${product.id}`}
                      onClick={() => setIndex(product.id - 1)}
                    >
                      <ListItem
                        className={classes.listColors}
                        divider={false}
                        disableGutters={true}
                      >
                        <Grid
                          container
                          alignItems='center'
                          justify='space-between'
                        >
                          <Hidden mdDown>
                            <Grid item lg={2} />
                          </Hidden>
                          <Grid item xs={6} md={6} lg={4}>
                            <Typography variant='body1' align='left'>
                              {product.fi < 40
                                ? `${product.colorName} — ${product.colorName2}`
                                : product.colorName}
                            </Typography>
                          </Grid>
                          {product.fi < 40 ? (
                            <>
                              <Grid item xs={3} md={3} lg={3}>
                                <Box
                                  className={classes.colors}
                                  style={{
                                    backgroundColor: `${product.colorHex}`,
                                  }}
                                ></Box>
                              </Grid>
                              <Grid item xs={3} md={3} lg={3}>
                                <Box
                                  className={classes.colors}
                                  style={{
                                    backgroundColor: `${product.colorHex2}`,
                                  }}
                                ></Box>
                              </Grid>
                            </>
                          ) : (
                            <Grid item xs={6} md={6} lg={6}>
                              <Box
                                className={classes.colors}
                                style={{
                                  backgroundColor: `${product.colorHex}`,
                                }}
                              ></Box>
                            </Grid>
                          )}
                        </Grid>
                      </ListItem>
                    </Link>
                  </List>
                ))}
              <ListExtraColors />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
};
