import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { TRANSLATIONS_EN } from "./translations/en/translations_en";
import { TRANSLATIONS_PL } from "./translations/pl/translations_pl";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      pl: {
        translation: TRANSLATIONS_PL,
      },
      en: {
        translation: TRANSLATIONS_EN,
      },
    },
  });

// i18n.changeLanguage("pl");

export default i18n;
