import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";

export const HomeButton = () => {
  const { t } = useTranslation();

  return (
    <Box ml={[3, 9, 12, 12, 36]} mr={[3, 9, 12, 12, 36]}>
      <Grid container justify='flex-end'>
        <Grid item xs={12} md={6} lg={6}>
          <RouterLink to={"/products"}>
            <Button
              variant='contained'
              size='large'
              color='secondary'
              disableElevation
              fullWidth={true}
            >
              <Typography color='primary' variant='subtitle2'>
                <Trans t={t} i18nKey='home.cta'>
                  Zamów FI
                </Trans>
              </Typography>
            </Button>
          </RouterLink>
        </Grid>
      </Grid>
    </Box>
  );
};
