import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { appBarState } from "../../commons/AppBarState";
import { useRecoilState, useResetRecoilState } from "recoil";
import { footerState } from "../../commons/FooterState";
import { useTranslation, Trans } from "react-i18next";

export default function PrivacyPolicy() {
  const setFooter = useResetRecoilState(footerState);
  useEffect(() => setFooter(), [setFooter]);

  const [{ appBarColor, appBarBg }] = useRecoilState(appBarState);

  const { t } = useTranslation();

  return (
    <Box
      style={{
        backgroundColor: appBarBg,
        color: appBarColor,
        transition: "all 2s",
      }}
      pt={[6, 6, 8, 18, 36]}
      pl={[3, 9, 12, 12, 36]}
      pr={[3, 9, 12, 12, 36]}
      pb={[6, 6, 10, 12, 18]}
    >
      <Grid container justify='space-between'>
        <Grid item xs={12} md={4} lg={4} xl={4}>
          <Box mb={[6, 0]}>
            <Typography variant='h2'>
              <Trans t={t} i18nKey='privacy.title'>
                Niniejsza Polityka Prywatności określa zasady przetwarzania i
                ochrony danych osobowych przekazanych przez Użytkowników w
                związku z korzystaniem przez nich usług świadczonych przez
                Serwis PASIEKA.
              </Trans>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6} xl={6}>
          <Box>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='privacy.1'>
                1. W trosce o bezpieczeństwo powierzonych nam danych
                opracowaliśmy wewnętrzne procedury i zalecenia, które mają
                zapobiec udostępnieniu danych osobom nieupoważnionym.
                Kontrolujemy ich wykonywanie i stale sprawdzamy ich zgodność z
                odpowiednimi aktami prawnymi - ustawą o ochronie danych
                osobowych, ustawą o świadczeniu usług drogą elektroniczną, a
                także wszelkiego rodzaju aktach wykonawczych i aktach prawa
                wspólnotowego.
              </Trans>
            </Typography>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='privacy.2'>
                2. Dane Osobowe przetwarzane są na podstawie zgody wyrażanej
                przez Użytkownika oraz w przypadkach, w których przepisy prawa
                upoważniają Administratora do przetwarzania danych osobowych na
                podstawie przepisów prawa lub w celu realizacji zawartej
                pomiędzy stronami umowy.
              </Trans>
            </Typography>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='privacy.3'>
                3. Serwis pozyskuje informacje o użytkownikach poprzez
                dobrowolnie wprowadzone w formularzach dane kontaktowe o oraz
                ich zachowaniu poprzez gromadzenie plików cookies usługi Google
                Analytics.
              </Trans>
            </Typography>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='privacy.4'>
                4. Serwis zbiera informacje dobrowolnie podane przez
                użytkownika.
              </Trans>
            </Typography>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='privacy.5'>
                5. Dane podane w formularzu są przetwarzane tylko i wyłącznie w
                celu realizacji zamówienia.
              </Trans>
            </Typography>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='privacy.6'>
                6. Dane osobowe pozostawione w serwisie nie zostaną sprzedane
                ani udostępnione osobom trzecim, zgodnie z przepisami Ustawy o
                ochronie danych osobowych.
              </Trans>
            </Typography>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='privacy.7'>
                7. Do danych zawartych w formularzu przysługuje wgląd osobie
                fizycznej, która je tam umieściła. Osoba ta ma również praw do
                modyfikacji i zaprzestania przetwarzania swoich danych w
                dowolnym momencie.
              </Trans>
            </Typography>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='privacy.8'>
                8. Zastrzegamy sobie prawo do zmiany w polityce ochrony
                prywatności serwisu, na które może wpłynąć rozwój technologii
                internetowej, ewentualne zmiany prawa w zakresie ochrony danych
                osobowych oraz rozwój naszego serwisu internetowego. O wszelkich
                zmianach będziemy informować w sposób widoczny i zrozumiały.
              </Trans>
            </Typography>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='privacy.9'>
                9. W Serwisie mogą pojawiać się linki do innych stron
                internetowych. Takie strony internetowe działają niezależnie od
                Serwisu PASIEKA i nie są w żaden sposób przez nas nadzorowane.
                Strony te mogą posiadać własne polityki dotyczące prywatności
                oraz regulaminy, z którymi zalecamy się zapoznać.
              </Trans>
            </Typography>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='privacy.10'>
                10. Serwis PASIEKA w celu pozyskiwania danych o zachowaniach
                użytkowników korzysta z usługi Google Analytics. Usługa Google
                Analytics korzysta z plików cookies, dzięki którym może
                zapisywać i przetwarzać dane dotyczące odwiedzin, lokalizacji,
                urządzeń czy przeglądarek użytkowników. PASIEKA korzysta z tych
                danych tylko i wyłącznie w celu poprawy jakości działania
                Serwisu.
              </Trans>
            </Typography>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='privacy.admin.title'>
                W razie pytań do zapisów niniejszej zachęcamy do kontaktu.
              </Trans>
            </Typography>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2'>
              <Trans t={t} i18nKey='privacy.admin.1'>
                Administratorem danych osobowych jest
              </Trans>
            </Typography>
            <Typography variant='body2'>
              <Trans t={t} i18nKey='privacy.admin.2'>
                Grzegorz Łotysz
              </Trans>
            </Typography>
            <Typography variant='body2'>
              <Trans t={t} i18nKey='privacy.admin.3'>
                ul. Mickiewicza 107/4
              </Trans>
            </Typography>
            <Typography variant='body2'>
              <Trans t={t} i18nKey='privacy.admin.4'>
                51-685 Wrocław
              </Trans>
            </Typography>
            <Typography variant='body2'>
              <Trans t={t} i18nKey='privacy.admin.5'>
                NIP 754-270-63-49
              </Trans>
            </Typography>
            <Typography variant='body2'>
              <Trans t={t} i18nKey='privacy.admin.6'>
                mypasieka@gmail.com
              </Trans>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
