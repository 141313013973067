import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'pasieka-169.firebaseapp.com',
  databaseURL: 'https://pasieka-169.firebaseio.com',
  projectId: 'pasieka-169',
  storageBucket: 'pasieka-169.appspot.com',
  messagingSenderId: '518652154401',
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: 'G-58TMGWG520'
}

export const firebaseClient = firebase.initializeApp(firebaseConfig);
export const firebaseFirestore = firebaseClient.firestore();
export const firebaseFunctions = firebase.functions();