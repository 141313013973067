import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { appBarState } from "../../commons/AppBarState";
import { footerState } from "../../commons/FooterState";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useTranslation, Trans } from "react-i18next";

export default function TermsConditions() {
  const [{ appBarColor, appBarBg }] = useRecoilState(appBarState);

  const setFooter = useResetRecoilState(footerState);
  useEffect(() => setFooter(), [setFooter]);

  const { t } = useTranslation();
  return (
    <Box
      style={{
        backgroundColor: appBarBg,
        color: appBarColor,
        transition: "all 2s",
      }}
      pt={[6, 6, 8, 18, 36]}
      pl={[3, 9, 12, 12, 36]}
      pr={[3, 9, 12, 12, 36]}
      pb={[6, 6, 10, 12, 18]}
    >
      <Grid container justify='space-between'>
        <Grid item xs={12} md={4} lg={4} xl={4}>
          <Box mb={[6, 0]}>
            <Typography variant='h2'>
              <Trans t={t} i18nKey='terms.intro'>
                Niniejszy Regulamin określa ogólne warunki korzystania, zasady
                oraz sposób sprzedaży produktów marki PASIEKA, prowadzonej przez
                Grzegorza Łotysza, prowadzącego jednoosobową działalność
                gospodarczą, zwanego dalej „Sprzedawcą”, za pośrednictwem strony
                internetowej mypasieka.com, zwanej dalej „Sklepem internetowym”.
              </Trans>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6} xl={6}>
          <Box>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='terms.definitions.title'>
                § 1 DEFINICJE
              </Trans>
            </Typography>
            <Box>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.definitions.pasieka'>
                  PASIEKA – nazwa marki promującej produkty wyposażenia wnętrz
                  autorstwa Katarzyny Dobieckiej i prowadzącej ich sprzedaż za
                  pośrednictwem Sklepu Internetowego, działającego pod adresem
                  www.mypasieka.com
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.definitions.shop'>
                  Sklep internetowy (sklep PASIEKA) – serwis internetowy
                  dostępny pod adresem mypasieka.com, za pośrednictwem którego
                  Kupujący może w szczególności składać Zamówienia.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.definitions.assets'>
                  Materiały – zdjęcia Produktów oraz pozostałe materiały (w tym
                  w szczególności teksty, grafiki, logotypy, kody źródłowe)
                  będące utworami w rozumieniu przepisów ustawy z dnia 4 lutego
                  1994 r. o prawie autorskim i prawach pokrewnych (t. j.: Dz. U.
                  z 2019 r. poz. 1231, 2245), zamieszczone w Sklepie
                  internetowym i będące własnością Sprzedawcy lub użyte przez
                  Sprzedawcę za zgodą osoby lub podmiotu trzeciego, któremu
                  przysługują prawa własności intelektualnej do Materiałów.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.definitions.seller'>
                  Sprzedawca – Grzegorz Łotysz, prowadzący jednoosobową
                  działalność gospodarczą pod adresem: ulica Mickiewicza 107/4,
                  51-685 Wrocław, wpisaną do Centralnej Ewidencji i Informacji o
                  Działalności Gospodarczej, posiadającej nr NIP: 754-270-63-49.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.definitions.buyer'>
                  Kupujący – Konsument lub Przedsiębiorca korzystający z usług
                  świadczonych przez Sprzedawcę drogą elektroniczną w ramach
                  Sklepu internetowego lub zawierający ze Sprzedawcą Umowę
                  sprzedaży.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.definitions.consument'>
                  Konsument – osoba fizyczna dokonująca czynności prawnej z
                  przedsiębiorcą niezwiązanej bezpośrednio z jej działalnością
                  gospodarczą lub zawodową.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.definitions.entrepreneur'>
                  Przedsiębiorca – osoba fizyczna, osoba prawna lub jednostka
                  nieposiadająca osobowości prawnej, dokonująca czynności
                  prawnej we własnym imieniu w ramach działalności gospodarczej
                  lub zawodowej.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.definitions.courier'>
                  Dostawca – wyspecjalizowany podmiot świadczący usługi przewozu
                  wyznaczony przez Sprzedającego do wykonywania jego zobowiązań
                  związanych z dostawą zamówienia do Zamawiającego.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.definitions.orderForm'>
                  Formularz Zamówienia – dostępny w Sklepie internetowym
                  formularz, umożliwiający Kupującemu złożenie Zamówienia.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.definitions.order'>
                  Zamówienie – oświadczenie woli Kupującego, stanowiące ofertę
                  zawarcia Umowy sprzedaży ze Sprzedawcą.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.definitions.contract'>
                  Umowa Sprzedaży – umowa sprzedaży zawierana pomiędzy
                  Sprzedawcą i Kupującym na odległość za pośrednictwem Sklepu
                  internetowego, dotycząca zakupu Produktów.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.definitions.cart'>
                  Koszyk – wirtualne narzędzie umożliwiające Kupującemu
                  agregację wybranych Produktów przed ich zakupem oraz
                  przeliczanie ich wartości, przy czym dokonując wyboru
                  Produktów Kupujący może dowolnie zarządzać zawartością Koszyka
                  poprzez dodawanie lub usuwanie z niego Produktów.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.definitions.product'>
                  Produkt – dostępna w Sklepie internetowym rzecz ruchoma,
                  będąca przedmiotem Umowy Sprzedaży.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.definitions.bill'>
                  Dowód Zakupu – faktura VAT wystawiana przez Sprzedawcę i
                  potwierdzająca zawarcie Umowy Sprzedaży
                </Trans>
              </Typography>
            </Box>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='terms.generals.title'>
                § 2 POSTANOWIENIA OGÓLNE
              </Trans>
            </Typography>
            <Box>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.generals.1'>
                  1. Niniejszy Regulamin dotyczy Umów Sprzedaży oraz innych
                  usług świadczonych przez Sprzedawcę za pośrednictwem Sklepu
                  internetowego.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.generals.2'>
                  2. Sklep internetowy prowadzi sprzedaż na terytorium
                  Rzeczypospolitej Polskiej.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.generals.3'>
                  3. Produkty dostępne w Sklepie internetowym są oryginalne,
                  fabrycznie nowe (wolne od wad) oraz zgodne z obowiązującymi
                  standardami, wymaganiami i przepisami prawa.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.generals.4'>
                  4. Sprzedawca dokłada najwyższej staranności przy realizacji
                  Umów Sprzedaży oraz Zamówień.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.generals.5'>
                  5. Począwszy od dnia 1 stycznia 2021 r. - w przypadku
                  Przedsiębiorcy będącego osobą fizyczną prowadzącą działalność
                  gospodarczą wpisaną do Centralnej Ewidencji i Informacji o
                  Działalności Gospodarczej (CEIDG), który zawiera Umowę
                  Sprzedaży związaną bezpośrednio z prowadzoną przez niego
                  działalnością gospodarczą, gdy jednocześnie z treści Umowy
                  Sprzedaży wynika, że nie posiada ona dla takiego
                  Przedsiębiorcy charakteru zawodowego, wynikającego w
                  szczególności z przedmiotu wykonywanej przez takiego
                  Przedsiębiorcę działalności gospodarczej udostępnionego w
                  CEIDG (w tym w szczególności z numerów PKD) - postanowienia
                  niniejszego Regulaminu dotyczące Kupującego będącego
                  Konsumentem stosuje się również do takiego Przedsiębiorcy, z
                  wyłączeniem przepisu art. 558 1 zdanie drugie Kodeksu
                  cywilnego. Wobec Przedsiębiorcy, o którym mowa w niniejszym
                  punkcie, począwszy od dnia 1 stycznia 2021 r. nie stosuje się
                  również przepisów art. 563 oraz art. 567 § 2 Kodeksu
                  cywilnego.
                </Trans>
              </Typography>
            </Box>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='terms.rules.title'>
                § 3 ZASADY KORZYSTANIA ZE SKLEPU INTERNETOWEGO
              </Trans>
            </Typography>
            <Box>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.rules.1'>
                  1. Kupujący zobowiązany jest do korzystania ze Sklepu
                  internetowego w sposób zgodny z obowiązującym prawem, zasadami
                  współżycia społecznego oraz dobrymi obyczajami, mając na
                  uwadze poszanowanie dóbr osobistych oraz praw własności
                  intelektualnej osób lub podmiotów trzecich.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.rules.2'>
                  2. Korzystanie ze Sklepu internetowego jest całkowicie
                  dobrowolne.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.rules.3'>
                  3. Przy wypełnianiu Formularza Rejestracji lub Formularza
                  Zamówienia, Kupujący zobowiązany jest do zapoznania się z
                  treścią Regulaminu i Polityki prywatności oraz do akceptacji
                  ich treści.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.rules.4'>
                  4. Podczas korzystania ze Sklepu internetowego, Kupujący jest
                  zobowiązany do podawania prawdziwych oraz zgodnych ze stanem
                  faktycznym danych i informacji koniecznych do założenia Konta
                  lub zawarcia i realizacji Umowy Sprzedaży.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.rules.5'>
                  5. Zarówno Kupujący, jak i każda inna osoba mająca dostęp do
                  Sklepu internetowego, zobowiązana jest do powstrzymywania się
                  od kopiowania, modyfikowania, rozpowszechniania, przedruku,
                  transmitowania lub wykorzystywania w jakikolwiek inny sposób
                  (w tym w szczególności w celach marketingowych, handlowych lub
                  zarobkowych), Materiałów zamieszczonych w Sklepie internetowym
                  bez pisemnej zgody Sprzedawcy albo innej osoby lub podmiotu
                  trzeciego, któremu przysługują prawa własności intelektualnej
                  do Materiałów, z wyjątkiem korzystania z tych Materiałów w
                  ramach dozwolonego użytku, o którym mowa w przepisach ustawy z
                  dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych
                  (t. j.: Dz. U. z 2019 r. poz. 1231, 2245).
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.rules.6'>
                  6. Naruszenie postanowień punktu poprzedzającego mogłoby
                  stanowić naruszenie prawa, a tym samym stanowić podstawę do
                  wszczęcia postępowania cywilnego lub karnego przeciwko osobom
                  lub podmiotom dopuszczającym się takich praktyk.
                </Trans>
              </Typography>
            </Box>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='terms.orders.title'>
                § 4 SKŁADANIE ZAMÓWIEŃ
              </Trans>
            </Typography>
            <Box>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.orders.1.title'>
                  1. Kupujący składa Zamówienie:
                </Trans>
              </Typography>
              <ul>
                <li>
                  <Typography variant='body2'>
                    <Trans t={t} i18nKey='terms.orders.1.1'>
                      za pośrednictwem Sklepu internetowego, działającego pod
                      adresem www.mypasieka.com,
                    </Trans>
                  </Typography>
                </li>
                <li>
                  <Typography variant='body2'>
                    <Trans t={t} i18nKey='terms.orders.1.2'>
                      za pośrednictwem poczty elektronicznej na adres
                      mypasieka@gmail.com
                    </Trans>
                  </Typography>
                </li>
              </ul>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.orders.2'>
                  2. W celu złożenia Zamówienia za pośrednictwem Sklepu
                  internetowego, Kupujący po wyborze Produktu i określeniu jego
                  cech klika przycisk „Złóż zamówienie”, gdzie przechodzi do
                  zestawienia Koszyka i podsumowania kosztów. Po kliknięciu
                  przycisku „Kontynuuj”, Kupujący wypełnia Formularz Zamówienia,
                  następnie składa Zamówienie dedykowanym przyciskiem
                  „Zamawiam”. Jest to jednoznaczne z przesłaniem na adres Sklepu
                  internetowego wiadomości e-mail, następnie otrzymania
                  automatycznego e-maila potwierdzającego ze wszystkimi
                  informacjami niezbędnymi do finalizacji Zamówienia.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.orders.3'>
                  3. Złożenie Zamówienia i otrzymanie od Sprzedającego e-maila
                  potwierdzającego ten fakt, nie jest równoznaczne z zawarciem
                  Umowy Sprzedaży.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.orders.4'>
                  4. Termin na wykonanie płatności wynosi 7 dni od dnia
                  otrzymania e-maila potwierdzającego. Zamówienie zostaje
                  przyjęte do realizacji po dokonaniu wpłaty przez Kupującego i
                  zaksięgowaniu jej na rachunku bankowym Sprzedającego. W
                  przypadku braku wpłaty w tym czasie, Zamówienie jest
                  anulowane.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.orders.5'>
                  5. Informacja o rozpoczęciu realizacji Zamówienia zostanie
                  przesłana Kupującemu przez Sprzedawcę w formie wiadomości
                  e-mail po odnotowaniu wpłaty. Od tego momentu między Kupującym
                  a Sprzedawcą zostaje zawarta Umowa Sprzedaży.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.orders.6'>
                  6. Jeśli po opłaceniu zamówienia przez Kupującego, Sprzedający
                  stwierdzi, że zamówienie nie może być zrealizowane,
                  Sprzedający niezwłocznie poinformuje o tym Kupującego za
                  pośrednictwem poczty elektronicznej lub telefonicznie i środki
                  finansowe zostaną zwrócone w terminie do 7 dni od momentu
                  zawiadomienia.
                </Trans>
              </Typography>
            </Box>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='terms.payments.title'>
                § 5 PŁATNOŚCI
              </Trans>
            </Typography>
            <Box>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.payments.1'>
                  1. W związku z realizacją Zamówień oraz Umów, Sprzedawcy
                  przysługuje wynagrodzenie według cen dostępnych w Sklepie
                  internetowym i obowiązujących w momencie składania Zamówienia.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.payments.2'>
                  2. Ceny podane w Sklepie internetowym wyrażone są w polskich
                  złotych, są cenami brutto i zawierają wszystkie składniki, w
                  tym podatek VAT oraz cła.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.payments.3'>
                  3. O całkowitej cenie za Produkty objęte Zamówieniem, która
                  uwzględnia koszty dostawy, Kupujący informowany jest przed
                  złożeniem Zamówienia.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.payments.4'>
                  4. Kupujący, po złożeniu Zamówienia i otrzymaniu e-maila
                  potwierdzającego ma 7 dni na dokonanie płatności za zamówiony
                  Produkt.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.payments.5.title'>
                  5. Kupujący dokonuje płatności za zamówiony Produkt przelewem
                  bezpośrednio na rachunek bankowy Sprzedającego:
                </Trans>
              </Typography>
              <Box>
                <Typography variant='body2'>
                  <Trans t={t} i18nKey='terms.payments.5.1'>
                    mBank:
                  </Trans>
                </Typography>
                <Typography variant='body2'>
                  <Trans t={t} i18nKey='terms.payments.5.2'>
                    KOD BIC/SWIFT: BREXPLPWMBK
                  </Trans>
                </Typography>
                <Typography variant='body2'>
                  <Trans t={t} i18nKey='terms.payments.5.3'>
                    Numery kont bankowych:
                  </Trans>
                </Typography>
                <Typography variant='body2'>
                  <Trans t={t} i18nKey='terms.payments.5.4'>
                    Dla wpłat w zł (PLN):
                  </Trans>
                </Typography>
                <Typography variant='body2' paragraph>
                  <Trans t={t} i18nKey='terms.payments.5.5'>
                    IBAN: PL 95 1140 2004 0000 3102 6873 1478
                  </Trans>
                </Typography>
              </Box>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.payments.6'>
                  6. Do każdego Zamówienia dołączany jest Dowód Zakupu, przy
                  czym Kupujący wyraża zgodę, że jako Dowód Zakupu otrzyma
                  fakturę elektroniczną na podany w Formularzu Zamówienia adres
                  e-mail.
                </Trans>
              </Typography>
            </Box>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='terms.processing.title'>
                § 6 TERMIN REALIZACJI
              </Trans>
            </Typography>
            <Box>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.processing.1'>
                  1. Do każdego Produktu przyporządkowany jest termin realizacji
                  Zamówienia. Termin realizacji Zamówienia określony jest w
                  tygodniach i biegnie od daty zaksięgowania wpłaty na rachunku
                  bankowym Sprzedającego.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.processing.2'>
                  2. Termin otrzymania Zamówienia jest sumą terminu realizacji
                  Zamówienia i kilkudniowego czasu dostawy.
                </Trans>
              </Typography>
            </Box>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='terms.delivery.title'>
                § 7 DOSTAWA
              </Trans>
            </Typography>
            <Box>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.delivery.1'>
                  1. Koszty dostawy Zamówienia na terenie Polski ponosi
                  Sprzedający.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.delivery.2'>
                  2. Koszty dostawy Zamówienia poza granicami Polski są ustalane
                  indywidualnie.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.delivery.3'>
                  3. Kupujący zobowiązuje się do odebrania wysłanego Produktu.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.delivery.4'>
                  4. Sprzedawca dostarcza swoje produkty wielkogabarytowe tj.
                  stoły i stoliki na paletach za pośrednictwem renomowanych firm
                  spedycyjnych. Realizacja dostaw odbywa się w godzinach od 8 do
                  16 w dniach roboczych. Kupujący otrzymuje link do śledzenia
                  swojej przesyłki na stronie internetowej firmy transportowej,
                  gdzie może uzyskać numer telefonu do kuriera doręczającego
                  przesyłkę i ustalić z nim dokładniejszy czas dostawy.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.delivery.5'>
                  5. Zgodnie z art. 43. Prawa przewozowego czynności ładunkowe
                  należą odpowiednio do obowiązków nadawcy lub odbiorcy.
                  Kierowca doręcza przesyłkę pod wskazany adres do granicy
                  posesji i dokonuje wydania przesyłki na podłoże, na którym
                  stoi samochód kierowcy.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.delivery.6'>
                  6. Kupujący ma obowiązek sprawdzić stan przesyłki i jej
                  zawartość w obecności Dostawcy. W przypadku stwierdzenia jej
                  uszkodzenia zobowiązany jest zgłosić ten fakt Dostawcy w ciągu
                  7 dni kalendarzowych i niezwłocznie skontaktować się ze
                  Sprzedającym. W takim wypadku wysłany zostanie kurier, który
                  spisze protokół. UWAGA! Spisanie protokołu nie jest
                  jednoznaczne ze złożeniem reklamacji.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.delivery.7'>
                  7. Kupujący ma obowiązek pisemnie pokwitować odbiór przesyłki
                  od Dostawcy.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.delivery.8'>
                  8. Z chwilą pokwitowania na Kupującego przechodzi prawo
                  własności zamówionego Produktu oraz wszelkie ryzyko związane z
                  jego posiadaniem i użytkowaniem, a w szczególności ryzyko
                  utraty lub uszkodzenia.
                </Trans>
              </Typography>
            </Box>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='terms.withdrawal.title'>
                § 8 ODSTĄPIENIE OD UMOWY SPRZEDAŻY
              </Trans>
            </Typography>
            <Box>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.withdrawal.1'>
                  1. Kupującemu będącemu Konsumentem przysługuje prawo
                  odstąpienia od Umowy Sprzedaży jako umowy zawartej na
                  odległość, bez podania przyczyny, w terminie 14 dni od dnia
                  otrzymania Produktu, chyba że zastosowanie ma wyłączenie, o
                  którym mowa w pkt 8 niniejszego paragrafu.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.withdrawal.2.title'>
                  2. Skorzystanie z uprawnienia, o którym mowa w punkcie
                  poprzedzającym, wymaga złożenia przez Kupującego we wskazanym
                  terminie oświadczenia o odstąpieniu od Umowy Sprzedaży:
                </Trans>
              </Typography>
              <ul>
                <li>
                  <Typography variant='body2'>
                    <Trans t={t} i18nKey='terms.withdrawal.2.1'>
                      w przypadku formy papierowej – korespondencyjnie na adres
                      Sprzedawcy: Mickiewicza 107/4, 51-685 Wrocław,
                    </Trans>
                  </Typography>
                </li>
                <li>
                  <Typography variant='body2'>
                    <Trans t={t} i18nKey='terms.withdrawal.2.2'>
                      w przypadku formy poczty elektronicznej – na adres e-mail
                      Sprzedawcy: mypasieka@gmail.com
                    </Trans>
                  </Typography>
                </li>
              </ul>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.withdrawal.3'>
                  3. Odstępując od Umowy Sprzedaży Kupujący zobowiązany jest do
                  zwrotu Produktu wraz z oryginałem lub kopią Dowodu Zakupu albo
                  innym dowodem potwierdzającym dokonanie zakupu zwracanego
                  Produktu, w terminie 14 dni od daty złożenia oświadczenia o
                  odstąpieniu od Umowy Sprzedaży. Zwroty Produktów objętych
                  oświadczeniem o odstąpieniu od Umowy Sprzedaży, wysyłane na
                  koszt Sprzedawcy lub za pobraniem, nie będą przyjmowane przez
                  Sprzedawcę.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.withdrawal.4'>
                  4. Koszty wysyłki zwracanego Produktu ponosi Kupujący.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.withdrawal.5'>
                  5. Kupujący ponosi odpowiedzialność za zmniejszenie wartości
                  Produktu będące wynikiem korzystania z niego w sposób
                  wykraczający poza konieczny do stwierdzenia charakteru, cech i
                  funkcjonowania Produktu.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.withdrawal.6'>
                  6. Sprzedawca zwraca Kupującemu cenę Produktu oraz koszty
                  dostawy poniesione przez Kupującego przy zakupie Produktu nie
                  później niż w terminie 14 dni od dnia doręczenia Produktu
                  objętego oświadczeniem o odstąpieniu od Umowy Sprzedaży, z
                  uwzględnieniem pkt 7 niniejszego paragrafu.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.withdrawal.7'>
                  7. Sprzedawca dokonuje zwrotu płatności przy użyciu takiego
                  samego sposobu zapłaty, jakiego użył Kupujący przy zakupie
                  Produktu, chyba że Kupujący wyraźnie zgodził się na inny
                  sposób zwrotu, który nie wiąże się dla niego z dodatkowymi
                  kosztami. Jednakże w przypadku, gdy Kupujący wybrał inny niż
                  najtańszy zwykły sposób dostawy Produktu oferowany przez
                  Sprzedawcę, Sprzedawca nie jest zobowiązany do zwrotu
                  Kupującemu poniesionych przez niego dodatkowych kosztów.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.withdrawal.8'>
                  8. Prawo odstąpienia od Umowy Sprzedaży, o którym mowa w
                  niniejszym paragrafie, nie przysługuje Kupującemu, który
                  dokonał zamówienia towaru nieprefabrykowanego, wyprodukowanego
                  każdorazowo według określonej specyfikacji. Dotyczy to w
                  szczególności mebli, w przypadku których Kupujący ma możliwość
                  wyboru rozmiaru i koloru wykończenia. Prawu odstąpienia od
                  Umowy Sprzedaży nie podlegają stoły i stoliki z kolekcji FI.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.withdrawal.9'>
                  9. Szczegóły dotyczące korzystania z prawa odstąpienia od
                  Umowy Sprzedaży - jako umowy zawartej na odległość - określają
                  przepisy ustawy z dnia 30 maja 2014 r. o prawach konsumenta
                  (t. j.: Dz. U. z 2019 r. poz. 134, 730).
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.withdrawal.10'>
                  10. Postanowienia niniejszego paragrafu nie stosuje się do
                  Kupujących będących Przedsiębiorcami innymi niż
                  Przedsiębiorcy, o których mowa w § 2 pkt 5 Regulaminu.
                </Trans>
              </Typography>
            </Box>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='terms.complaints.title'>
                § 9 REKLAMACJE
              </Trans>
            </Typography>
            <Box>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.complaints.1'>
                  1. Reklamacje w niniejszym paragrafie związane z jakością
                  towaru, z wyłączeniem uszkodzenia w transporcie.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.complaints.2'>
                  2. Sprzedawca odpowiada z tytułu rękojmi za wady Produktu,
                  jeżeli wada została stwierdzona przed upływem dwóch lat od
                  dnia wydania Produktu Kupującemu.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.complaints.3'>
                  3. Reklamacja powinna zawierać: dane Kupującego, informacje
                  dotyczące Produktu i Zamówienia, opis i data powstania wady
                  Produktu oraz żądanie Kupującego. Kupujący zobowiązany jest
                  również do przekazania oryginału lub kopii Dowodu Zakupu albo
                  innego dowodu potwierdzającego dokonanie zakupu reklamowanego
                  Produktu, jak również do odesłania reklamowanego Produktu, aby
                  możliwe było rozpatrzenie reklamacji.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.complaints.4.title'>
                  4. Reklamacje należy składać:
                </Trans>
              </Typography>
              <ul>
                <li>
                  <Typography variant='body2'>
                    <Trans t={t} i18nKey='terms.complaints.4.1'>
                      w przypadku formy papierowej – korespondencyjnie na adres
                      Sprzedawcy: Mickiewicza 107/4, 51-685 Wrocław
                    </Trans>
                  </Typography>
                </li>
                <li>
                  <Typography variant='body2' paragraph>
                    <Trans t={t} i18nKey='terms.complaints.4.2'>
                      w przypadku formy poczty elektronicznej – na adres e-mail
                      Sprzedawcy: mypasieka@gmail.com
                    </Trans>
                  </Typography>
                </li>
              </ul>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.complaints.4.3'>
                  Produkty odsyłane w ramach procedury reklamacyjnej należy
                  wysyłać na adres wskazany każdorazowo przez Sprzedawcę.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.complaints.5'>
                  5. Jeżeli podane w reklamacji dane lub informacje wymagają
                  uzupełnienia, przed rozpatrzeniem reklamacji Sprzedawca zwróci
                  się do składającego reklamację o jej uzupełnienie we wskazanym
                </Trans>
                zakresie.
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.complaints.6'>
                  6. Sprzedawca zobowiązuje się do rozpatrzenia reklamacji
                  złożonej przez Kupującego będącego Konsumentem, który w ramach
                  reklamacji zażądał wymiany Produktu lub usunięcia wady albo
                  złożył oświadczenie o obniżeniu ceny, określając kwotę, o
                  którą cena ma być obniżona, w ciągu 14 dni od dnia jej
                  złożenia lub uzupełnienia przez Kupującego.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.complaints.7'>
                  7. Odpowiedzialność Sprzedawcy z tytułu rękojmi wobec
                  Kupujących będących Przedsiębiorcami zostaje całkowicie
                  wyłączona, co nie dotyczy Przedsiębiorców, o których mowa w §
                  2 pkt 5 Regulaminu.
                </Trans>
              </Typography>
            </Box>
          </Box>
          <Box mt={[2, 2, 4, 4, 6]}>
            <Typography variant='body2' paragraph>
              <Trans t={t} i18nKey='terms.provisions.title'>
                § 10 POSTANOWIENIA KOŃCOWE
              </Trans>
            </Typography>
            <Box>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.provisions.1'>
                  1. Sprzedawca zastrzega sobie prawo do wprowadzenia ograniczeń
                  w korzystaniu ze Sklepu internetowego spowodowanych jego
                  serwisem technicznym, pracami konserwacyjnymi lub pracami nad
                  polepszeniem jego funkcjonalności.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.provisions.2'>
                  2. Sprzedawca zastrzega sobie prawo do zmiany niniejszego
                  Regulaminu. Zmiany wchodzą w życie w momencie wyraźnie
                  wskazanym przez Sprzedawcę, nie wcześniej niż po 7 dniach od
                  dnia ich ogłoszenia. Zamówienia złożone przed wejściem w życie
                  zmian, o których mowa w zdaniu poprzedzającym, będą
                  realizowane na zasadach obowiązujących w momencie ich
                  składania.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.provisions.3'>
                  3. Wszelkie spory między Sprzedawcą a Kupującym – pod
                  warunkiem ich obopólnej zgody - rozstrzygane będą w sposób
                  polubowny.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.provisions.4'>
                  4. W przypadku braku możliwości polubownego rozwiązania sporu,
                  sądem właściwym miejscowo do rozpatrywania sporów będzie
                  właściwy rzeczowo Sąd dla adresu Sprzedawcy.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.provisions.5'>
                  5. W sprawach nieuregulowanych niniejszym Regulaminem
                  zastosowanie będą miały właściwe przepisy prawa powszechnie
                  obowiązującego, w tym w szczególności przepisy Kodeksu
                  cywilnego, ustawy o prawach konsumenta, ustawy o prawie
                  autorskim i prawach pokrewnych oraz przepisy o ochronie danych
                  osobowych.
                </Trans>
              </Typography>
              <Typography variant='body2' paragraph>
                <Trans t={t} i18nKey='terms.provisions.6'>
                  6. Regulamin obowiązuje od dnia 01/07/2021 r.
                </Trans>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
