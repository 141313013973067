import { atom } from "recoil";

export interface FooterStateTypes {
  page: "home" | false;
}

export const footerState = atom<FooterStateTypes>({
  key: "FooterState",
  default: {
    page: false,
  },
});
