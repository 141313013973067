import React, { useState } from "react";
import { Box, Collapse, Grid, Typography } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";
import ProductDialogGallery from "./ProductDialogGallery";

export const ProductInfo = () => {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const [expandedOrder, setExpandedOrder] = useState(false);
  const handleExpandOrderClick = () => {
    setExpandedOrder(!expandedOrder);
  };

  const { t } = useTranslation();

  return (
    <Box mb={[0, 0, 0, 2, 16]}>
      <Grid container>
        <Grid item xs={12}>
          <Box pt={12}>
            <Box
              onClick={() => handleExpandClick()}
              style={{ cursor: "pointer" }}
            >
              <Typography color='primary' variant='h2'>
                <Trans t={t} i18nKey='product.info.about'>
                  O produkcie
                </Trans>
              </Typography>
            </Box>
            <Collapse in={expanded} timeout={750}>
              <Grid container item direction='column' xs={12}>
                <Box pt={4}>
                  <Typography color='primary' variant='body2'>
                    <Trans t={t} i18nKey='product.info.aboutContent.aboutTitle'>
                      O kolekcji
                    </Trans>
                  </Typography>
                </Box>
                <Box>
                  <Typography color='primary' variant='body2'>
                    <Trans
                      t={t}
                      i18nKey='product.info.aboutContent.aboutParagraph'
                    >
                      FI to okrągłe stoły i stoliki o minimalistycznej formie i
                      oryginalnym wykończeniu linoleum meblowym Forbo. Sprawdzą
                      się jako pojedynczy mebel w przestrzeni domowej lub jako
                      grupa do większych wnętrz publicznych. Kolekcja Fi dostała
                      główną nagrodę w prestiżowym konkursie „Linoleum Od Nowa”,
                      zorganizowanym przez markę Forbo.
                    </Trans>
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography color='primary' variant='body2'>
                    <Trans t={t} i18nKey='product.info.aboutContent.materials'>
                      Materiały
                    </Trans>
                  </Typography>
                </Box>
                <Box>
                  <Typography color='primary' variant='body2'>
                    <Trans
                      t={t}
                      i18nKey='product.info.aboutContent.materialsParagraph1'
                    >
                      Blat – linoleum meblowe Forbo / sklejka brzozowa / laminat
                      hpl
                    </Trans>
                  </Typography>
                  <Typography color='primary' variant='body2'>
                    <Trans
                      t={t}
                      i18nKey='product.info.aboutContent.materialsParagraph2'
                    >
                      Noga – linoleum meblowe Forbo / sklejka brzozowa / tuleja
                      kartonowa
                    </Trans>
                  </Typography>
                  <Typography color='primary' variant='body2'>
                    <Trans
                      t={t}
                      i18nKey='product.info.aboutContent.materialsParagraph3'
                    >
                      Elementy montażowe – stal
                    </Trans>
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography color='primary' variant='body2'>
                    <Trans t={t} i18nKey='product.info.aboutContent.dimensions'>
                      Wymiary w cm (średnica blatu / średnica nogi / wysokość)
                    </Trans>
                  </Typography>
                </Box>
                <Box>
                  <Typography color='primary' variant='body2'>
                    <Trans
                      t={t}
                      i18nKey='product.info.aboutContent.dimensionsParagraph'
                    >
                      FI50 (50∕30∕50), FI70 (70∕39∕35), FI90 (90∕48∕25), FI85
                      (85∕30∕75), FI110 (110∕39∕75), FI135 (135∕48∕75)
                    </Trans>
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography color='primary' variant='body2'>
                    <Trans t={t} i18nKey='product.info.aboutContent.colors'>
                      Kolory FI
                    </Trans>
                  </Typography>
                </Box>
                <Box>
                  <Typography color='primary' variant='body2'>
                    <Trans
                      t={t}
                      i18nKey='product.info.aboutContent.colorsParagraph'
                    >
                      Podstawowe kolory kolekcji to Mushroom, Olive, Nero z
                      palety Forbo Furniture Linoleum. Stoły i stoliki w innych
                      kolorach dostępne na zapytanie za dodatkową opłatą.
                    </Trans>
                  </Typography>
                </Box>
                <Box pt={2} pb={4}>
                  <Typography color='primary' variant='body2'>
                    <Trans t={t} i18nKey='product.info.aboutContent.more'>
                      Szczegółowa specyfikacja mebla w karcie produktu.
                    </Trans>
                  </Typography>
                </Box>
              </Grid>
            </Collapse>
          </Box>
          <Box mt={[2, 2, 2, 2, 4]}>
            <Box
              onClick={() => handleExpandOrderClick()}
              style={{ cursor: "pointer" }}
            >
              <Typography color='primary' variant='h2'>
                <Trans t={t} i18nKey='product.info.order'>
                  Szczegóły zamówienia
                </Trans>
              </Typography>
            </Box>
            <Collapse in={expandedOrder} timeout={500}>
              <Grid container item direction='column' xs={12}>
                <Box pt={4}>
                  <Typography color='primary' variant='body2'>
                    <Trans t={t} i18nKey='product.info.orderContent.timing'>
                      Termin realizacji
                    </Trans>
                  </Typography>
                </Box>
                <Box>
                  <Typography color='primary' variant='body2'>
                    <Trans
                      t={t}
                      i18nKey='product.info.orderContent.timingParagraph'
                    >
                      Od momentu opłaty zamówienia czas realizacji wynosi 6-8
                      tygodni.
                    </Trans>
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography color='primary' variant='body2'>
                    <Trans t={t} i18nKey='product.info.orderContent.transport'>
                      Koszt transportu
                    </Trans>
                  </Typography>
                </Box>
                <Box>
                  <Typography color='primary' variant='body2'>
                    <Trans
                      t={t}
                      i18nKey='product.info.orderContent.transportParagraph'
                    >
                      Dostawa na terenie Polski 0 złotych.
                    </Trans>
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography color='primary' variant='body2'>
                    <Trans t={t} i18nKey='product.info.orderContent.returns'>
                      Zwroty
                    </Trans>
                  </Typography>
                </Box>
                <Box>
                  <Typography color='primary' variant='body2'>
                    <Trans
                      t={t}
                      i18nKey='product.info.orderContent.returnsParagraph'
                    >
                      Wszystkie stoły i stoliki FI są każdorazowo wykonywane na
                      zamówienie według określonej specyfikacji i nie podlegają
                      zwrotom.
                    </Trans>
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography color='primary' variant='body2'>
                    <Trans t={t} i18nKey='product.info.orderContent.more'>
                      Szczegółowe zasady określa Regulamin sklepu.
                    </Trans>
                  </Typography>
                </Box>
              </Grid>
            </Collapse>
          </Box>
          {/* <Box mt={[2, 2, 2, 2, 4]}>
            <Typography color='primary' variant='h2'>
              <Trans t={t} i18nKey='product.info.gallery'>
                Galeria
              </Trans>
            </Typography>
          </Box>
          <ProductDialogGallery /> */}
        </Grid>
      </Grid>
    </Box>
  );
};
