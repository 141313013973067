import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { fiTheme } from "./commons/Theme";
import ProductsCtx from "./commons/ProductsCtx";
import { Home } from "./pages/Home/Home";
import { ProductsList } from "./pages/ProductList/ProductsList";
import { ProductDetails } from "./pages/ProductDetails/ProductDetails";
import { AboutFI } from "./pages/AboutFI/AboutFI";
// import Demo from "./pages/Demo/Demo";
import Cart from "./pages/Cart/Cart";
import Checkout from "./pages/Checkout/Checkout";
import CheckoutConfimation from "./pages/Checkout/CheckoutConfirmation";
import About from "./pages/About/About";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import AppBar from "./components/AppBar";
import { Footer } from "./components/Footer";
import { RecoilRoot } from "recoil";
import ScrollToTop from "./commons/ScrollToTop";

export const App = () => {
  return (
    <RecoilRoot>
      <ProductsCtx>
        <ThemeProvider theme={fiTheme}>
          <CssBaseline />
          <ScrollToTop />
          <AppBar />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/products' component={ProductsList} />
            <Route path='/product/:productId' component={ProductDetails} />
            {/* <Route exact path='/demo' component={Demo} /> */}
            <Route exact path='/aboutFI' component={AboutFI} />
            <Route exact path='/about' component={About} />
            <Route exact path='/cart' component={Cart} />
            <Route exact path='/checkout' component={Checkout} />
            <Route exact path='/confirmation' component={CheckoutConfimation} />
            <Route exact path='/privacy-policy' component={PrivacyPolicy} />
            <Route exact path='/terms-conditions' component={TermsConditions} />
          </Switch>
          <Footer />
        </ThemeProvider>
      </ProductsCtx>
    </RecoilRoot>
  );
};
