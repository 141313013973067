import { createMuiTheme } from "@material-ui/core/styles";

export const fiTheme = createMuiTheme({
  overrides: {
    MuiTypography: {
      h1: {
        fontFamily: "Söhne Breit Buch",
        fontSize: "2rem",
        fontWeight: 400,
        lineHeight: "2.8rem",
        "@media (max-width:1920px)": {
          fontSize: "1.6rem",
          lineHeight: "2.24rem",
        },
        "@media (max-width:1440px)": {
          fontSize: "1.4rem",
          lineHeight: "1.96rem",
        },
        "@media (max-width:960px)": {
          fontSize: "1.1rem",
          lineHeight: "1.54rem",
        },
      },
      h2: {
        fontFamily: "Söhne Breit Buch",
        fontSize: "1.8rem",
        fontWeight: 400,
        lineHeight: "2.7rem",
        "@media (max-width:1920px)": {
          fontSize: "1.4rem",
          lineHeight: "2.1rem",
        },
        "@media (max-width:1440px)": {
          fontSize: "1.25rem",
          lineHeight: "1.875rem",
        },
        "@media (max-width:960px)": {
          fontSize: "1rem",
          lineHeight: "1.5rem",
        },
      },
      h3: {
        fontFamily: "Söhne Breit Fett",
        fontSize: "2.5rem",
        fontWeight: 900,
        lineHeight: "3rem",
        "@media (max-width:1920px)": {
          fontSize: "2rem",
          lineHeight: "2.8rem",
        },
        "@media (max-width:1440px)": {
          fontSize: "1.7rem",
          lineHeight: "2.04rem",
        },
      },
      subtitle1: {
        fontFamily: "Söhne Breit Fett",
        fontSize: "2.4rem",
        fontWeight: 900,
        lineHeight: "2.88rem",
        "@media (max-width:1920px)": {
          fontSize: "2rem",
          lineHeight: "2.4rem",
        },
        "@media (max-width:1440px)": {
          fontSize: "1.6rem",
          lineHeight: "1.92rem",
        },
      },
      subtitle2: {
        fontFamily: "Söhne Breit Buch",
        fontSize: "1.375rem",
        fontWeight: 400,
        lineHeight: "1.65rem",
        letterSpacing: "0.01rem",
        "@media (max-width:1920px)": {
          fontSize: "1.1rem",
          lineHeight: "1.32rem",
        },
        "@media (max-width:1440px)": {
          fontSize: "0.9rem",
          lineHeight: "1.08rem",
        },
      },
      body1: {
        fontFamily: "EB Garamond",
        fontSize: "1.625rem",
        fontWeight: 400,
        lineHeight: "2.25rem",
        "@media (max-width:1920px)": {
          fontSize: "1.4rem",
          lineHeight: "1.96rem",
        },
        "@media (max-width:1440px)": {
          fontSize: "1.2rem",
          lineHeight: "1.68rem",
        },
        "@media (max-width:960px)": {
          fontSize: "1rem",
          lineHeight: "1.5rem",
        },
      },
      body2: {
        fontFamily: "EB Garamond",
        fontSize: "1.375rem",
        fontWeight: 400,
        lineHeight: "1.625rem",
        "@media (max-width:1920px)": {
          fontSize: "1.1rem",
          lineHeight: "1.54rem",
        },
        "@media (max-width:1440px)": {
          fontSize: "1rem",
          lineHeight: "1.5rem",
        },
      },
      caption: {
        fontFamily: "Söhne Breit Buch",
        fontSize: "1.1rem",
        fontWeight: 400,
        lineHeight: "1.32rem",
        "@media (max-width:1920px)": {
          fontSize: "1.1rem",
          lineHeight: "1.32rem",
        },
        "@media (max-width:1440px)": {
          fontSize: "1.1rem",
          lineHeight: "1.32rem",
        },
      },
    },
    MuiToolbar: {
      regular: {
        height: "96px",
        "@media (max-width:1440px)": {
          height: "64px",
        },
      },
    },
    MuiAccordion: {
      root: {
        "&$expanded": {
          margin: "0px",
        },
        "&::before": {
          opacity: "0",
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: "0px",
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: "0px",
        height: "80px",
        "&$expanded": {
          minHeight: "32px",
        },
        "@media (max-width:1440px)": {
          padding: "0px",
          height: "48px",
          "&$expanded": {
            minHeight: "32px",
          },
        },
      },
      content: {
        margin: "0px",
        "&$expanded": {
          margin: "0",
        },
      },
      expandIcon: {
        position: "absolute",
        left: "0",
        fontSize: "0.8rem",
        color: "#000064",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        borderRadius: "0",
        minWidth: "48px",
        "&:hover": {
          backgroundColor: "#F3EAE6",
        },
      },
      text: {
        padding: "0",
      },
      containedSecondary: {
        "&:hover": {
          backgroundColor: "#E8D6D0",
        },
      },
      sizeSmall: {
        padding: "8px 4px",
      },
      textSizeSmall: {
        padding: "0px",
        fontFamily: "Söhne Breit Buch",
        fontSize: "1.1rem",
        fontWeight: 400,
        lineHeight: "1.32rem",
        letterSpacing: "0.01rem",
        "@media (max-width:1440px)": {
          fontSize: "0.9rem",
          lineHeight: "1.08rem",
        },
      },
      sizeLarge: {
        height: "80px",
        "@media (max-width:1440px)": {
          height: "48px",
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: "4px",
      },
      label: {
        color: "#000064",
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "#FFFFFF",
        color: "#000064",
      },
    },
    MuiListItem: {
      root: {
        paddingTop: "16px",
        paddingBottom: "16px",
        "@media (max-width:1440px)": {
          paddingTop: "8px",
          paddingBottom: "8px",
        },
      },
      divider: {
        borderBottom: "2px solid #B2B4D0",
      },
    },
    MuiFormControl: {
      fullWidth: {
        height: "80px",
        "@media (max-width:1440px)": {
          height: "48px",
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: "0px",
      },
    },
    MuiInputLabel: {
      formControl: {
        transform: "translate(0px, 23px) scale(1)",
        color: "#B2B4D0",
        "@media (max-width:1440px)": {
          transform: "translate(0px, 10px) scale(1)",
        },
        "&.MuiInputLabel-shrink": {
          transform: "translate(0px, 23px) scale(1)",
          color: "#000064",
          "@media (max-width:1440px)": {
            transform: "translate(0px, 10px) scale(1)",
          },
        },
      },
    },
    MuiInput: {
      root: {
        paddingLeft: "240px",
        height: "80px",
        "@media (max-width:1440px)": {
          paddingLeft: "160px",
          height: "48px",
        },
      },
      underline: {
        "&:hover:before": {
          borderBottom: "2px solid #000064",
        },
        "&::before": {
          borderBottom: "2px solid #B2B4D0",
        },
        "&::after": {
          borderBottom: "2px solid #000064",
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "2px solid #000064",
        },
      },
      formControl: {
        marginTop: "0px",
      },
    },
    MuiInputBase: {
      root: {
        color: "#000064",
      },
      input: {
        fontFamily: "EB Garamond",
        fontSize: "1.625rem",
        fontWeight: 400,
        lineHeight: "2.25rem",
        "@media (max-width:1440px)": {
          fontSize: "1.2rem",
          lineHeight: "1.68rem",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#000064",
      light: "#B2B4D0",
    },
    secondary: {
      main: "#F3EAE6",
      light: "#E8D6D0",
      dark: "#DEC4BC",
    },
    error: {
      main: "#E91E63",
    },
    background: {
      default: "#FFFFFF",
    },
    warning: {
      main: "#E2D1A5",
    },
  },
  typography: {
    fontFamily: [
      "EB Garamond",
      "Söhne Breit Buch",
      "Söhne Breit Fett",
      "serif",
    ].join(","),
  },
});
