import React from "react";

export const Fi8 = () => {
  return (
    <svg
      viewBox='0 0 300 300'
      style={{ fill: "none", stroke: "#000064", strokeWidth: 1.5 }}
    >
      <g>
        <circle
          vectorEffect='non-scaling-stroke'
          cx='150'
          cy='150'
          r='25.398'
        />
      </g>
    </svg>
  );
};

export const Fi16 = () => {
  return (
    <svg
      viewBox='0 0 300 300'
      style={{ fill: "none", stroke: "#000064", strokeWidth: 1.5 }}
    >
      <g>
        <circle
          vectorEffect='non-scaling-stroke'
          cx='150'
          cy='150'
          r='50.797'
        />
      </g>
    </svg>
  );
};

export const Fi37 = () => {
  return (
    <svg
      viewBox='0 0 300 300'
      style={{ fill: "none", stroke: "#000064", strokeWidth: 1.5 }}
    >
      <g>
        <circle
          vectorEffect='non-scaling-stroke'
          cx='150'
          cy='150'
          r='117.468'
        />
      </g>
    </svg>
  );
};

export const Fi50 = () => {
  return (
    <svg
      viewBox='0 0 300 300'
      style={{ fill: "none", stroke: "#000064", strokeWidth: 1.5 }}
    >
      <g>
        <polygon
          vectorEffect='non-scaling-stroke'
          points='102.046,103.7 197.954,103.7 197.954,107.547 102.046,107.547 102.046,103.7 	'
        />
        <polyline
          vectorEffect='non-scaling-stroke'
          points='183.997,107.547 183.997,196.3 116.003,196.3 116.003,107.547 116.003,107.547 	'
        />
      </g>
    </svg>
  );
};

export const Fi70 = () => {
  return (
    <svg
      viewBox='0 0 300 300'
      style={{ fill: "none", stroke: "#000064", strokeWidth: 1.5 }}
    >
      <g>
        <polygon
          vectorEffect='non-scaling-stroke'
          points='82.856,116.405 217.144,116.405 217.144,120.252 82.856,120.252 82.856,116.405 	'
        />
        <polyline
          vectorEffect='non-scaling-stroke'
          points='187.799,120.252 187.799,183.595 112.201,183.595 112.201,120.252 112.201,120.252 	'
        />
      </g>
    </svg>
  );
};

export const Fi90 = () => {
  return (
    <svg
      viewBox='0 0 300 300'
      style={{ fill: "none", stroke: "#000064", strokeWidth: 1.5 }}
    >
      <g>
        <polygon
          vectorEffect='non-scaling-stroke'
          points='63.666,125.978 236.334,125.978 236.334,129.825 63.666,129.825 63.666,125.978 	'
        />
        <polyline
          vectorEffect='non-scaling-stroke'
          points='196.433,129.825 196.433,174.022 103.567,174.022 103.567,129.825 103.567,129.825 	'
        />
      </g>
    </svg>
  );
};

export const Fi85 = () => {
  return (
    <svg
      viewBox='0 0 300 300'
      style={{ fill: "none", stroke: "#000064", strokeWidth: 1.5 }}
    >
      <g>
        <polygon
          vectorEffect='non-scaling-stroke'
          points='68.452,78.023 231.548,78.023 231.548,81.87 68.452,81.87 68.452,78.023 	'
        />
        <polyline
          vectorEffect='non-scaling-stroke'
          points='120.879,81.869 120.879,81.869 120.879,221.977 179.211,221.977 179.211,81.869 	'
        />
      </g>
    </svg>
  );
};

export const Fi110 = () => {
  return (
    <svg
      viewBox='0 0 300 300'
      style={{ fill: "none", stroke: "#000064", strokeWidth: 1.5 }}
    >
      <g>
        <polygon
          vectorEffect='non-scaling-stroke'
          points='44.43,77.978 255.57,77.978 255.57,81.825 44.43,81.825 44.43,77.978 	'
        />
        <polyline
          vectorEffect='non-scaling-stroke'
          points='112.156,81.914 112.156,81.914 112.156,222.022 187.844,222.022 187.844,81.914 	'
        />
      </g>
    </svg>
  );
};
export const Fi135 = () => {
  return (
    <svg
      viewBox='0 0 300 300'
      style={{ fill: "none", stroke: "#000064", strokeWidth: 1.5 }}
    >
      <g>
        <polygon
          vectorEffect='non-scaling-stroke'
          points='28.676,82.563 271.324,82.563 271.324,86.086 28.676,86.086 28.676,82.563 	'
        />
        <polyline
          vectorEffect='non-scaling-stroke'
          points='106.511,86.17 106.511,86.17 106.511,217.437 193.489,217.437 193.489,86.17 	'
        />
      </g>
    </svg>
  );
};
