import React, { useEffect } from "react";
import HomeComponentsHero from "./components/AboutFIComponentsHero";
import HomeComponentsText from "./components/AboutFIComponentsText";
import HomeDetailHero from "./components/AboutFIDetailHero";
import HomeDetailText from "./components/AboutFIDetailText";
import HomeForboHero from "./components/AboutFIForboHero";
import HomeForboText from "./components/AboutFIForboText";
import HomeAssemblyHero from "./components/AboutFIAssemblyHero";
import HomeAssemblyText from "./components/AboutFIAssemblyText";
import { useResetRecoilState } from "recoil";
import { appBarState } from "../../commons/AppBarState";
import { footerState } from "../../commons/FooterState";

export const AboutFI = () => {
  const setFooter = useResetRecoilState(footerState);
  useEffect(() => setFooter(), [setFooter]);

  const setAppBar = useResetRecoilState(appBarState);
  useEffect(() => setAppBar(), [setAppBar]);

  return (
    <React.Fragment>
      <HomeComponentsHero />
      <HomeComponentsText />
      <HomeDetailHero />
      <HomeDetailText />
      <HomeForboHero />
      <HomeForboText />
      <HomeAssemblyHero />
      <HomeAssemblyText />
    </React.Fragment>
  );
};
