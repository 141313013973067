import React, { createContext, FC } from "react";
import {
  Fi8,
  Fi16,
  Fi37,
  Fi50,
  Fi70,
  Fi90,
  Fi85,
  Fi110,
  Fi135,
} from "./SVGExports";

export interface IProducts {
  id: number;
  title: string;
  price: any;
  home?: boolean;
  color: "White" | "Green" | "Black";
  colorName?: string;
  colorName2?: string;
  colorHex?: string;
  colorHex2?: string;
  img: string;
  svg?: JSX.Element;
  purpose: string;
  height?: number;
  width?: number;
  fi: number;
}

const products: IProducts[] = [
  {
    id: 1,
    title: "FI 50",
    price: 2200,
    home: false,
    color: "White",
    colorName: "4176 — Mushroom",
    colorHex: "#E6E6E1",
    img: "/img/FI/PASIEKA_FI50_white.jpg",
    svg: <Fi50 />,
    purpose: "stolik S",
    height: 50,
    width: 30,
    fi: 50,
  },
  {
    id: 2,
    title: "FI 50",
    price: 2200,
    home: false,
    color: "Green",
    colorName: "4184 — Olive",
    colorHex: "#7E8D7E",
    img: "/img/FI/PASIEKA_FI50_green.jpg",
    svg: <Fi50 />,
    purpose: "stolik S",
    height: 50,
    width: 30,
    fi: 50,
  },
  {
    id: 3,
    title: "FI 50",
    price: 2200,
    home: false,
    color: "Black",
    colorName: "4023 — Nero",
    colorHex: "#1E1F21",
    img: "/img/FI/PASIEKA_FI50_black.jpg",
    svg: <Fi50 />,
    purpose: "stolik S",
    height: 50,
    width: 30,
    fi: 50,
  },
  {
    id: 4,
    title: "FI 70",
    price: 2500,
    home: true,
    color: "White",
    colorName: "4176 — Mushroom",
    colorHex: "#E6E6E1",
    img: "/img/FI/PASIEKA_FI70_white.jpg",
    svg: <Fi70 />,
    purpose: "stolik M",
    height: 35,
    width: 39,
    fi: 70,
  },
  {
    id: 5,
    title: "FI 70",
    price: 2500,
    home: false,
    color: "Green",
    colorName: "4184 — Olive",
    colorHex: "#7E8D7E",
    img: "/img/FI/PASIEKA_FI70_green.jpg",
    svg: <Fi70 />,
    purpose: "stolik M",
    height: 35,
    width: 39,
    fi: 70,
  },
  {
    id: 6,
    title: "FI 70",
    price: 2500,
    home: false,
    color: "Black",
    colorName: "4023 — Nero",
    colorHex: "#1E1F21",
    img: "/img/FI/PASIEKA_FI70_black.jpg",
    svg: <Fi70 />,
    purpose: "stolik M",
    height: 35,
    width: 39,
    fi: 70,
  },
  {
    id: 7,
    title: "FI 90",
    price: 2800,
    home: false,
    color: "White",
    colorName: "4176 — Mushroom",
    colorHex: "#E6E6E1",
    img: "/img/FI/PASIEKA_FI90_white.jpg",
    svg: <Fi90 />,
    purpose: "stolik L",
    height: 25,
    width: 48,
    fi: 90,
  },
  {
    id: 8,
    title: "FI 90",
    price: 2800,
    home: false,
    color: "Green",
    colorName: "4184 — Olive",
    colorHex: "#7E8D7E",
    img: "/img/FI/PASIEKA_FI90_green.jpg",
    svg: <Fi90 />,
    purpose: "stolik L",
    height: 25,
    width: 48,
    fi: 90,
  },
  {
    id: 9,
    title: "FI 90",
    price: 2800,
    home: false,
    color: "Black",
    colorName: "4023 — Nero",
    colorHex: "#1E1F21",
    img: "/img/FI/PASIEKA_FI90_black.jpg",
    svg: <Fi90 />,
    purpose: "stolik L",
    height: 25,
    width: 48,
    fi: 90,
  },
  {
    id: 10,
    title: "FI 85",
    price: 4000,
    home: false,
    color: "White",
    colorName: "4176 — Mushroom",
    colorHex: "#E6E6E1",
    img: "/img/FI/PASIEKA_FI85_white.jpg",
    svg: <Fi85 />,
    purpose: "stół dla 3 os.",
    height: 76,
    width: 30,
    fi: 85,
  },
  {
    id: 11,
    title: "FI 85",
    price: 4000,
    home: false,
    color: "Green",
    colorName: "4184 — Olive",
    colorHex: "#7E8D7E",
    img: "/img/FI/PASIEKA_FI85_green.jpg",
    svg: <Fi85 />,
    purpose: "stół dla 3 os.",
    height: 76,
    width: 30,
    fi: 85,
  },
  {
    id: 12,
    title: "FI 85",
    price: 4000,
    home: true,
    color: "Black",
    colorName: "4023 — Nero",
    colorHex: "#1E1F21",
    img: "/img/FI/PASIEKA_FI85_black.jpg",
    svg: <Fi85 />,
    purpose: "stół dla 3 os.",
    height: 76,
    width: 30,
    fi: 85,
  },
  {
    id: 13,
    title: "FI 110",
    price: 5300,
    home: false,
    color: "White",
    colorName: "4176 — Mushroom",
    colorHex: "#E6E6E1",
    img: "/img/FI/PASIEKA_FI110_white.jpg",
    svg: <Fi110 />,
    purpose: "stół dla 5 os.",
    height: 76,
    width: 39,
    fi: 110,
  },
  {
    id: 14,
    title: "FI 110",
    price: 5300,
    home: false,
    color: "Green",
    colorName: "4184 — Olive",
    colorHex: "#7E8D7E",
    img: "/img/FI/PASIEKA_FI110_green.jpg",
    svg: <Fi110 />,
    purpose: "stół dla 5 os.",
    height: 76,
    width: 39,
    fi: 110,
  },
  {
    id: 15,
    title: "FI 110",
    price: 5300,
    home: false,
    color: "Black",
    colorHex: "#1E1F21",
    colorName: "4023 — Nero",
    img: "/img/FI/PASIEKA_FI110_black.jpg",
    svg: <Fi110 />,
    purpose: "stół dla 5 os.",
    height: 76,
    width: 39,
    fi: 110,
  },
  {
    id: 16,
    title: "FI 135",
    price: 6300,
    home: false,
    color: "White",
    colorName: "4176 — Mushroom",
    colorHex: "#E6E6E1",
    img: "/img/FI/PASIEKA_FI135_white.jpg",
    svg: <Fi135 />,
    purpose: "stół dla 7 os.",
    height: 76,
    width: 48,
    fi: 135,
  },
  {
    id: 17,
    title: "FI 135",
    price: 6300,
    home: true,
    color: "Green",
    colorName: "4184 — Olive",
    colorHex: "#7E8D7E",
    img: "/img/FI/PASIEKA_FI135_green.jpg",
    svg: <Fi135 />,
    purpose: "stół dla 7 os.",
    height: 76,
    width: 48,
    fi: 135,
  },
  {
    id: 18,
    title: "FI 135",
    price: 6300,
    home: false,
    color: "Black",
    colorName: "4023 — Nero",
    colorHex: "#1E1F21",
    img: "/img/FI/PASIEKA_FI135_black.jpg",
    svg: <Fi135 />,
    purpose: "stół dla 7 os.",
    height: 76,
    width: 48,
    fi: 135,
  },
  {
    id: 19,
    title: "FI 8",
    price: undefined,
    home: false,
    color: "White",
    colorName: "Mushroom",
    colorName2: "Nero",
    colorHex: "#E6E6E1",
    colorHex2: "#1E1F21",
    img: "/img/FI/PASIEKA_FI8_white.jpg",
    svg: <Fi8 />,
    purpose: "podkładka S",
    fi: 8,
  },
  {
    id: 20,
    title: "FI 8",
    price: undefined,
    home: false,
    color: "Black",
    colorName: "Nero",
    colorName2: "Olive",
    colorHex: "#1E1F21",
    colorHex2: "#7E8D7E",
    img: "/img/FI/PASIEKA_FI8_black.jpg",
    svg: <Fi8 />,
    purpose: "podkładka S",
    fi: 8,
  },
  {
    id: 21,
    title: "FI 8",
    price: undefined,
    home: false,
    color: "Green",
    colorName: "Olive",
    colorName2: "Mushroom",
    colorHex: "#7E8D7E",
    colorHex2: "#E6E6E1",
    img: "/img/FI/PASIEKA_FI8_green.jpg",
    svg: <Fi8 />,
    purpose: "podkładka S",
    fi: 8,
  },
  {
    id: 22,
    title: "FI 16",
    price: undefined,
    home: false,
    color: "White",
    colorName: "Mushroom",
    colorName2: "Nero",
    colorHex: "#E6E6E1",
    colorHex2: "#1E1F21",
    img: "/img/FI/PASIEKA_FI16_white.jpg",
    svg: <Fi16 />,
    purpose: "podkładka M",
    fi: 16,
  },
  {
    id: 23,
    title: "FI 16",
    price: undefined,
    home: false,
    color: "Black",
    colorName: "Nero",
    colorName2: "Olive",
    colorHex: "#1E1F21",
    colorHex2: "#7E8D7E",
    img: "/img/FI/PASIEKA_FI16_black.jpg",
    svg: <Fi16 />,
    purpose: "podkładka M",
    fi: 16,
  },
  {
    id: 24,
    title: "FI 16",
    price: undefined,
    home: false,
    color: "Green",
    colorName: "Olive",
    colorName2: "Mushroom",
    colorHex: "#7E8D7E",
    colorHex2: "#E6E6E1",
    img: "/img/FI/PASIEKA_FI16_green.jpg",
    svg: <Fi16 />,
    purpose: "podkładka M",
    fi: 16,
  },
  {
    id: 25,
    title: "FI 37",
    price: undefined,
    home: false,
    color: "White",
    colorName: "Mushroom",
    colorName2: "Nero",
    colorHex: "#E6E6E1",
    colorHex2: "#1E1F21",
    img: "/img/FI/PASIEKA_FI37_white.jpg",
    svg: <Fi37 />,
    purpose: "podkładka L",
    fi: 37,
  },
  {
    id: 26,
    title: "FI 37",
    price: undefined,
    home: false,
    color: "Black",
    colorName: "Nero",
    colorName2: "Olive",
    colorHex: "#1E1F21",
    colorHex2: "#7E8D7E",
    img: "/img/FI/PASIEKA_FI37_black.jpg",
    svg: <Fi37 />,
    purpose: "podkładka L",
    fi: 37,
  },
  {
    id: 27,
    title: "FI 37",
    price: undefined,
    home: false,
    color: "Green",
    colorName: "Olive",
    colorName2: "Mushroom",
    colorHex: "#7E8D7E",
    colorHex2: "#E6E6E1",
    img: "/img/FI/PASIEKA_FI37_green.jpg",
    svg: <Fi37 />,
    purpose: "podkładka L",
    fi: 37,
  },
];

export const ProductsCtx = createContext<IProducts[]>(products);

const Products: FC = ({ children }) => {
  return (
    <ProductsCtx.Provider value={products}>{children}</ProductsCtx.Provider>
  );
};

export default Products;
