import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { footerState } from "../../commons/FooterState";
import { useResetRecoilState } from "recoil";
import { useTranslation, Trans } from "react-i18next";

export default function CheckoutConfimation() {
  const setFooter = useResetRecoilState(footerState);
  useEffect(() => setFooter(), [setFooter]);
  const { t } = useTranslation();
  return (
    <Box ml={[3, 9, 12, 12, 18]} mr={[3, 9, 12, 12, 18]}>
      <Grid container alignItems='center'>
        <Grid item xs={12} md={2} xl={2} />
        <Grid item xs={12} md={6} xl={6}>
          <Box mt={[6, 9, 9, 9, 36]} mb={[3, 6, 8, 8, 36]} pr={4}>
            <Typography color='primary' variant='h1' paragraph>
              <Trans t={t} i18nKey='checkout.confirmation'>
                Dziękujemy za zamówienie.
              </Trans>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
