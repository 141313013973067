import React, { useState } from "react";
import { Box, Hidden, Grid } from "@material-ui/core";
import { useSpring, animated } from "react-spring";

function HomeComponentsHero() {
  const toggle = useState(false);
  const [propsMain, setMain] = useSpring(() => ({ transform: "scale(1)" }));
  const [propsSecondary, setSecondary] = useSpring(() => ({
    transform: "scale(1)",
  }));

  return (
    <Box ml={[3, 6, 8, 8, 30]} mr={[3, 6, 8, 8, 30]} pt={[8, 0]}>
      <Grid container>
        <Hidden mdDown>
          <Grid item md={5} xl={4}>
            <Box mr={[0, 0, 8]} mb={[4, 4, 0]}>
              <animated.div
                onMouseEnter={() =>
                  setMain({ transform: toggle ? "scale(0.96)" : "scale(1)" })
                }
                onMouseLeave={() =>
                  setMain({ transform: !toggle ? "scale(0.96)" : "scale(1)" })
                }
                style={{ ...propsMain, willChange: "transform" }}
              >
                <img
                  src='/img/fi_web_abstract_greenWhite.jpg'
                  alt='FI Detail'
                  style={{ width: `100%` }}
                />
              </animated.div>
            </Box>
          </Grid>
        </Hidden>
        <Grid item xs={9} md={4} xl={3}>
          <Box>
            <animated.div
              onMouseEnter={() =>
                setSecondary({ transform: toggle ? "scale(0.96)" : "scale(1)" })
              }
              onMouseLeave={() =>
                setSecondary({
                  transform: !toggle ? "scale(0.96)" : "scale(1)",
                })
              }
              // onRest={() => stopSecondary()}
              style={{ ...propsSecondary, willChange: "transform" }}
            >
              <img
                src='/img/fi_web_abstract_blackGreen.jpg'
                alt='FI Detail'
                style={{ width: `100%` }}
              />
            </animated.div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomeComponentsHero;
