import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { fiTheme } from "../../commons/Theme";
import { appBarState } from "../../commons/AppBarState";
import { footerState } from "../../commons/FooterState";
import { useRecoilState, useResetRecoilState } from "recoil";
import { useInView } from "react-intersection-observer";
import IntroWrapper from "./components/IntroWrapper";
import Intro from "./components/Intro";
import CreatorsWrapper from "./components/CreatorsWrapper";
import Creators from "./components/Creators";
// import OfferWrapper from "./components/OfferWrapper";
// import Offer from "./components/Offer";
import ContactWrapper from "./components/ContactWrapper";
import Contact from "./components/Contact";

export default function About() {
  const [refIntro, inViewIntro] = useInView({
    threshold: 0,
  });

  const [refCreators, inViewCreators] = useInView({
    threshold: 0.2,
  });

  // const [refOffer, inViewOffer] = useInView({
  //   threshold: 0.2,
  // });

  const [refContact, inViewContact] = useInView({
    threshold: 0.5,
  });

  const [{ appBarColor, appBarBg, transisionTime }, setAppBar] =
    useRecoilState(appBarState);

  const setFooter = useResetRecoilState(footerState);
  useEffect(() => setFooter(), [setFooter]);

  useEffect(() => {
    if (inViewIntro) {
      setAppBar({
        appBarColor: fiTheme.palette.primary.main,
        appBarBg: fiTheme.palette.background.default,
        transisionTime: "all 2s",
      });
    }
    if (inViewCreators) {
      setAppBar({
        appBarColor: fiTheme.palette.primary.main,
        appBarBg: "#C5CDC7",
        transisionTime: "all 2s",
      });
    }
    if (inViewContact) {
      setAppBar({
        appBarColor: fiTheme.palette.primary.main,
        appBarBg: "#8B9C8F",
        transisionTime: "all 2s",
      });
    }
  }, [inViewIntro, inViewCreators, inViewContact, setAppBar]);

  return (
    <Box
      style={{
        backgroundColor: appBarBg,
        color: appBarColor,
        transition: transisionTime,
      }}
    >
      <IntroWrapper>
        <Intro ref={refIntro} />
      </IntroWrapper>
      <CreatorsWrapper>
        <Creators ref={refCreators} />
      </CreatorsWrapper>
      {/* <OfferWrapper>
        <Offer ref={refOffer} />
      </OfferWrapper> */}
      <ContactWrapper>
        <Contact ref={refContact} />
      </ContactWrapper>
    </Box>
  );
}
