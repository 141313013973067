import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { ProductConfiguration } from "./components/ProductConfiguration";
import { ProductGallery } from "./components/ProductGallery";
import { useResetRecoilState } from "recoil";
import { appBarState } from "../../commons/AppBarState";
import { footerState } from "../../commons/FooterState";
import { ProductAccessoriesGallery } from "./components/ProductAccessoriesGallery";

type TParams = { productId: string };

export const ProductDetails = ({ match }: RouteComponentProps<TParams>) => {
  const {
    params: { productId },
  } = match;

  const setFooter = useResetRecoilState(footerState);
  useEffect(() => setFooter(), [setFooter]);

  const setAppBar = useResetRecoilState(appBarState);
  useEffect(() => setAppBar(), [setAppBar]);

  return (
    <React.Fragment>
      <ProductConfiguration product={productId} />
      {parseInt(productId) <= 18 ? (
        <ProductGallery />
      ) : (
        <ProductAccessoriesGallery />
      )}
    </React.Fragment>
  );
};
