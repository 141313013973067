import { atom } from "recoil";
import { fiTheme } from "./Theme";

export interface appBarStateTypes {
  appBarColor: string;
  appBarBg: string;
  appBarPosition: string;
  transisionTime: string;
}

export const appBarState = atom<appBarStateTypes>({
  key: "appBarState",
  default: {
    appBarColor: fiTheme.palette.primary.main,
    appBarBg: fiTheme.palette.background.default,
    appBarPosition: "sticky",
    transisionTime: "all 0s",
  },
});
