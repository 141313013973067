import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSpring, useTransition, animated } from "react-spring";

import FIAssembly1 from "../../../assets/img/FIAssembly/FIAssembly-01.png";
import FIAssembly2 from "../../../assets/img/FIAssembly/FIAssembly-02.png";
import FIAssembly3 from "../../../assets/img/FIAssembly/FIAssembly-03.png";
import FIAssembly4 from "../../../assets/img/FIAssembly/FIAssembly-04.png";
import FIAssembly5 from "../../../assets/img/FIAssembly/FIAssembly-05.png";
import FIAssembly6 from "../../../assets/img/FIAssembly/FIAssembly-06.png";
import FIAssembly7 from "../../../assets/img/FIAssembly/FIAssembly-07.png";
import FIAssembly8 from "../../../assets/img/FIAssembly/FIAssembly-08.png";

const useStyles = makeStyles({
  relativeDiv: {
    position: "relative",
    width: "100%",
    height: "800px",
    willChange: "scale",
    "@media (max-width:1280px)": {
      width: "100%",
      height: "480px",
    },
    "@media (max-width:960px)": {
      width: "100%",
      height: "320px",
    },
  },
  assemblySlides: {
    position: "absolute",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "800px",
    willChange: "opacity",
    "@media (max-width:1280px)": {
      width: "100%",
      height: "480px",
    },
    "@media (max-width:960px)": {
      width: "100%",
      height: "320px",
    },
  },
  assemblyPagination: {
    position: "absolute",
    zIndex: "-1",
    bottom: "50%",
    width: "100%",
    backgroundColor: "white",
  },
});

function HomeAssemblyHero() {
  const [assemblySlides] = useState([
    { id: 0, backgroundImage: `url(${FIAssembly1})`, pagination: "1 | 8" },
    { id: 1, backgroundImage: `url(${FIAssembly2})`, pagination: "2 | 8" },
    { id: 2, backgroundImage: `url(${FIAssembly3})`, pagination: "3 | 8" },
    { id: 3, backgroundImage: `url(${FIAssembly4})`, pagination: "4 | 8" },
    { id: 4, backgroundImage: `url(${FIAssembly5})`, pagination: "5 | 8" },
    { id: 5, backgroundImage: `url(${FIAssembly6})`, pagination: "6 | 8" },
    { id: 6, backgroundImage: `url(${FIAssembly7})`, pagination: "7 | 8" },
    { id: 7, backgroundImage: `url(${FIAssembly8})`, pagination: "8 | 8" },
  ]);

  const classes = useStyles();
  const toggle = useState(false);
  const [props, set] = useSpring(() => ({ transform: "scale(1)" }));

  const [index, setIndex] = useState(0);
  const transitions = useTransition(assemblySlides[index], (item) => item.id, {
    from: { opacity: 0.5 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    trail: 200,
    unique: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((state) => ++state % 8);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box ml={[3, 6, 8, 8, 30]} mr={[3, 6, 8, 8, 30]}>
      <Grid container>
        <Grid item xs={12} md={8} xl={6}>
          <Box className={classes.relativeDiv}>
            <animated.div
              onMouseEnter={() =>
                set({ transform: toggle ? "scale(0.96)" : "scale(1)" })
              }
              onMouseLeave={() =>
                set({ transform: !toggle ? "scale(0.96)" : "scale(1)" })
              }
              style={props}
            >
              {transitions.map(({ item, props, key }) => (
                <animated.div
                  className={classes.assemblySlides}
                  key={key}
                  style={{ ...props, backgroundImage: item.backgroundImage }}
                />
              ))}
            </animated.div>
            <animated.div>
              {transitions.map(({ item, key }) => (
                <animated.div key={key}>
                  <Box
                    className={classes.assemblyPagination}
                    pl={[0, 4, 4, 4, 8]}
                  >
                    <Typography color='primary' variant='h1'>
                      {item.pagination}
                    </Typography>
                  </Box>
                </animated.div>
              ))}
            </animated.div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomeAssemblyHero;
