import React from "react";
import { Box, Button, Grid, Hidden, Typography } from "@material-ui/core";
import { useRecoilState } from "recoil";
import { cartState } from "../../../commons/CartState";
import { useSpring, animated } from "react-spring";
import { PurposeTrans } from "../../ProductList/components/PurposeTrans";

function replaceItemAtIndex(arr, index, newValue) {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
}

function removeItemAtIndex(arr, index) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

function handleSubZero(value) {
  return value > 0 ? (value = value - 1) : (value = 0);
}

export default function CartItem({ item }) {
  const [cart, setCart] = useRecoilState(cartState);
  const index = cart.findIndex((cartItem) => cartItem === item);

  const handleMinusItem = () => {
    const newCart = replaceItemAtIndex(cart, index, {
      ...item,
      quantity: handleSubZero(item.quantity),
    });
    setCart(newCart);
  };

  const handlePlusItem = () => {
    const newCart = replaceItemAtIndex(cart, index, {
      ...item,
      quantity: item.quantity + 1,
    });
    setCart(newCart);
  };

  const removeItem = () => {
    const newCart = removeItemAtIndex(cart, index);
    setCart(newCart);
  };

  const propsScale = useSpring({
    transform: "scale(1)",
    opacity: 1,
    from: { transform: "scale(0.96)", opacity: 0 },
  });

  return (
    <Box color='primary.main' borderColor='primary.main' borderBottom={2}>
      <Grid container>
        <Grid item container xs={3} md={3} lg={3}>
          <Grid item xs={12}>
            <animated.div style={propsScale}>
              <img src={item.img} alt='FI Table' style={{ width: `100%` }} />
            </animated.div>
          </Grid>
          <Grid item>
            <Box pb={2} textAlign='right'>
              <Button size='small' onClick={removeItem}>
                <Typography color='primary' variant='subtitle2'>
                  {" "}
                  X{" "}
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item container xs={9} md={9} lg={9}>
          <Grid item xs={12} md={12} lg={3}>
            <Box color='primary.main' mt={2} mb={1}>
              <Typography variant='h1'>{item.title}</Typography>
            </Box>
            <Box color='primary.main'>
              <Typography variant='body1'>
                {item.fi}/{item.width}/{item.height} cm
              </Typography>
            </Box>
            <Box color='primary.main'>
              <Typography variant='body1'>
                <PurposeTrans purpose={item.purpose} />
              </Typography>
            </Box>
            <Box color='primary.main'>
              <Typography variant='body1'>{item.colorName}</Typography>
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={4} lg={3}>
              <Box color='primary.main' mt={3}>
                <Typography variant='subtitle2'>{item.price} PLN</Typography>
              </Box>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item xs={2} md={4} lg={3}>
              <Grid container alignItems='center' justify='space-between'>
                <Grid item xs={4}>
                  <Box color='primary.main' mt={2}>
                    <Button size='small' onClick={handleMinusItem}>
                      <Typography variant='subtitle2'> - </Typography>
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box color='primary.main' mt={2}>
                    <Typography variant='subtitle2' align='center'>
                      {item.quantity}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box color='primary.main' mt={2}>
                    <Button size='small' onClick={handlePlusItem}>
                      <Typography variant='subtitle2'> + </Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid
              container
              item
              xs={2}
              md={4}
              lg={3}
              direction='column'
              justify='space-between'
            >
              <Grid item>
                <Box color='primary.main' mt={3}>
                  <Typography variant='subtitle2' align='right'>
                    {item.quantity * item.price} PLN
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <Hidden mdUp>
        <Box mb={1}>
          <Grid container>
            <Grid item xs={4}>
              <Box color='primary.main' mt={0}>
                <Typography variant='subtitle2'>{item.price} PLN</Typography>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Grid container alignItems='center' justify='space-between'>
                <Grid item xs={4}>
                  <Box color='primary.main' mt={-1}>
                    <Button size='small' onClick={handleMinusItem}>
                      <Typography variant='subtitle2'> - </Typography>
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box color='primary.main' mt={-1}>
                    <Typography variant='subtitle2' align='center'>
                      {item.quantity}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box color='primary.main' mt={-1}>
                    <Button size='small' onClick={handlePlusItem}>
                      <Typography variant='subtitle2'> + </Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid item>
                <Box color='primary.main' mt={0}>
                  <Typography variant='subtitle2' align='right'>
                    {item.quantity * item.price} PLN
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Hidden>
    </Box>
  );
}
