export const TRANSLATIONS_PL = {
  navigation: {
    product: "Sklep",
    fi: "O FI",
    aboutUs: "O Nas",
    cart: "Koszyk",
  },
  footer: {
    design:
      "Projekt — Katarzyna Dobiecka / Kody — Grzegorz Łotysz / Fonty — Söhne Breit i EB Garamond",
    copyright:
      "Copyright © 2021 PASIEKA. Dopuszcza się kopiowanie materiałów z powyższej strony wyłącznie w celu dalszej promocji marki Pasieka. Wszelkie prawa zastrzeżone.",
    terms: "Regulamin",
    privacy: "Polityka prywatności",
  },
  home: {
    hero: {
      title: "Kolekcja FI",
      winner: "Najlepszy projekt w konkursie Forbo",
      interview: "Wywiad z projektantką",
      aboutFI: "Przeczytaj o FI",
      headline:
        "FI to okrągłe stoły i stoliki o minimalistycznej formie i oryginalnym wykończeniu materiałem Forbo Furniture Linoleum. Bryła walca doskonale wykorzystuje właściwości elastycznego materiału i razem z oklejonym blatem stanowi wizualną jedność. Stół w takim wydaniu wygląda lekko, świeżo − po prostu pięknie.",
      paragraph:
        "Podstawowa paleta kolekcji to trzy uniwersalne kolory, które zostały dobrze odwzorowane na fotografiach wykonanych w naturalnym świetle. Trzy średnice stołów oraz trzy wielkości stolików są odpowiedzią na różne potrzeby. Sprawdzą się jako pojedynczy mebel w przestrzeni domowej lub jako grupa do większych wnętrz publicznych.",
      cta: "Zamów FI",
    },
  },
  product: {
    purpose: {
      s: "stolik S",
      m: "stolik M",
      l: "stolik L",
      for3: "stół dla 3 os.",
      for5: "stół dla 5 os.",
      for7: "stół dla 7 os.",
      aS: "podkładka S",
      aM: "podkładka M",
      aL: "podkładka L",
    },
    extraColors: "na zamówienie",
    cta: "Dodaj do koszyka",
    ctaDisabled: "Dostępne wkrótce",
    info: {
      about: "O produkcie",
      aboutContent: {
        aboutTitle: "O kolekcji",
        aboutParagraph:
          "FI to okrągłe stoły i stoliki o minimalistycznej formie i oryginalnym wykończeniu linoleum meblowym Forbo. Sprawdzą się jako pojedynczy mebel w przestrzeni domowej lub jako grupa do większych wnętrz publicznych. Kolekcja Fi dostała główną nagrodę w prestiżowym konkursie „Linoleum Od Nowa”, zorganizowanym przez markę Forbo.",
        materials: "Materiały",
        materialsParagraph1:
          "Blat – linoleum meblowe Forbo / sklejka brzozowa / laminat hpl",
        materialsParagraph2:
          "Noga – linoleum meblowe Forbo / sklejka brzozowa / tuleja kartonowa",
        materialsParagraph3: "Elementy montażowe – stal",
        dimensions: "Wymiary w cm (średnica blatu / średnica nogi / wysokość)",
        dimensionsParagraph:
          "FI 50 (50∕30∕50), FI 70 (70∕39∕35), FI 90 (90∕48∕25), FI 85 (85∕30∕75), FI 110 (110∕39∕75), FI 135 (135∕48∕75)",
        colors: "Kolory FI",
        colorsParagraph:
          "Podstawowe kolory kolekcji to Mushroom, Olive, Nero z palety Forbo Furniture Linoleum. Stoły i stoliki w innych kolorach dostępne na zapytanie za dodatkową opłatą.",
        more: "Szczegółowa specyfikacja mebla w karcie produktu.",
      },
      order: "Szczegóły zamówienia",
      orderContent: {
        timing: "Termin realizacji",
        timingParagraph:
          "Od momentu opłaty zamówienia czas realizacji wynosi 6-8 tygodni.",
        transport: "Koszt transportu",
        transportParagraph: "Dostawa na terenie Polski 0 złotych.",
        returns: "Zwroty",
        returnsParagraph:
          "Wszystkie stoły i stoliki FI są każdorazowo wykonywane na zamówienie według określonej specyfikacji i nie podlegają zwrotom.",
        more: "Szczegółowe zasady określa Regulamin sklepu.",
      },
      gallery: "Galeria",
    },
  },
  cart: { summary: "Zestawienie", total: "Suma", cta: "Kontynuuj" },
  checkout: {
    form: {
      contact: "Dane kontaktowe",
      delivery: "Adres dostawy",
      invoice: "Dane do faktury",
      note: "Prześlij do nas swoje zamówienie klikając w przycisk „Zamawiam”. W odpowiedzi dostaniesz email ze wszystkimi informacjami dotyczącymi jego finalizacji. Masz 7 dni na płatność. Zamówienie zostanie przyjęte do realizacji po zaksięgowaniu przelewu.",
      labels: {
        name: "imię i nazwisko",
        email: "email",
        phone: "telefon",
        deliveryName: "imię i nazwisko",
        companyName: "nazwa firmy*",
        country: "kraj",
        address: "adres",
        zipCode: "kod pocztowy",
        city: "miasto",
        vatNumber: "NIP",
      },
      validation: {
        required: "Pole obowiązkowe",
        email: "Upewnij się czy to poprawny email",
        phone: "Upewnij się czy to poprawny nr telefonu",
        company: "Upewnij się czy to poprawny NIP",
      },
    },
    orderSummary: "Moje zamówienie",
    cost: "Kwota",
    delivery: "Dostawa",
    toPay: "Do zapłaty",
    termsOptIn:
      "Przeczytałem/am i akceptuję regulamin oraz politykę prywatności",
    cta: "Zamawiam",
    confirmation: "Dziękujemy za zamówienie.",
  },
  aboutFI: {
    assembly: "Montaż",
    assemblyParagraph:
      "Montaż każdego stołu i stolika wyglada tak samo. Do podnoszenia większych FI potrzebne są dwie osoby. Stół montujemy w pozycji odwróconej, tak by wkręcić pręt do spodu blatu, następnie osadzić nogę i całość skręcić śrubą ukrytą w dnie sklejki. Stół jest wyposażony w regulowane stopki, które stabilizują go na nierównych podłogach, ułatwiają przesuwanie i chronią przed zarysowaniem.",
    components: "Elementy",
    componentsParagraph:
      "Podstawa stołu FI wykonana jest z grubej kartonowej tulei i razem z wyważonym dnem tworzy szkielet dla linoleum. Okrągły blat to sandwich wysokiej jakości materiałów: linoleum meblowe, sklejka brzozowa, laminat hpl.",
    componentsParagraph2:
      "Dzięki pomysłowej konstrukcji połączenie blatu i nogi jest całkowicie niewidoczne.",
    detail: "Detal",
    detailParagraph:
      "Powierzchnia linoleum meblowego jest ciepła w dotyku i idealnie matowa dla oka bez widocznych odcisków palców. Naturalne właściwości antystatyczne zapobiegają osadzaniu się kurzu i brudu.",
    detailParagraph2:
      "Wszystkie ostre krawędzie stołu, również te od strony podłogi zostały wykończone minimalną fazą. Obrzeże ze sklejki jest gładkie i zaimpregnowane woskiem.",
    linoleum: "Linoleum Forbo",
    linoleumParagraph:
      "Forbo Furniture Linoleum to unikatowy, ekologiczny produkt szwajcarskiego producenta Forbo znanego z całej gamy materiałów wykończeniowych. Granulat linoleum jest mieszanką utlenionego oleju lnianego oraz żywicy z dodatkiem mączki drzewnej i barwników. Rozwałkowywany na papierowym podkładzie tworzy gruby, jednobarwny rdzeń. Po wyschnięciu impregnuje się go akrylową powłoką ochronną na bazie wody, która zapewnia okładzinie trwałość oraz łatwość pielęgnacji i użytkowania.",
    linoleumParagraph2:
      "Furniture Linoleum jest produktem neutralnym pod względem emisji CO2. To w 100% naturalny produkt wytwarzany z naturalnych materiałów nadających się do recyklingu.",
    linoleumLink: "Więcej o linoleum meblowym Forbo",
  },
  aboutUs: {
    contact: "Kontakt",
    address: "Wrocław, Polska",
    creators: "Twórcy",
    creatorsKD1:
      "Współzałożycielka i projektantka marki. Architekta z wszechstronnym doświadczeniem projektowym. Działa na styku wielu dziedzin i w różnej skali. Jej udziałem są projekty przestrzeni publicznych, wnętrz, mebli, opakowań a także grafiki użytkowej. Pomysły czerpie z nieustannej obserwacji trendów i potrzeb oraz problemów z tym związanych. Jej obiekty cechuje prosty i czytelny koncept oraz subtelny detal. Dąży do oryginalności w ramach prostych środków i dostępnych rozwiązań. Kontroluje cały proces: od koncepcji, przez szczegółowy projekt po nadzór nad jego wykonaniem.",
    creatorsKD2:
      "Współlaureatka i laureatka konkursów, takich jak Rewitalizacja Bulwaru Xawerego Dunikowskiego we Wrocławiu, identyfikacja wizualna DoFA, CREA AWARDS Vibia, H&M Home i ELLE „Współczesny Nomada”, katalog wystawy głównej MHŻP Polin, identyfikacja programu 100 na 100. Muzyczne Dekady Wolności, Polish Graphic Design Awards, Linoleum Od Nowa Forbo. Jej lampa, stół i meble modułowe były prezentowane na wystawach make me!, Wroclove Design, Gdynia Design Days, Gwangju International Design Biennale.",
    creatorsGL:
      "Współzałożyciel marki. Creative Director, Product Designer oraz przedsiębiorca z międzynarodowym doświadczeniem w projektowaniu i rozwoju marek oraz produktów. Na co dzień pracuje na stanowisku Senior Product Designer w Boldare.",
    intro: "O marce",
    introParagraph:
      "Pasieka to miejsce mocno zdefiniowane przez swoją funkcję oraz wygląd. Ten wyodrębniony obszar pola odznacza się oryginalną, spójną dla znajdujących się tam obiektów formą i kolorystyką. Warstwa dekoracyjna nie jest wartością nadrzędną pasieki. Jest (tylko i aż) wyrazem estetycznych upodobań jej właściciela-twórcy.",
    introParagraph2:
      "PASIEKA to proste meble i przedmioty, które powstały w celu zaspokojenia własnych potrzeb mieszkaniowych. Ich forma jest wynikiem poszukiwania indywidualnych rozwiązań projektowych, nie mających swoich odpowiedników na rynku.",
  },
  terms: {
    intro:
      "Niniejszy Regulamin określa ogólne warunki korzystania, zasady oraz sposób sprzedaży produktów marki PASIEKA, prowadzonej przez Grzegorza Łotysza, prowadzącego jednoosobową działalność gospodarczą, zwanego dalej „Sprzedawcą”, za pośrednictwem strony internetowej www.mypasieka.com, zwanego dalej „Sklepem internetowym”.",
    definitions: {
      title: "§ 1 DEFINICJE",
      pasieka:
        "PASIEKA – nazwa marki promującej produkty wyposażenia wnętrz autorstwa Katarzyny Dobieckiej i prowadzącej ich sprzedaż za pośrednictwem Sklepu Internetowego, działającego pod adresem www.mypasieka.com",
      shop: "Sklep internetowy (sklep PASIEKA) –  serwis internetowy dostępny pod adresem www.mypasieka.com, za pośrednictwem którego Kupujący może w szczególności składać Zamówienia.",
      assets:
        "Materiały – zdjęcia Produktów oraz pozostałe materiały (w tym w szczególności teksty, grafiki, logotypy, kody źródłowe) będące utworami w rozumieniu przepisów ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (t. j.: Dz. U. z 2019 r. poz. 1231, 2245), zamieszczone w Sklepie internetowym i będące własnością Sprzedawcy lub użyte przez Sprzedawcę za zgodą osoby lub podmiotu trzeciego, któremu przysługują prawa własności intelektualnej do Materiałów.",
      seller:
        "Sprzedawca – Grzegorz Łotysz, prowadzący jednoosobową działalność gospodarczą pod adresem: ulica Mickiewicza 107/4, 51-685 Wrocław, wpisaną do Centralnej Ewidencji i Informacji o Działalności Gospodarczej, posiadającej nr NIP: 754-270-63-49",
      buyer:
        "Kupujący – Konsument lub Przedsiębiorca korzystający z usług świadczonych przez Sprzedawcę drogą elektroniczną w ramach Sklepu internetowego lub zawierający ze Sprzedawcą Umowę sprzedaży,",
      consument:
        "Konsument – osoba fizyczna dokonująca czynności prawnej z przedsiębiorcą niezwiązanej bezpośrednio z jej działalnością gospodarczą lub zawodową,",
      entrepreneur:
        "Przedsiębiorca – osoba fizyczna, osoba prawna lub jednostka nieposiadająca osobowości prawnej, dokonująca czynności prawnej we własnym imieniu w ramach działalności gospodarczej lub zawodowej,",
      courier:
        "Dostawca – wyspecjalizowany podmiot świadczący usługi przewozu wyznaczony przez Sprzedającego do wykonywania jego zobowiązań związanych z dostawą zamówienia do Zamawiającego,",
      orderForm:
        "Formularz Zamówienia – dostępny w Sklepie internetowym formularz, umożliwiający Kupującemu złożenie Zamówienia,",
      order:
        "Zamówienie – oświadczenie woli Kupującego, stanowiące ofertę zawarcia Umowy sprzedaży ze Sprzedawcą,",
      contract:
        "Umowa Sprzedaży – umowa sprzedaży zawierana pomiędzy Sprzedawcą i Kupującym na odległość za pośrednictwem Sklepu internetowego, dotycząca zakupu Produktów",
      cart: "Koszyk – wirtualne narzędzie umożliwiające Kupującemu agregację wybranych Produktów przed ich zakupem oraz przeliczanie ich wartości, przy czym dokonując wyboru Produktów Kupujący może dowolnie zarządzać zawartością Koszyka poprzez dodawanie lub usuwanie z niego Produktów,",
      product:
        "Produkt – dostępna w Sklepie internetowym rzecz ruchoma, będąca przedmiotem Umowy Sprzedaży,",
      bill: "Dowód Zakupu – faktura VAT wystawiana przez Sprzedawcę i potwierdzająca zawarcie Umowy Sprzedaży",
    },
    generals: {
      title: "§ 2 POSTANOWIENIA OGÓLNE",
      1: "1. Niniejszy Regulamin dotyczy Umów Sprzedaży oraz innych usług świadczonych przez Sprzedawcę za pośrednictwem Sklepu internetowego.",
      2: "2. Sklep internetowy prowadzi sprzedaż na terytorium Rzeczypospolitej Polskiej.",
      3: "3. Produkty dostępne w Sklepie internetowym są oryginalne, fabrycznie nowe (wolne od wad) oraz zgodne z obowiązującymi standardami, wymaganiami i przepisami prawa.",
      4: "4. Sprzedawca dokłada najwyższej staranności przy realizacji Umów Sprzedaży oraz Zamówień.",
      5: "5. Począwszy od dnia 1 stycznia 2021 r. - w przypadku Przedsiębiorcy będącego osobą fizyczną prowadzącą działalność gospodarczą wpisaną do Centralnej Ewidencji i Informacji o Działalności Gospodarczej (CEIDG), który zawiera Umowę Sprzedaży związaną bezpośrednio z prowadzoną przez niego działalnością gospodarczą, gdy jednocześnie z treści Umowy Sprzedaży wynika, że nie posiada ona dla takiego Przedsiębiorcy charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez takiego Przedsiębiorcę działalności gospodarczej udostępnionego w CEIDG (w tym w szczególności z numerów PKD) - postanowienia niniejszego Regulaminu dotyczące Kupującego będącego Konsumentem stosuje się również do takiego Przedsiębiorcy, z wyłączeniem przepisu art. 558 1 zdanie drugie Kodeksu cywilnego. Wobec Przedsiębiorcy, o którym mowa w niniejszym punkcie, począwszy od dnia 1 stycznia 2021 r. nie stosuje się również przepisów art. 563 oraz art. 567 § 2 Kodeksu cywilnego.",
    },
    rules: {
      title: "§ 3 ZASADY KORZYSTANIA ZE SKLEPU INTERNETOWEGO",
      1: "1. Kupujący zobowiązany jest do korzystania ze Sklepu internetowego w sposób zgodny z obowiązującym prawem, zasadami współżycia społecznego oraz dobrymi obyczajami, mając na uwadze poszanowanie dóbr osobistych oraz praw własności intelektualnej osób lub podmiotów trzecich.",
      2: "2. Korzystanie ze Sklepu internetowego jest całkowicie dobrowolne.",
      3: "3. Przy wypełnianiu Formularza Rejestracji lub Formularza Zamówienia, Kupujący zobowiązany jest do zapoznania się z treścią Regulaminu i Polityki prywatności oraz do akceptacji ich treści.",
      4: "4. Podczas korzystania ze Sklepu internetowego, Kupujący jest zobowiązany do podawania prawdziwych oraz zgodnych ze stanem faktycznym danych i informacji koniecznych do założenia Konta lub zawarcia i realizacji Umowy Sprzedaży.",
      5: "5. Zarówno Kupujący, jak i każda inna osoba mająca dostęp do Sklepu internetowego, zobowiązana jest do powstrzymywania się od kopiowania, modyfikowania, rozpowszechniania, przedruku, transmitowania lub wykorzystywania w jakikolwiek inny sposób (w tym w szczególności w celach marketingowych, handlowych lub zarobkowych), Materiałów zamieszczonych w Sklepie internetowym bez pisemnej zgody Sprzedawcy albo innej osoby lub podmiotu trzeciego, któremu przysługują prawa własności intelektualnej do Materiałów, z wyjątkiem korzystania z tych Materiałów w ramach dozwolonego użytku, o którym mowa w przepisach ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (t. j.: Dz. U. z 2019 r. poz. 1231, 2245).",
      6: "6. Naruszenie postanowień punktu poprzedzającego mogłoby stanowić naruszenie prawa, a tym samym stanowić podstawę do wszczęcia postępowania cywilnego lub karnego przeciwko osobom lub podmiotom dopuszczającym się takich praktyk.",
    },
    orders: {
      title: "§ 4 SKŁADANIE ZAMÓWIEŃ",
      1: {
        title: "1. Kupujący składa Zamówienie:",
        1: "za pośrednictwem Sklepu internetowego, działającego pod adresem www.mypasieka.com",
        2: "za pośrednictwem poczty elektronicznej na adres mypasieka@gmail.com",
      },
      2: "2. W celu złożenia Zamówienia za pośrednictwem Sklepu internetowego, Kupujący po wyborze Produktu i określeniu jego cech klika przycisk „Złóż zamówienie”, gdzie przechodzi do zestawienia Koszyka i podsumowania kosztów. Po kliknięciu przycisku „Kontynuuj”, Kupujący wypełnia Formularz Zamówienia, następnie składa Zamówienie dedykowanym przyciskiem „Zamawiam”. Jest to jednoznaczne z przesłaniem na adres Sklepu internetowego wiadomości e-mail,  następnie otrzymania automatycznego e-maila potwierdzającego ze wszystkimi informacjami niezbędnymi do finalizacji Zamówienia.",
      3: "3. Złożenie Zamówienia i otrzymanie od Sprzedającego e-maila potwierdzającego ten fakt, nie jest równoznaczne z zawarciem Umowy Sprzedaży.",
      4: "4. Termin na wykonanie płatności wynosi 7 dni od dnia otrzymania e-maila potwierdzającego. Zamówienie zostaje przyjęte do realizacji po dokonaniu wpłaty przez Kupującego i zaksięgowaniu jej na rachunku bankowym Sprzedającego. W przypadku braku wpłaty w tym czasie, Zamówienie jest anulowane.",
      5: "5. Informacja o rozpoczęciu realizacji Zamówienia zostanie przesłana Kupującemu przez Sprzedawcę w formie wiadomości e-mail po odnotowaniu wpłaty. Od tego momentu między Kupującym a Sprzedawcą zostaje zawarta Umowa Sprzedaży.",
      6: "6. Jeśli po opłaceniu zamówienia przez Kupującego, Sprzedający stwierdzi, że zamówienie nie może być zrealizowane, Sprzedający niezwłocznie poinformuje o tym Kupującego za pośrednictwem poczty elektronicznej lub telefonicznie i środki finansowe zostaną zwrócone w terminie do 7 dni od momentu zawiadomienia.",
    },
    payments: {
      title: "§ 5 PŁATNOŚCI",
      1: "1. W związku z realizacją Zamówień oraz Umów, Sprzedawcy przysługuje wynagrodzenie według cen dostępnych w Sklepie internetowym i obowiązujących w momencie składania Zamówienia.",
      2: "2. Ceny podane w Sklepie internetowym wyrażone są w polskich złotych, są cenami brutto i zawierają wszystkie składniki, w tym podatek VAT oraz cła.",
      3: "3. O całkowitej cenie za Produkty objęte Zamówieniem, która uwzględnia koszty dostawy, Kupujący informowany jest przed złożeniem Zamówienia.",
      4: "4. Kupujący, po złożeniu Zamówienia i otrzymaniu e-maila potwierdzającego ma 7 dni na dokonanie płatności za zamówiony Produkt.",
      5: {
        title:
          "5. Kupujący dokonuje płatności za zamówiony Produkt przelewem bezpośrednio na rachunek bankowy Sprzedającego:",
        1: "Dane rachunku bankowego:",
        2: "mBank",
        3: "KOD BIC/SWIFT: BREXPLPWMBK",
        4: "Numery kont bankowych:",
        5: "Dla wpłat w zł (PLN):",
        6: "IBAN: PL 95 1140 2004 0000 3102 6873 1478",
      },
      6: "6. Do każdego Zamówienia dołączany jest Dowód Zakupu, przy czym Kupujący wyraża zgodę, że jako Dowód Zakupu otrzyma fakturę elektroniczną na podany w Formularzu Zamówienia adres e-mail.",
    },
    processing: {
      title: "§ 6 TERMIN REALIZACJI",
      1: "1. Do każdego Produktu przyporządkowany jest termin realizacji Zamówienia. Termin realizacji Zamówienia określony jest w tygodniach i biegnie od daty zaksięgowania wpłaty na rachunku bankowym Sprzedającego.",
      2: "2. Termin otrzymania Zamówienia jest sumą terminu realizacji Zamówienia i kilkudniowego czasu dostawy.",
    },
    delivery: {
      title: "§ 7 DOSTAWA",
      1: "1. Koszty dostawy Zamówienia na terenie Polski ponosi Sprzedający.",
      2: "2. Koszty dostawy Zamówienia poza granicami Polski są ustalane indywidualnie.",
      3: "3. Kupujący zobowiązuje się do odebrania wysłanego Produktu.",
      4: "4. Sprzedawca dostarcza swoje produkty wielkogabarytowe tj. stoły i stoliki na paletach za pośrednictwem renomowanych firm spedycyjnych. Realizacja dostaw odbywa się w godzinach od 8 do 16 w dniach roboczych. Kupujący otrzymuje link do śledzenia swojej przesyłki na stronie internetowej firmy transportowej, gdzie może uzyskać numer telefonu do kuriera doręczającego przesyłkę i ustalić z nim dokładniejszy czas dostawy.",
      5: "5. Zgodnie z art. 43. Prawa przewozowego czynności ładunkowe należą odpowiednio do obowiązków nadawcy lub odbiorcy. Kierowca doręcza przesyłkę pod wskazany adres do granicy posesji i dokonuje wydania przesyłki na podłoże, na którym stoi samochód kierowcy.",
      6: "6. Kupujący ma obowiązek sprawdzić stan przesyłki i jej zawartość w obecności Dostawcy. W przypadku stwierdzenia jej uszkodzenia zobowiązany jest zgłosić ten fakt Dostawcy w ciągu 7 dni kalendarzowych i niezwłocznie skontaktować się ze Sprzedającym. W takim wypadku wysłany zostanie kurier, który spisze protokół. UWAGA! Spisanie protokołu nie jest jednoznaczne ze złożeniem reklamacji.",
      7: "7. Kupujący ma obowiązek pisemnie pokwitować odbiór przesyłki od Dostawcy.",
      8: "8. Z chwilą pokwitowania na Kupującego przechodzi prawo własności zamówionego Produktu oraz wszelkie ryzyko związane z jego posiadaniem i użytkowaniem, a w szczególności ryzyko utraty lub uszkodzenia.",
    },
    withdrawal: {
      title: "§ 8 ODSTĄPIENIE OD UMOWY SPRZEDAŻY",
      1: "1. Kupującemu będącemu Konsumentem przysługuje prawo odstąpienia od Umowy Sprzedaży jako umowy zawartej na odległość, bez podania przyczyny, w terminie 14 dni od dnia otrzymania Produktu, chyba że zastosowanie ma wyłączenie, o którym mowa w pkt 8 niniejszego paragrafu.",
      2: {
        title:
          "2. Skorzystanie z uprawnienia, o którym mowa w punkcie poprzedzającym, wymaga złożenia przez Kupującego we wskazanym terminie oświadczenia o odstąpieniu od Umowy Sprzedaży:",
        1: "w przypadku formy papierowej – korespondencyjnie na adres Sprzedawcy: Mickiewicza 107/4, 51-685 Wrocław",
        2: "w przypadku formy poczty elektronicznej – na adres e-mail Sprzedawcy: mypasieka@gmail.com",
      },
      3: "3. Odstępując od Umowy Sprzedaży Kupujący zobowiązany jest do zwrotu Produktu wraz z oryginałem lub kopią Dowodu Zakupu albo innym dowodem potwierdzającym dokonanie zakupu zwracanego Produktu, w terminie 14 dni od daty złożenia oświadczenia o odstąpieniu od Umowy Sprzedaży. Zwroty Produktów objętych oświadczeniem o odstąpieniu od Umowy Sprzedaży, wysyłane na koszt Sprzedawcy lub za pobraniem, nie będą przyjmowane przez Sprzedawcę.",
      4: "4. Koszty wysyłki zwracanego Produktu ponosi Kupujący.",
      5: "5. Kupujący ponosi odpowiedzialność za zmniejszenie wartości Produktu będące wynikiem korzystania z niego w sposób wykraczający poza konieczny do stwierdzenia charakteru, cech i funkcjonowania Produktu.",
      6: "6. Sprzedawca zwraca Kupującemu cenę Produktu oraz koszty dostawy poniesione przez Kupującego przy zakupie Produktu nie później niż w terminie 14 dni od dnia doręczenia Produktu objętego oświadczeniem o odstąpieniu od Umowy Sprzedaży, z uwzględnieniem pkt 7 niniejszego paragrafu.",
      7: "7. Sprzedawca dokonuje zwrotu płatności przy użyciu takiego samego sposobu zapłaty, jakiego użył Kupujący przy zakupie Produktu, chyba że Kupujący wyraźnie zgodził się na inny sposób zwrotu, który nie wiąże się dla niego z dodatkowymi kosztami. Jednakże w przypadku, gdy Kupujący wybrał inny niż najtańszy zwykły sposób dostawy Produktu oferowany przez Sprzedawcę, Sprzedawca nie jest zobowiązany do zwrotu Kupującemu poniesionych przez niego dodatkowych kosztów.",
      8: "8. Prawo odstąpienia od Umowy Sprzedaży, o którym mowa w niniejszym paragrafie, nie przysługuje Kupującemu, który dokonał zamówienia towaru nieprefabrykowanego, wyprodukowanego każdorazowo według określonej specyfikacji. Dotyczy to w szczególności mebli, w przypadku których Kupujący ma możliwość wyboru rozmiaru i koloru wykończenia. Prawu odstąpienia od Umowy Sprzedaży nie podlegają stoły i stoliki z kolekcji FI.",
      9: "9. Szczegóły dotyczące korzystania z prawa odstąpienia od Umowy Sprzedaży - jako umowy zawartej na odległość - określają przepisy ustawy z dnia 30 maja 2014 r. o prawach konsumenta (t. j.: Dz. U. z 2019 r. poz. 134, 730).",
      10: "10. Postanowienia niniejszego paragrafu nie stosuje się do Kupujących będących Przedsiębiorcami innymi niż Przedsiębiorcy, o których mowa w § 2 pkt 5 Regulaminu.",
    },
    complaints: {
      title: "§ 9 REKLAMACJE",
      1: "1. Reklamacje w niniejszym paragrafie związane z jakością towaru, z wyłączeniem uszkodzenia w transporcie.",
      2: "2. Sprzedawca odpowiada z tytułu rękojmi za wady Produktu, jeżeli wada została stwierdzona przed upływem dwóch lat od dnia wydania Produktu Kupującemu.",
      3: "3. Reklamacja powinna zawierać: dane Kupującego, informacje dotyczące Produktu i Zamówienia, opis i data powstania wady Produktu oraz żądanie Kupującego. Kupujący zobowiązany jest również do przekazania oryginału lub kopii Dowodu Zakupu albo innego dowodu potwierdzającego dokonanie zakupu reklamowanego Produktu, jak również do odesłania reklamowanego Produktu, aby możliwe było rozpatrzenie reklamacji.",
      4: {
        title: "4. Reklamacje należy składać:",
        1: "w przypadku formy papierowej – korespondencyjnie na adres Sprzedawcy: Mickiewicza 107/4, 51-685 Wrocław",
        2: "w przypadku formy poczty elektronicznej – na adres e-mail Sprzedawcy: mypasieka@gmail.com",
        3: "Produkty odsyłane w ramach procedury reklamacyjnej należy wysyłać na adres wskazany każdorazowo przez Sprzedawcę.",
      },
      5: "5. Jeżeli podane w reklamacji dane lub informacje wymagają uzupełnienia, przed rozpatrzeniem reklamacji Sprzedawca zwróci się do składającego reklamację o jej uzupełnienie we wskazanym zakresie.",
      6: "6. Sprzedawca zobowiązuje się do rozpatrzenia reklamacji złożonej przez Kupującego będącego Konsumentem, który w ramach reklamacji zażądał wymiany Produktu lub usunięcia wady albo złożył oświadczenie o obniżeniu ceny, określając kwotę, o którą cena ma być obniżona, w ciągu 14 dni od dnia jej złożenia lub uzupełnienia przez Kupującego.",
      7: "7. Odpowiedzialność Sprzedawcy z tytułu rękojmi wobec Kupujących będących Przedsiębiorcami zostaje całkowicie wyłączona, co nie dotyczy Przedsiębiorców, o których mowa w § 2 pkt 5 Regulaminu.",
    },
    provisions: {
      title: "§ 10 POSTANOWIENIA KOŃCOWE",
      1: "1. Sprzedawca zastrzega sobie prawo do wprowadzenia ograniczeń w korzystaniu ze Sklepu internetowego spowodowanych jego serwisem technicznym, pracami konserwacyjnymi lub pracami nad polepszeniem jego funkcjonalności.",
      2: "2. Sprzedawca zastrzega sobie prawo do zmiany niniejszego Regulaminu. Zmiany wchodzą w życie w momencie wyraźnie wskazanym przez Sprzedawcę, nie wcześniej niż po 7 dniach od dnia ich ogłoszenia. Zamówienia złożone przed wejściem w życie zmian, o których mowa w zdaniu poprzedzającym, będą realizowane na zasadach obowiązujących w momencie ich składania.",
      3: "3. Wszelkie spory między Sprzedawcą a Kupującym – pod warunkiem ich obopólnej zgody - rozstrzygane będą w sposób polubowny.",
      4: "4. W przypadku braku możliwości polubownego rozwiązania sporu, sądem właściwym miejscowo do rozpatrywania sporów będzie właściwy rzeczowo Sąd dla adresu Sprzedawcy.",
      5: "5. W sprawach nieuregulowanych niniejszym Regulaminem zastosowanie będą miały właściwe przepisy prawa powszechnie obowiązującego, w tym w szczególności przepisy Kodeksu cywilnego, ustawy o prawach konsumenta, ustawy o prawie autorskim i prawach pokrewnych oraz przepisy o ochronie danych osobowych.",
      6: "6. Regulamin obowiązuje od dnia 01/08/2021 r.",
    },
  },
  privacy: {
    title:
      "Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych przekazanych przez Użytkowników w związku z korzystaniem przez nich usług świadczonych przez Serwis PASIEKA.",
    1: "W trosce o bezpieczeństwo powierzonych nam danych opracowaliśmy wewnętrzne procedury i zalecenia, które mają zapobiec udostępnieniu danych osobom nieupoważnionym. Kontrolujemy ich wykonywanie i stale sprawdzamy ich zgodność z odpowiednimi aktami prawnymi - ustawą o ochronie danych osobowych, ustawą o świadczeniu usług drogą elektroniczną, a także wszelkiego rodzaju aktach wykonawczych i aktach prawa wspólnotowego.",
    2: "Dane Osobowe przetwarzane są na podstawie zgody wyrażonej przez Użytkownika oraz w przypadkach, w których przepisy prawa upoważniają Administratora do przetwarzania danych osobowych na podstawie przepisów prawa lub w celu realizacji zawartej pomiędzy stronami umowy.",
    3: "Serwis pozyskuje informacje o użytkownikach poprzez dobrowolnie wprowadzone w formularzach dane kontaktowe o oraz ich zachowaniu poprzez gromadzenie plików cookies usługi Google Analytics.",
    4: "Serwis zbiera informacje dobrowolnie podane przez użytkownika.",
    5: "Dane podane w formularzu są przetwarzane tylko i wyłącznie w celu realizacji zamówienia.",
    6: "Dane osobowe pozostawione w serwisie nie zostaną sprzedane ani udostępnione osobom trzecim, zgodnie z przepisami Ustawy o ochronie danych osobowych.",
    7: "Do danych zawartych w formularzu przysługuje wgląd osobie fizycznej, która je tam umieściła. Osoba ta ma również praw do modyfikacji i zaprzestania przetwarzania swoich danych w dowolnym momencie.",
    8: "Zastrzegamy sobie prawo do zmiany w polityce ochrony prywatności serwisu, na które może wpłynąć rozwój technologii internetowej, ewentualne zmiany prawa w zakresie ochrony danych osobowych oraz rozwój naszego serwisu internetowego. O wszelkich zmianach będziemy informować w sposób widoczny i zrozumiały.",
    9: "W Serwisie mogą pojawiać się linki do innych stron internetowych. Takie strony internetowe działają niezależnie od Serwisu PASIEKA i nie są w żaden sposób przez nas nadzorowane. Strony te mogą posiadać własne polityki dotyczące prywatności oraz regulaminy, z którymi zalecamy się zapoznać.",
    10: "Serwis PASIEKA w celu pozyskiwania danych o zachowaniach użytkowników korzysta z usługi Google Analytics. Usługa Google Analytics korzysta z plików cookies, dzięki którym może zapisywać i przetwarzać dane dotyczące odwiedzin, lokalizacji, urządzeń czy przeglądarek użytkowników. PASIEKA korzysta z tych danych tylko i wyłącznie w celu poprawy jakości działania Serwisu.",
    admin: {
      title: "W razie pytań do zapisów niniejszej zachęcamy do kontaktu.",
      1: "Administratorem danych osobowych jest",
      2: "Grzegorz Łotysz",
      3: "ul. Mickiewicza 107/4",
      4: "51-685 Wrocław",
      5: "NIP 754-270-63-49",
      6: "mypasieka@gmail.com",
    },
  },
};
