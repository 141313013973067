import React, { useState } from "react";
import { Box, Collapse, Grid, Typography } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";

export const ProductAccessoriesInfo = () => {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { t } = useTranslation();

  return (
    <Box mb={[0, 0, 0, 2, 16]}>
      <Grid container>
        <Grid item xs={12}>
          <Box pt={12}>
            <Box
              onClick={() => handleExpandClick()}
              style={{ cursor: "pointer" }}
            >
              <Typography color='primary' variant='h2'>
                <Trans t={t} i18nKey=''>
                  O produkcie
                </Trans>
              </Typography>
            </Box>
            <Collapse in={expanded} timeout={750}>
              <Grid container item direction='column' xs={12}>
                <Box pt={4}>
                  <Typography color='primary' variant='body2'>
                    <Trans t={t} i18nKey=''>
                      O kolekcji
                    </Trans>
                  </Typography>
                </Box>
                <Box>
                  <Typography color='primary' variant='body2'>
                    <Trans t={t} i18nKey=''>
                      Akcesoria FI to okrągłe podkładki na stół wykonane w 100%
                      z linoleum Forbo. Produkt jest uzupełnieniem kolekcji
                      stołów FI i kolejnym znakiem rozpoznawalnym marki PASIEKA.
                      Podkładki FI mają dwie strony i dwa kontrastowe kolory
                      więc możemy zmieniać wygląd nakrycia w zależności od
                      okazji, dania czy nastroju. Linoleum meblowe, z którego
                      wykonane są podkładki to naturalny, elastyczny, wytrzymały
                      i higieniczny materiał o wyjątkowej palecie kolorystycznej
                      i ciepłej matowej powierzchni.
                    </Trans>
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography color='primary' variant='body2'>
                    <Trans t={t} i18nKey=''>
                      Materiały
                    </Trans>
                  </Typography>
                </Box>
                <Box>
                  <Typography color='primary' variant='body2'>
                    <Trans t={t} i18nKey=''>
                      Linoleum meblowe Forbo
                    </Trans>
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography color='primary' variant='body2'>
                    <Trans t={t} i18nKey=''>
                      Wymiary w cm (średnica)
                    </Trans>
                  </Typography>
                </Box>
                <Box>
                  <Typography color='primary' variant='body2'>
                    <Trans t={t} i18nKey=''>
                      FI 8 (8), FI 16 (16), FI 37 (37)
                    </Trans>
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography color='primary' variant='body2'>
                    <Trans t={t} i18nKey=''>
                      Kolory FI
                    </Trans>
                  </Typography>
                </Box>
                <Box>
                  <Typography color='primary' variant='body2'>
                    <Trans t={t} i18nKey=''>
                      Podstawowe zestawy kolorystyczne to Mushroom-Nero,
                      Nero-Olive i Olive-Mushroom z palety Forbo Furniture
                      Linoleum. Akcesoria w innych kolorach dostępne na
                      zapytanie za dodatkową opłatą.
                    </Trans>
                  </Typography>
                </Box>
                <Box pt={2} pb={4}>
                  <Typography color='primary' variant='body2'>
                    <Trans t={t} i18nKey=''>
                      Szczegółowa specyfikacja wszystkich akcesoriów w karcie
                      produktu.{" "}
                    </Trans>
                  </Typography>
                </Box>
              </Grid>
            </Collapse>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
