import React from "react";
import { useTranslation, Trans } from "react-i18next";

export const PurposeTrans: any = ({ purpose }: any) => {
  const { t } = useTranslation();
  switch (purpose) {
    case "stolik S":
      return (
        <Trans i18nKey='product.purpose.s' t={t}>
          {purpose}
        </Trans>
      );
    case "stolik M":
      return (
        <Trans i18nKey='product.purpose.m' t={t}>
          {purpose}
        </Trans>
      );
    case "stolik L":
      return (
        <Trans i18nKey='product.purpose.l' t={t}>
          {purpose}
        </Trans>
      );
    case "stół dla 3 os.":
      return (
        <Trans i18nKey='product.purpose.for3' t={t}>
          {purpose}
        </Trans>
      );
    case "stół dla 5 os.":
      return (
        <Trans i18nKey='product.purpose.for5' t={t}>
          {purpose}
        </Trans>
      );
    case "stół dla 7 os.":
      return (
        <Trans i18nKey='product.purpose.for7' t={t}>
          {purpose}
        </Trans>
      );
    case "podkładka S":
      return (
        <Trans i18nKey='product.purpose.aS' t={t}>
          {purpose}
        </Trans>
      );
    case "podkładka M":
      return (
        <Trans i18nKey='product.purpose.aM' t={t}>
          {purpose}
        </Trans>
      );
    case "podkładka L":
      return (
        <Trans i18nKey='product.purpose.aL' t={t}>
          {purpose}
        </Trans>
      );

    default:
      return <></>;
  }
};
