import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Hidden,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { fiTheme } from "../../../commons/Theme";
import { useTranslation, Trans } from "react-i18next";

const useStyles = makeStyles({
  linksItems: {
    color: fiTheme.palette.primary.light,
    height: "80px",
    padding: "0",
    "&:hover": {
      color: fiTheme.palette.primary.main,
    },
  },
  "@media (max-width:1440px)": {
    height: "48px",
  },
  listColors: {
    height: "80px",
    padding: "0",
    borderTop: `2px solid ${fiTheme.palette.primary.main}`,
    "@media (max-width:1440px)": {
      height: "48px",
    },
  },
  colors: {
    height: "32px",
    "@media (max-width:1440px)": {
      height: "24px",
    },
  },
});

export const ListExtraColors = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <List disablePadding={true} dense={false}>
      <ListItem
        className={classes.listColors}
        divider={false}
        disableGutters={true}
      >
        <Grid container alignItems='center'>
          <Hidden mdDown>
            <Grid item xl={2} />
          </Hidden>
          <Grid item xs={6} md={6} xl={4}>
            <Typography color='primary' variant='body1'>
              <Trans t={t} i18nKey='product.extraColors'>
                na zapytanie
              </Trans>
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} xl={6}>
            <Grid container>
              <Grid item xs={2}>
                <Box
                  className={classes.colors}
                  style={{ backgroundColor: "#F4E7C7" }}
                ></Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  className={classes.colors}
                  style={{ backgroundColor: "#DBC8BB" }}
                ></Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  className={classes.colors}
                  style={{ backgroundColor: "#E86138" }}
                ></Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  className={classes.colors}
                  style={{ backgroundColor: "#99292B" }}
                ></Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  className={classes.colors}
                  style={{ backgroundColor: "#0C3027" }}
                ></Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  className={classes.colors}
                  style={{ backgroundColor: "#265E88" }}
                ></Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    </List>
  );
};
