import React, { useContext, useState } from "react";
import { useRecoilState } from "recoil";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { ProductsCtx } from "../../../commons/ProductsCtx";
import { cartState, IItem } from "../../../commons/CartState";
import { ProductInfo } from "./ProductInformation";
import { ProductImages } from "./ProductImages";
import { ProductSizes } from "./ProductSizes";
import { ProductColors } from "./ProductColors";
import { useTranslation, Trans } from "react-i18next";
import { ProductAccessoriesInfo } from "./ProductAccessoriesInformation";

export const ProductConfiguration = (props: any) => {
  const DetailsContext = useContext(ProductsCtx);

  const {
    id,
    title,
    price,
    color,
    colorName,
    colorName2,
    colorHex,
    colorHex2,
    img,
    purpose,
    height,
    width,
    fi,
  } = DetailsContext[parseInt(props.product) - 1];

  const [index] = useState(parseInt(props.product) - 1);

  const [cart, setCart] = useRecoilState(cartState);

  const addToCart = (
    id: any,
    title: any,
    price: any,
    color: any,
    colorName: any,
    colorName2: any,
    img: any,
    purpose: any,
    height: any,
    width: any,
    fi: any
  ) => {
    const newCart: IItem[] = [...cart];
    const foundIndex = cart.findIndex((a) => a.id === id);

    if (foundIndex >= 0) {
      newCart[foundIndex] = {
        ...cart[foundIndex],
        quantity: cart[foundIndex].quantity! + 1,
      };
      return newCart;
    }

    newCart.push({
      id: id,
      title: title,
      price: price,
      color: color,
      colorName: colorName,
      colorName2: colorName2,
      img: img,
      purpose: purpose,
      height: height,
      width: width,
      fi: fi,
      quantity: 1,
    });
    return newCart;
  };

  const handleAddToCart = () => {
    const newCart: IItem[] = addToCart(
      id,
      title,
      price,
      color,
      colorName,
      colorName2,
      img,
      purpose,
      height,
      width,
      fi
    );
    setCart(newCart);
  };

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Box ml={[3, 9, 12, 12, 36]} mr={[3, 9, 12, 12, 36]}>
        <Grid container>
          <Grid item xs={12} md={6} lg={6}>
            <ProductImages image={parseInt(props.product) - 1} />
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            lg={6}
            direction='column'
            justify='space-between'
          >
            <Grid item>
              <Box
                p={0}
                mt={[3, 6, 8, 8, 12]}
                borderTop={2}
                borderBottom={2}
                color='primary.main'
                borderColor='primary.main'
              >
                <ProductSizes
                  size={index}
                  title={title}
                  fi={fi}
                  width={width}
                  height={height}
                  purpose={purpose}
                  price={price}
                />
                <ProductColors
                  color={index}
                  fi={fi}
                  colorName={colorName}
                  colorName2={colorName2}
                  colorHex={colorHex}
                  colorHex2={colorHex2}
                />
              </Box>
              <Box mt={[2, 2, 2, 2, 3]}>
                {price ? (
                  <Button
                    variant='contained'
                    size='large'
                    color='secondary'
                    fullWidth={true}
                    disableRipple={false}
                    disableElevation
                    onClick={handleAddToCart}
                  >
                    <Typography color='primary' variant='subtitle2'>
                      <Trans t={t} i18nKey='product.cta'>
                        Dodaj do koszyka
                      </Trans>
                    </Typography>
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    size='large'
                    color='secondary'
                    fullWidth={true}
                    disableRipple={false}
                    disableElevation
                    disabled
                  >
                    <Typography color='primary' variant='subtitle2'>
                      <Trans t={t} i18nKey='product.ctaDisabled'>
                        Dostępne wkrótce
                      </Trans>
                    </Typography>
                  </Button>
                )}
              </Box>
              {fi > 40 ? <ProductInfo /> : <ProductAccessoriesInfo />}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};
