import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation, Trans } from "react-i18next";

function HomeComponentsText() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Box
        mt={[2, 2, 4, 4, 6]}
        ml={[3, 9, 12, 12, 36]}
        mr={[3, 9, 12, 12, 36]}
        pb={[4, 4, 6, 12, 18]}
      >
        <Grid container justify='space-between'>
          <Grid item xs={12} md={4} lg={6}>
            <Box>
              <Typography color='primary' variant='h2' paragraph>
                <Trans t={t} i18nKey='aboutFI.components'>
                  Elementy
                </Trans>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Box>
              <Box>
                <Typography color='primary' variant='h2' paragraph>
                  <Trans t={t} i18nKey='aboutFI.componentsParagraph'>
                    Podstawa stołu FI wykonana jest z grubej kartonowej tulei i
                    razem z wyważonym dnem tworzy szkielet dla linoleum. Okrągły
                    blat to sandwich wysokiej jakości materiałów: linoleum
                    meblowe, sklejka brzozowa, laminat hpl.
                  </Trans>
                </Typography>
              </Box>
              <Box mt={[3, 6]} pr={[0, 16]}>
                <Typography color='primary' variant='body1' paragraph>
                  <Trans t={t} i18nKey='aboutFI.componentsParagraph2'>
                    Dzięki pomysłowej konstrukcji połączenie blatu i nogi jest
                    całkowicie niewidoczne.
                  </Trans>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default HomeComponentsText;
