import React from "react";
import { Box, Grid, Hidden, Link, Typography } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";

export const HomeHeroText = () => {
  const { t } = useTranslation();

  return (
    <Box
      mt={[3, 6, 6, 8, 8]}
      ml={[3, 9, 12, 12, 36]}
      mr={[3, 9, 12, 12, 36]}
      pb={[4, 4, 4, 6, 6]}
    >
      <Grid container justify='space-between'>
        <Grid item xs={12} md={4} lg={6}>
          <Box mb={[4, 4, 4, 8, 8]}>
            <Typography color='primary' variant='h2' paragraph>
              <Trans t={t} i18nKey='home.title'>
                Kolekcja FI
              </Trans>
            </Typography>
          </Box>
          <Hidden mdDown>
            <Box mt={[2, 2, 2, 8, 12]} mb={[0, 1]}>
              <Typography color='primary' variant='h2'>
                <Link
                  href='https://www.forbo.com/flooring/pl-pl/konkurs-furniture-linoleum/psoj83'
                  underline='none'
                  target='_blank'
                  rel='noopener'
                >
                  &#8594;{" "}
                  <Trans t={t} i18nKey='home.winner'>
                    Najlepszy projekt w konkursie Forbo
                  </Trans>
                </Link>
              </Typography>
            </Box>
            <Box mb={[0, 1]}>
              <Typography color='primary' variant='h2'>
                <Link
                  href='https://label-magazine.com/dizajn/artykuly/rozmowa-z-katarzyna-dobiecka-laureatka-konkursu-linoleum-od-nowa'
                  underline='none'
                  target='_blank'
                  rel='noopener'
                >
                  &#8594;{" "}
                  <Trans t={t} i18nKey='home.interview'>
                    Wywiad z projektantką
                  </Trans>
                </Link>
              </Typography>
            </Box>
            <Box mb={[4, 4, 4, 8, 8]}>
              <Typography color='primary' variant='h2'>
                <Link
                  href='/aboutFI'
                  underline='none'
                  target='_self'
                  rel='noopener'
                >
                  &#8594;{" "}
                  <Trans t={t} i18nKey='home.aboutFI'>
                    Więcej o FI
                  </Trans>
                </Link>
              </Typography>
            </Box>
          </Hidden>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box>
            <Box>
              <Typography color='primary' variant='h2' paragraph>
                <Trans t={t} i18nKey='home.headline'>
                  FI to okrągłe stoły i stoliki o minimalistycznej formie i
                  oryginalnym wykończeniu materiałem Forbo Furniture Linoleum.
                  Bryła walca doskonale wykorzystuje właściwości elastycznego
                  materiału i razem z oklejonym blatem stanowi wizualną jedność.
                  Stół w takim wydaniu wygląda lekko, świeżo − po prostu
                  pięknie.
                </Trans>
              </Typography>
            </Box>
            <Box mt={[3, 6]} pr={[0, 16]}>
              <Typography color='primary' variant='body1' paragraph>
                <Trans t={t} i18nKey='home.paragraph'>
                  Podstawowa paleta kolekcji to trzy uniwersalne kolory, które
                  zostały dobrze odwzorowane na fotografiach wykonanych w
                  naturalnym świetle. Trzy średnice stołów oraz trzy wielkości
                  stolików są odpowiedzią na różne potrzeby. Sprawdzą się jako
                  pojedynczy mebel w przestrzeni domowej lub jako grupa do
                  większych wnętrz publicznych.
                </Trans>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
