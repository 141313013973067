import React, { useState, useEffect, VFC } from "react";
import { useSpring, useTransition, animated } from "react-spring";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";
import { slidesInterface } from "./HomeProducts";

const useStyles = makeStyles({
  relativeDiv: {
    position: "relative",
    width: "100%",
    height: "600px",
    willChange: "transform",
    "@media (max-width:1600px)": {
      height: "400px",
    },
    "@media (max-width:1280px)": {
      height: "312px",
    },
    "@media (max-width:960px)": {
      height: "200px",
    },
  },
  productSlides: {
    position: "absolute",
    backgroundSize: "contain",
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "600px",
    willChange: "opacity",
    "@media (max-width:1600px)": {
      height: "400px",
    },
    "@media (max-width:1280px)": {
      height: "312px",
    },
    "@media (max-width:960px)": {
      height: "200px",
    },
  },
  productTitles: {
    position: "absolute",
    width: "100%",
    bottom: "-48px",
    backgroundColor: "white",
  },
});

export const HomeProductsSlideComponent: VFC<slidesInterface[]> = (
  slides: slidesInterface[]
) => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);
  const transitions = useTransition(slides[index], (item) => item.id, {
    from: { opacity: 0.5 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    trail: 200,
    unique: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((state) => ++state % 6);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const toggle = useState(false);
  const [scale, setScale] = useSpring(() => ({ transform: "scale(1)" }));

  return (
    <Box className={classes.relativeDiv} mb={[16, 0]}>
      <animated.div
        onMouseEnter={() =>
          setScale({ transform: toggle ? "scale(0.96)" : "scale(1)" })
        }
        onMouseLeave={() =>
          setScale({ transform: !toggle ? "scale(0.96)" : "scale(1)" })
        }
        style={scale}
      >
        {transitions.map(({ item, props, key }) => (
          <animated.div key={key} style={props}>
            <Link to={`product/${slides[index].link}`}>
              <Box
                className={classes.productSlides}
                style={{ backgroundImage: item.img }}
              />
            </Link>
          </animated.div>
        ))}
      </animated.div>
      <animated.div>
        {transitions.map(({ item, key, props }) => (
          <animated.div key={key} style={props}>
            <Box className={classes.productTitles} pl={[0, 4, 4, 4, 6]}>
              <Typography color='primary' variant='h1'>
                {item.title}
              </Typography>
            </Box>
          </animated.div>
        ))}
      </animated.div>
    </Box>
  );
};
