import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useSpring, animated } from "react-spring";

function HomeDetailHero() {
  const toggle = useState(false);
  const [props, set] = useSpring(() => ({ transform: "scale(1)" }));

  return (
    <Box ml={[3, 6, 8, 8, 30]} mr={[3, 6, 8, 8, 30]} mt={12}>
      <Grid container>
        <Grid item xs={12} md={8} xl={6}>
          <Box>
            <animated.div
              onMouseEnter={() =>
                set({ transform: toggle ? "scale(0.96)" : "scale(1)" })
              }
              onMouseLeave={() =>
                set({ transform: !toggle ? "scale(0.96)" : "scale(1)" })
              }
              style={{ ...props, willChange: "transform" }}
            >
              <img
                src='/img/fi_web_detail.jpg'
                alt='FI Detail'
                style={{ width: `100%` }}
              />
            </animated.div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomeDetailHero;
