import React, { useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Hidden,
  TextField,
  Typography,
} from "@material-ui/core";
import { useRecoilValue } from "recoil";
import { cartState, cartItemCountState } from "./../../commons/CartState";
import CheckoutItem from "./components/CheckoutItem";
import { useForm } from "react-hook-form";
import { firebaseFirestore } from "../../firebase/firebaseConfig";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import { useResetRecoilState } from "recoil";
import { footerState } from "../../commons/FooterState";
import { Trans, useTranslation } from "react-i18next";

export default function Checkout() {
  const setFooter = useResetRecoilState(footerState);
  useEffect(() => setFooter(), [setFooter]);

  let history = useHistory();

  const resetCart = useResetRecoilState(cartState);

  const checkoutSummary = useRecoilValue(cartState);
  const cartItemCount = useRecoilValue(cartItemCountState);

  const { register, errors, handleSubmit } = useForm();
  const onSubmit = (data) => {
    async function addToItems(cart, order, created) {
      const { id } = await firebaseFirestore.collection("items").add({
        cart: cart,
        order: order,
        created: created,
      });

      firebaseFirestore.collection("orders").add({
        to: [`${data.email}`, "mypasieka@gmail.com"],
        message: {
          subject: "PASIEKA — Potwierdzenie zamówienia",
          // text: 'Dziękujemy za zamówienie',
          html: `<code>
                    <b>Dziękujemy za zamówienie</b>
                    <br><br>
                    Już wkrótce będziesz cieszyć się nowym stołem z kolekcji FI. Realizacja Twojego zamówienia rozpocznie się z momentem opłacenia zamówienia. 
                    Na Twój przelew czekamy do 7 dni. Po tym czasie Twoje zamówienie zostanie anulowane. 
                    <br><br>
                    <b>Dane do przelewu:</b><br>
                    Grzegorz Łotysz PASIEKA<br>
                    ul. Mickiewicza 107/4<br>
                    51-685 Wrocław<br>
                    <br>
                    mBank 95 1140 2004 0000 3102 6873 1478
                    <br><br>
                    W tytule przelewu podaj nr zamówienia: <b>${id}</b>
                    <br><br>
                    Pozdrowienia z Pasieki!
                    </code>`,
        },
      });
    }

    addToItems(checkoutSummary, data, firebase.firestore.Timestamp.now());
    resetCart();
    history.push("/confirmation");
  };

  const { t } = useTranslation();

  return (
    <Box
      ml={[3, 9, 12, 12, 36]}
      mr={[3, 9, 12, 12, 36]}
      mt={[3, 6, 8, 8, 12]}
      color='primary.main'
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Hidden mdDown>
            <Grid item lg={2} />
          </Hidden>
          <Grid item xs={12} md={12} lg={6}>
            <Box pb={2} borderColor='primary.main' borderBottom={2}>
              <Typography variant='h1'>
                <Trans t={t} i18nKey='checkout.form.contact'>
                  Dane kontaktowe
                </Trans>
              </Typography>
            </Box>
            <Box>
              <TextField
                inputRef={register({ required: true })}
                name='name'
                label={
                  <Trans t={t} i18nKey='checkout.form.labels.name'>
                    imię i nazwisko
                  </Trans>
                }
                fullWidth
                margin='none'
              />
              {errors.name && (
                <Typography variant='body2' color='error'>
                  <Trans t={t} i18nKey='checkout.form.validation.required'>
                    Pole obowiązkowe
                  </Trans>
                </Typography>
              )}

              <TextField
                inputRef={register({
                  required: true,
                  pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                })}
                name='email'
                label={
                  <Trans t={t} i18nKey='checkout.form.labels.email'>
                    email
                  </Trans>
                }
                fullWidth
                margin='none'
              />
              {errors.email?.type === "required" && (
                <Typography variant='body2' color='error'>
                  <Trans t={t} i18nKey='checkout.form.validation.required'>
                    Pole obowiązkowe
                  </Trans>
                </Typography>
              )}
              {errors.email?.type === "pattern" && (
                <Typography variant='body2' color='error'>
                  <Trans t={t} i18nKey='checkout.form.validation.email'>
                    Upewnij się czy to poprawny email
                  </Trans>
                </Typography>
              )}

              <TextField
                inputRef={register({ required: true, pattern: /\d+/ })}
                name='phone'
                label={
                  <Trans t={t} i18nKey='checkout.form.labels.phone'>
                    telefon
                  </Trans>
                }
                fullWidth
                margin='none'
              />
              {errors.phone?.type === "required" && (
                <Typography variant='body2' color='error'>
                  <Trans t={t} i18nKey='checkout.form.validation.required'>
                    Pole obowiązkowe
                  </Trans>
                </Typography>
              )}
              {errors.phone?.type === "pattern" && (
                <Typography variant='body2' color='error'>
                  <Trans t={t} i18nKey='checkout.form.validation.phone'>
                    Upewnij się czy to poprawny nr telefonu
                  </Trans>
                </Typography>
              )}
            </Box>
            <Box mt={4} pb={2} borderColor='primary.main' borderBottom={2}>
              <Typography variant='h1'>
                <Trans t={t} i18nKey='checkout.form.delivery'>
                  Adres dostawy
                </Trans>
              </Typography>
            </Box>
            <Box>
              <TextField
                inputRef={register({ required: true })}
                name='deliveryName'
                label={
                  <Trans t={t} i18nKey='checkout.form.labels.deliveryName'>
                    imię i nazwisko
                  </Trans>
                }
                fullWidth
                margin='none'
              />
              {errors.deliveryName && (
                <Typography variant='body2' color='error'>
                  <Trans t={t} i18nKey='checkout.form.validation.required'>
                    Pole obowiązkowe
                  </Trans>
                </Typography>
              )}

              <TextField
                inputRef={register({ required: false })}
                name='deliveryCompany'
                label={
                  <Trans t={t} i18nKey='checkout.form.labels.companyName'>
                    nazwa firmy
                  </Trans>
                }
                fullWidth
                margin='none'
              />

              <TextField
                inputRef={register({ required: true })}
                name='deliveryCountry'
                label={
                  <Trans t={t} i18nKey='checkout.form.labels.country'>
                    country
                  </Trans>
                }
                fullWidth
                margin='none'
              />
              {errors.deliveryCountry && (
                <Typography variant='body2' color='error'>
                  <Trans t={t} i18nKey='checkout.form.validation.required'>
                    Pole obowiązkowe
                  </Trans>
                </Typography>
              )}

              <TextField
                inputRef={register({ required: true })}
                name='deliveryStreet'
                label={
                  <Trans t={t} i18nKey='checkout.form.labels.address'>
                    adres
                  </Trans>
                }
                fullWidth
                margin='none'
              />
              {errors.deliveryStreet && (
                <Typography variant='body2' color='error'>
                  <Trans t={t} i18nKey='checkout.form.validation.required'>
                    Pole obowiązkowe
                  </Trans>
                </Typography>
              )}

              <TextField
                inputRef={register({ required: true })}
                name='deliveryPostCode'
                label={
                  <Trans t={t} i18nKey='checkout.form.labels.zipCode'>
                    kod pocztowy
                  </Trans>
                }
                fullWidth
                margin='none'
              />
              {errors.deliveryPostCode && (
                <Typography variant='body2' color='error'>
                  <Trans t={t} i18nKey='checkout.form.validation.required'>
                    Pole obowiązkowe
                  </Trans>
                </Typography>
              )}

              <TextField
                inputRef={register({ required: true })}
                name='deliveryCity'
                label={
                  <Trans t={t} i18nKey='checkout.form.labels.city'>
                    miasto
                  </Trans>
                }
                fullWidth
                margin='none'
              />
              {errors.deliveryCity && (
                <Typography variant='body2' color='error'>
                  <Trans t={t} i18nKey='checkout.form.validation.required'>
                    Pole obowiązkowe
                  </Trans>
                </Typography>
              )}
            </Box>
            <Box mt={4} pb={2} borderColor='primary.main' borderBottom={2}>
              <Typography variant='h1'>
                <Trans t={t} i18nKey='checkout.form.invoice'>
                  Dane do faktury
                </Trans>
              </Typography>
            </Box>
            <Box>
              <TextField
                inputRef={register({ required: false, pattern: /\d+/ })}
                name='companyNo'
                label={
                  <Trans t={t} i18nKey='checkout.form.labels.vatNumber'>
                    NIP*
                  </Trans>
                }
                fullWidth
                margin='none'
              />
              {errors.companyNo?.type === "pattern" && (
                <Typography variant='body2' color='error'>
                  <Trans t={t} i18nKey='checkout.form.validation.company'>
                    Upewnij się czy to poprawny NIP
                  </Trans>
                </Typography>
              )}
            </Box>
            <Box mt={8} mb={4}>
              <Grid container item xs={12}>
                <Typography paragraph variant='body1'>
                  <Trans t={t} i18nKey='checkout.form.note'>
                    Prześlij do nas swoje zamówienie klikając w przycisk
                    „Zamawiam”. W odpowiedzi dostaniesz email ze wszystkimi
                    informacjami dotyczącymi jego finalizacji. Masz 7 dni na
                    płatność. Zamówienie zostanie przyjęte do realizacji po
                    zaksięgowaniu przelewu.
                  </Trans>
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <Hidden mdDown>
            <Grid item xs={1} md={1} lg={1} />
          </Hidden>
          <Grid item xs={12} md={12} lg={3}>
            <Grid container alignItems='flex-start'>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography color='primary' variant='h1'>
                    <Trans t={t} i18nKey='checkout.orderSummary'>
                      Moje zamówienie
                    </Trans>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {checkoutSummary.map((checkoutItem) => (
                  <CheckoutItem key={checkoutItem.id} item={checkoutItem} />
                ))}
              </Grid>
              <Grid container justify='space-between'>
                <Grid item xs={6}>
                  <Box color='primary.main' mt={2} pl={[2, 2, 2, 2, 4]}>
                    <Typography variant='subtitle2'>
                      <Trans t={t} i18nKey='checkout.cost'>
                        Kwota
                      </Trans>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box color='primary.main' mt={2} pr={[2, 2, 2, 2, 4]}>
                    <Typography variant='subtitle2' align='right'>
                      {cartItemCount} PLN
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box color='primary.main' mt={1} pl={[2, 2, 2, 2, 4]}>
                    <Typography variant='subtitle2'>
                      <Trans t={t} i18nKey='checkout.delivery'>
                        Dostawa
                      </Trans>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box color='primary.main' mt={1} pr={[2, 2, 2, 2, 4]}>
                    <Typography variant='subtitle2' align='right'>
                      0 PLN
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} container justify='space-between'>
                <Grid item xs={6}>
                  <Box color='primary.main' mt={3}>
                    <Typography variant='h1'>
                      <Trans t={t} i18nKey='checkout.toPay'>
                        Do zapłaty
                      </Trans>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box color='primary.main' mt={3}>
                    <Typography variant='h1' align='right'>
                      {cartItemCount} PLN
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} container justify='space-between'>
                <Grid item xs={2}>
                  <Box mt={4} mb={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputRef={register({ required: true })}
                          name='terms'
                          label=''
                        />
                      }
                    />
                    {errors.terms && (
                      <Typography variant='body2' color='error'>
                        <Trans
                          t={t}
                          i18nKey='checkout.form.validation.required'
                        >
                          Pole obowiązkowe
                        </Trans>
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <Box mt={4} mb={2}>
                    <Typography color='primary' variant='body2'>
                      <Trans t={t} i18nKey='checkout.termsOptIn'>
                        Przeczytałem/am i akceptuję regulamin oraz politykę
                        prywatności
                      </Trans>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box mt={2}>
                  <Button
                    variant='contained'
                    size='large'
                    color='primary'
                    fullWidth={true}
                    disableElevation
                    type='submit'
                  >
                    <Typography color='secondary' variant='subtitle2'>
                      <Trans t={t} i18nKey='checkout.cta'>
                        Zamawiam
                      </Trans>
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
