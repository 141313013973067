import React from "react";
import { Box, Grid, Link, Typography } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";

const Contact = React.forwardRef(({ ...props }, ref) => {
  const { t } = useTranslation();
  return (
    <Box {...props}>
      <Box
        ref={ref}
        mt={[9, 15, 12, 12, 12]}
        pl={[3, 6, 8, 8, 30]}
        pr={[3, 6, 8, 8, 30]}
        pb={[4, 4, 6, 6, 12]}
      >
        <Grid container>
          <Grid item xs={12} md={6} lg={6}>
            <Box pl={[0, 3, 4, 4, 6]} mb={[3, 0]}>
              <Typography variant='h2'>
                <Trans t={t} i18nKey='aboutUs.contact'>
                  Kontakt
                </Trans>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container>
              <Grid item xs={8}>
                <Typography variant='h2'>
                  <Link
                    href='mailto:mypasieka@gmail.com'
                    underline='none'
                    target='_blank'
                    rel='noopener'
                  >
                    mypasieka@gmail.com
                  </Link>
                </Typography>
                <Typography variant='h2'>+48 502661837</Typography>
                <Typography variant='h2'>
                  <Link
                    href='https://www.instagram.com/mypasieka/'
                    underline='none'
                    target='_blank'
                    rel='noopener'
                  >
                    @mypasieka
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

export default Contact;
