import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography } from "@material-ui/core";
import { useSpring, animated } from "react-spring";
import { fiTheme } from "../../../commons/Theme";
import { useHistory } from "react-router-dom";
import { PurposeTrans } from "./PurposeTrans";

export const Product = (props: any) => {
  const [toggle, set] = useState(false);
  const [propsScale, setScale] = useSpring(() => ({
    transform: "scale(1)",
    opacity: 1,
    from: { transform: "scale(0.96)", opacity: 0 },
  }));

  const propsOp = useSpring({ opacity: toggle ? 1 : 0 });
  const propsBg = useSpring({
    backgroundColor: toggle ? fiTheme.palette.secondary.main : "white",
  });

  let history = useHistory();

  return (
    <Box mb={[0, 4]}>
      <Box style={{ willChange: "scale" }}>
        <Box textAlign='center'>
          <animated.div style={propsBg}>
            <Box
              style={{ background: !toggle ? "white" : "none" }}
              p={[0, 4, 4, 4, 8]}
            >
              <Grid container>
                <Grid item xs={12}>
                  <animated.div style={propsOp}>
                    <Typography color='primary' variant='body1'>
                      {props.product.fi}
                    </Typography>
                  </animated.div>
                </Grid>
              </Grid>
              <Grid container alignItems='center'>
                <Grid item xs={1}>
                  <animated.div style={propsOp}>
                    <Typography color='primary' variant='body1' align='left'>
                      {props.product.height}
                    </Typography>
                  </animated.div>
                </Grid>
                <Grid item xs={10}>
                  <Box>
                    <Link to={`/product/${props.product.id}`}>
                      <animated.div
                        onMouseEnter={() =>
                          setScale({
                            transform: toggle ? "scale(0.96)" : "scale(1)",
                          })
                        }
                        onMouseLeave={() =>
                          setScale({
                            transform: !toggle ? "scale(0.96)" : "scale(1)",
                          })
                        }
                        onTouchStart={() =>
                          history.push(`/product/${props.product.id}`)
                        }
                        style={{ ...propsScale, willChange: "transform" }}
                      >
                        {!toggle ? (
                          <animated.div
                            onMouseOver={() => set((state) => !state)}
                            onMouseEnter={() =>
                              setScale({
                                transform: !toggle ? "scale(0.96)" : "scale(1)",
                              })
                            }
                            onMouseLeave={() =>
                              setScale({
                                transform: toggle ? "scale(0.96)" : "scale(1)",
                              })
                            }
                            style={{
                              ...propsScale,
                              willChange: "transform",
                              opacity: 1,
                            }}
                          >
                            <img
                              src={props.product.img}
                              alt='fi black'
                              style={{ width: `100%` }}
                            />
                          </animated.div>
                        ) : (
                          <animated.div
                            onMouseLeave={() => set((state) => !state)}
                            style={propsOp}
                          >
                            {props.product.svg}
                          </animated.div>
                        )}
                      </animated.div>
                    </Link>
                  </Box>
                </Grid>
                <Grid item xs={1} />
              </Grid>
              <Grid container>
                <Grid item xs={3}>
                  <animated.div style={propsOp}>
                    <Box>
                      <Typography color='primary' variant='body1' align='left'>
                        <PurposeTrans purpose={props.product.purpose} />
                      </Typography>
                    </Box>
                  </animated.div>
                </Grid>
                <Grid item xs={6}>
                  <animated.div style={propsOp}>
                    <Typography color='primary' variant='body1'>
                      {props.product.width}
                    </Typography>
                  </animated.div>
                </Grid>
                <Grid item xs={3} />
              </Grid>
            </Box>
          </animated.div>
        </Box>
      </Box>
      <Box mt={4} pl={[0, 4, 4, 4, 6]} pr={[0, 4, 4, 4, 6]}>
        <Grid container alignItems='center'>
          <Grid item xs={4}>
            <Box>
              <Link to={`/product/${props.product.id}`}>
                <Typography color='primary' variant='h1' align='left'>
                  {props.product.title}
                </Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box>
              <Typography color='primary' variant='subtitle2' align='center'>
                {props.product.price && `${props.product.price} PLN`}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </Box>
    </Box>
  );
};
