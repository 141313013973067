import React, { useContext, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import { ProductsCtx } from "../../commons/ProductsCtx";
import { appBarState } from "../../commons/AppBarState";
import { footerState } from "../../commons/FooterState";
import { useResetRecoilState } from "recoil";
import { Product } from "./components/Product";

export const ProductsList = () => {
  const setAppBar = useResetRecoilState(appBarState);
  useEffect(() => setAppBar(), [setAppBar]);

  const setFooter = useResetRecoilState(footerState);
  useEffect(() => setFooter(), [setFooter]);

  const ListContext = useContext(ProductsCtx);

  const tablesProducts = ListContext.filter((p) => p.color === "White").filter(
    (p) => p.fi > 40
  );

  const accessoriesProducts = ListContext.filter(
    (p) => p.color === "White"
  ).filter((p) => p.fi < 40);

  return (
    <React.Fragment>
      <Box
        display='flex'
        flexDirection='row'
        flexWrap='wrap'
        justifyContent='space-between'
        ml={[3, 6, 8, 8, 30]}
        mr={[3, 6, 8, 8, 30]}
      >
        {accessoriesProducts.map((product) => (
          <Grid item md={4} sm={12} key={product.id}>
            <Product product={product} />
          </Grid>
        ))}
        {tablesProducts.map((product) => (
          <Grid item md={4} sm={12} key={product.id}>
            <Product product={product} />
          </Grid>
        ))}
      </Box>
    </React.Fragment>
  );
};
