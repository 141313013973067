import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import ImgApiary from "../../../assets/img/About/PASIEKA-About-Apiary.jpg";
import { useTranslation, Trans } from "react-i18next";

const Intro = React.forwardRef(({ ...props }, ref) => {
  const { t } = useTranslation();
  return (
    <Box {...props}>
      <Box
        ref={ref}
        pl={[3, 6, 8, 8, 30]}
        pr={[3, 6, 8, 8, 30]}
        pb={[6, 6, 6, 6, 18]}
        pt={[8, 0]}
      >
        <Grid container>
          <Grid item xs={12} md={8} lg={8}>
            <Box>
              <img src={ImgApiary} alt='Pasieka' width='100%' />
            </Box>
          </Grid>
        </Grid>
        <Box mt={[2, 2, 4, 4, 6]}>
          <Grid container>
            <Grid item lg={6} />
            <Grid item xs={12} lg={6}>
              <Box mt={[2, 4]} pr={[0, 16]}>
                <Typography variant='body1' paragraph>
                  <Trans t={t} i18nKey='aboutUs.introParagraph'>
                    Pasieka to miejsce mocno zdefiniowane przez swoją funkcję
                    oraz wygląd. Ten wyodrębniony obszar pola odznacza się
                    oryginalną, spójną dla znajdujących się tam obiektów formą i
                    kolorystyką. Warstwa dekoracyjna nie jest wartością
                    nadrzędną Pasieki. Jest (tylko i aż) wyrazem estetycznych
                    upodobań jej właściciela-twórcy.
                  </Trans>
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box mt={[2, 4]}>
            <Grid container>
              <Grid item xs={12} md={12} lg={6}>
                <Box pl={[0, 3, 4, 4, 6]}>
                  <Typography variant='h2' paragraph>
                    <Trans t={t} i18nKey='aboutUs.intro'>
                      O marce
                    </Trans>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Box pr={[0, 3, 4, 4, 6]}>
                  <Typography variant='h2' paragraph>
                    <Trans t={t} i18nKey='aboutUs.introParagraph2'>
                      Pasieka to proste meble i przedmioty, które powstały w
                      celu zaspokojenia własnych potrzeb mieszkaniowych. Ich
                      forma jest wynikiem poszukiwania indywidualnych rozwiązań
                      projektowych, nie mających swoich odpowiedników na rynku.
                    </Trans>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={2} lg={2} />
              <Grid item xs={12} md={4} lg={4}></Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default Intro;
