import React, { useContext } from "react";
import { ProductsCtx } from "../../../commons/ProductsCtx";
import { HomeProductsSlideComponent } from "./HomeProductsComponent";
import { Box, Grid } from "@material-ui/core";

export interface slidesInterface {
  id: number;
  img: string;
  title: string;
  link: number;
}

export const HomeProducts = () => {
  const HomeContext = useContext(ProductsCtx);

  const slidesA: slidesInterface[] = [
    {
      id: 1,
      img: `url(${HomeContext[0].img})`,
      title: HomeContext[0].title,
      link: HomeContext[0].id,
    },
    {
      id: 2,
      img: `url(${HomeContext[10].img})`,
      title: HomeContext[10].title,
      link: HomeContext[10].id,
    },
    {
      id: 3,
      img: `url(${HomeContext[2].img})`,
      title: HomeContext[2].title,
      link: HomeContext[2].id,
    },
    {
      id: 4,
      img: `url(${HomeContext[9].img})`,
      title: HomeContext[9].title,
      link: HomeContext[9].id,
    },
    {
      id: 5,
      img: `url(${HomeContext[1].img})`,
      title: HomeContext[1].title,
      link: HomeContext[1].id,
    },
    {
      id: 6,
      img: `url(${HomeContext[11].img})`,
      title: HomeContext[11].title,
      link: HomeContext[11].id,
    },
  ];

  const slidesB: slidesInterface[] = [
    {
      id: 1,
      img: `url(${HomeContext[13].img})`,
      title: HomeContext[13].title,
      link: HomeContext[13].id,
    },
    {
      id: 2,
      img: `url(${HomeContext[5].img})`,
      title: HomeContext[5].title,
      link: HomeContext[5].id,
    },
    {
      id: 3,
      img: `url(${HomeContext[12].img})`,
      title: HomeContext[12].title,
      link: HomeContext[12].id,
    },
    {
      id: 4,
      img: `url(${HomeContext[4].img})`,
      title: HomeContext[4].title,
      link: HomeContext[4].id,
    },
    {
      id: 5,
      img: `url(${HomeContext[14].img})`,
      title: HomeContext[14].title,
      link: HomeContext[14].id,
    },
    {
      id: 6,
      img: `url(${HomeContext[3].img})`,
      title: HomeContext[3].title,
      link: HomeContext[3].id,
    },
  ];

  const slidesC: slidesInterface[] = [
    {
      id: 1,
      img: `url(${HomeContext[8].img})`,
      title: HomeContext[8].title,
      link: HomeContext[8].id,
    },
    {
      id: 2,
      img: `url(${HomeContext[15].img})`,
      title: HomeContext[15].title,
      link: HomeContext[15].id,
    },
    {
      id: 3,
      img: `url(${HomeContext[7].img})`,
      title: HomeContext[7].title,
      link: HomeContext[7].id,
    },
    {
      id: 4,
      img: `url(${HomeContext[17].img})`,
      title: HomeContext[17].title,
      link: HomeContext[17].id,
    },
    {
      id: 5,
      img: `url(${HomeContext[6].img})`,
      title: HomeContext[6].title,
      link: HomeContext[6].id,
    },
    {
      id: 6,
      img: `url(${HomeContext[16].img})`,
      title: HomeContext[16].title,
      link: HomeContext[16].id,
    },
  ];

  return (
    <Box ml={[3, 6, 8, 8, 30]} mr={[3, 6, 8, 8, 30]} mt={[0, 0, 6, 6, 6]}>
      <Grid container>
        <Grid item xs={12} md={4}>
          <HomeProductsSlideComponent {...slidesA} />
        </Grid>
        <Grid item xs={12} md={4}>
          <HomeProductsSlideComponent {...slidesB} />
        </Grid>
        <Grid item xs={12} md={4}>
          <HomeProductsSlideComponent {...slidesC} />
        </Grid>
      </Grid>
    </Box>
  );
};
