import React, { useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { useSpring, animated } from "react-spring";
import KDPortrait from "../../../assets/img/About/PASIEKA-About-KasiaDobiecka.jpg";
import GLPortrait from "../../../assets/img/About/PASIEKA-About-GrzegorzLotysz.jpg";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";

const Creators = React.forwardRef(({ ...props }, ref) => {
  const toggle = useState(false);

  const { t } = useTranslation();

  const [scale, set] = useSpring(() => ({
    transform: "scale(1)",
    opacity: 1,
    from: {
      transform: "scale(0.96)",
      opacity: 0,
    },
  }));

  return (
    <Box
      {...props}
      pl={[3, 6, 8, 8, 30]}
      pr={[3, 6, 8, 8, 30]}
      pb={[6, 6, 10, 12, 18]}
    >
      <Box>
        <Box mt={[2, 2, 4, 4, 6]}>
          <Grid container>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <Box pl={[0, 3, 4, 4, 6]} mb={[3, 0]} mt={[2, 4, 6, 6, 12]}>
                <Typography variant='h2'>
                  <Trans t={t} i18nKey='aboutUs.creators'>
                    Twórcy
                  </Trans>
                </Typography>
              </Box>
              <Box width='50%' pt={4}>
                <animated.div
                  onMouseEnter={() =>
                    set({ transform: toggle ? "scale(0.96)" : "scale(1)" })
                  }
                  onMouseLeave={() =>
                    set({ transform: !toggle ? "scale(0.96)" : "scale(1)" })
                  }
                  style={{ willChange: "transform", ...scale }}
                >
                  <img src={KDPortrait} alt='Kasia Dobiecka' width='100%' />
                </animated.div>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
              <Box>
                <Box ref={ref} pr={[0, 16]} mt={[2, 4, 6, 6, 12]}>
                  <Typography variant='h2' paragraph>
                    Katarzyna Dobiecka
                  </Typography>
                  <Typography variant='h2' paragraph>
                    <Trans t={t} i18nKey='aboutUs.creatorsKD1'>
                      Współzałożycielka i projektantka marki. Architekta z
                      wszechstronnym doświadczeniem projektowym. Działa na styku
                      wielu dziedzin i w różnej skali. Jej udziałem są projekty
                      przestrzeni publicznych, wnętrz, mebli, opakowań a także
                      grafiki użytkowej. Pomysły czerpie z nieustannej
                      obserwacji trendów i potrzeb oraz problemów z tym
                      związanych. Jej obiekty cechuje prosty i czytelny koncept,
                      świeże spojrzenie na estetykę oraz subtelny detal. Dąży do
                      oryginalności w ramach prostych środków i dostępnych
                      rozwiązań. Lubi kontrolować cały proces od koncepcji,
                      przez szczegółowy projekt po nadzór nad jego wykonaniem.
                    </Trans>
                  </Typography>
                  <Typography variant='body1' paragraph>
                    <a
                      href='https://dobiecka.online'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Więcej na dobiecka.online
                    </a>
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box mt={4}>
            <Grid container>
              <Grid xs={12} md={6} lg={6} xl={6}>
                <Box width='50%' mt={[2, 4, 6, 6, 12]}>
                  <animated.div
                    onMouseEnter={() =>
                      set({ transform: toggle ? "scale(0.96)" : "scale(1)" })
                    }
                    onMouseLeave={() =>
                      set({ transform: !toggle ? "scale(0.96)" : "scale(1)" })
                    }
                    style={{ willChange: "transform", ...scale }}
                  >
                    <img src={GLPortrait} alt='Grzegorz Łotysz' width='100%' />
                  </animated.div>
                </Box>
              </Grid>
              <Grid xs={12} md={6} lg={6} xl={6}>
                <Box mt={[2, 4, 6, 6, 12]} pr={[0, 16]}>
                  <Typography variant='h2' paragraph>
                    Grzegorz Łotysz
                  </Typography>
                  <Typography variant='h2' paragraph>
                    <Trans t={t} i18nKey='aboutUs.creatorsGL'>
                      Współzałożyciel marki Pasieka. Creative Director, Product
                      Designer oraz przedsiębiorca z międzynarodowym
                      doświadczeniem w projektowaniu i rozwoju marek i
                      produktów. Na co dzień pełni rolę Senior Product Designera
                      w Boldare.
                    </Trans>
                  </Typography>
                  <Typography variant='body1' paragraph>
                    <a
                      href='https://lotysz.com'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Więcej na lotysz.com
                    </a>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

export default Creators;
