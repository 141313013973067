import * as React from 'react';
import { 
    Box
} from '@material-ui/core';

const CreatorsWrapper = ({children, ...props}) => {

    return (
    <Box {...props}>
        <Box>
            {children}
        </Box>
    </Box>
    )
}

export default CreatorsWrapper